import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import validator from 'validator';
import moment from 'moment';
import {
	Container, FlexboxGrid, Steps, Panel, ButtonToolbar, Button, Alert, Col
} from 'rsuite';
import $ from 'jquery';

import General from '../../../components/settings/employee/General';
import ScheduleRules from '../../../components/settings/employee/ScheduleRules';
import IncidentRules from '../../../components/settings/employee/IncidentRules';
import Picture from '../../../components/settings/employee/Picture';
import Layout from '../../../components/Layout';

class NewEmployee extends Component{
	constructor(props){
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onNext = this.onNext.bind(this);
		this.onPrevious = this.onPrevious.bind(this);
		this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.changeColorNext = this.changeColorNext.bind(this);
        this.openDetails = this.openDetails.bind(this);
        this.setImage = this.setImage.bind(this);
        this.onSave = this.onSave.bind(this);
        this.setSchedule = this.setSchedule.bind(this);
        this.getRolesByAccount = this.getRolesByAccount.bind(this);

		this.state = {
			show:false,
			step:0,
			status:"process",

            names:"",
            lastname1:"",
            lastname2:"",
            phone:"",
            code:"",
            country:"",
            codes:[],
            email:"",
            error_email:"",
            error_phone:"",
            face:false,
            geolocation:false,
            roleId:null,
            
            schedule_name:"",
            schedule:{},

            incident_name:"",
            incident:{},

            photoBase64:"",

            color_next:'default',
            
            save_schedule:false,
            save_incident:false,
            vertical:false,
            can_active: false,
            roles:[],

            contractors:[],
            contractorUserId:'',
		}
    }
    
    async componentDidMount(){
        await this.getRolesByAccount();
        await this.GetContractors();
        this.setState({
            can_active: this.props.match.params.can_active
        })
        //console.log(this.props);
        await fetch('https://countriesnow.space/api/v0.1/countries/codes')
            .then(res => {
                return res.json().then((r)=>{
                    return r;
                });
            }).then(response => {
                var codes = [];
                response.data.map((res)=>
                    codes.push({
                        code: res.dial_code,
                        label: res.code,
                        value:res.code
                    })
                );
                this.setState({codes: codes});
            });

        var ip = await fetch('https://get.geojs.io/v1/ip/country.json')
                    .then(res => {
                        return res.json().then((r)=>{
                            var index = this.state.codes.findIndex(obj => obj.value === r.country);
                      
                            if(index !== -1){
                                this.setState({code: this.state.codes[index].value, country:this.state.codes[index].label});
                            }

                            return r.ip;
                        });
                    });
        var code = await fetch('https://ipapi.co/'+ip+'/country_calling_code/')
                        .then(res => {
                           return res.text().then(r => 
                                r
                            )
                        });
        

        var width = $(window).width();
		if(width < 430){
			this.setState({vertical: true});
		}else{
		  this.setState({vertical: false});
		}

		$(window).on('resize', ()=>{
			if ($(window).width() !== width) {
			  	width = $(window).width();
			  	if(width < 430){
				  	this.setState({vertical: true});
			  	}else{
					this.setState({vertical: false});
			  	}
			}
		});
    }

    async getRolesByAccount(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            method:'GET',
            url:'roles/by-account/'+accountId,
            requireToken:true
        }
        let response =  await this.props.fetchRequest(req);

        if(response !== null&& response !== undefined){
            this.setState(prevState => ({
                roles: response,
                roleId:response && response[0]? response[0].id : null
            }));
        }
    }

    async GetContractors() {
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            method:'GET',
            url:'lookups/by-account-and-type/'+accountId+'/contractor',
            requireToken:true
        }


        let response =  await this.props.fetchRequest(req);
        //console.log(response);
        //console.log(sessionStorage.getItem('contractorId'))
        if (response !== null&& response !== undefined && response.length > 0) {
            //let email = sessionStorage.getItem('userEmail');
            let idx = response.findIndex(obj => obj.id === sessionStorage.getItem('contractorId'));
            
            this.setState({
                contractors:response,
                contractorUserId: sessionStorage.getItem('userType') === 'contractor' ? response[idx].id : ''
            })
            
        }
    }

    changeColorNext(){
        if(this.props.isValidForm('form')){
            this.setState({color_next:'primary'});
        }else{
            this.setState({color_next:'default'});
        }
    }

	handleChange(value, e){
		if(e === undefined){
			e = value;
			value = e.target.value
		}
        let name = e.target !== undefined ? e.target.name : e;
       
        switch(name){
            case 'email':
                this.setState({ [name]: e.target.value });
            
                if(e.target.value === ""){
                    this.setState({error_email:''});
                }else{
                    if(!validator.isEmail(e.target.value)){
                        this.setState({error_email:this.props.t('error_alert.invalid_email')});
                    }else{
                        this.setState({error_email:''});
                    }
                }
                break;
            case 'code':
                this.setState({ [name]: e.target.value });
                
                if(e.target.value === "" || e.target.value === null){
                    this.setState({error_phone: this.props.t('error_alert.invalid_code')});
                }else{
                    this.setState({error_phone:''});
                }
                
                break;
            case 'phone':
                /*var index = this.general.codes.findIndex(obj =>
                    obj.value === this.state.code    
                );
                var length = 10;

                if(index !== -1){
                    length = this.general.codes[index].digitos;
                }*/

                if(e.target.value === ""){
                    this.setState({ [name]: e.target.value });	
                }
                if(validator.isNumeric(e.target.value)){
                    this.setState({ [name]: e.target.value });	
                }				
    
                if(e.target.value === ""){
                    this.setState({error_phone:''});
                }else{
                    if(!validator.isMobilePhone(e.target.value, ['es-MX'])){
                        //this.setState({error_phone: this.props.t('error_alert.invalid_phone')});
                    }else{
                        this.setState({error_phone:''});
                    }
                }
                break;
            case 'schedule_name':
                this.setState({[name]: value});	
                this.schedule_rules.selectRule(e);

                setTimeout(()=>{
                    this.setSchedule();
                }, 500);
 
                break;
            case 'incident_name':
                this.setState({[name]: value});	
                this.incident_rules.selectIncident(e);

                setTimeout(()=>{
                    var i = this.incident_rules.state.incident;

                    var incident = {
                        name: i.name,
                        enableDelays: i.supportDelays === 'No' ? false : true,
                        delaysToAbsence: i.numDelays,
                        allowedDelayMinutes: i.minutesDelays,
                        enableOvertime: i.supportExtraHr === 'No' ? false : true,
                        overtimeMax: i.maxExtraHr,
                        enableEconomicDays: i.supportEconomicDay === 'No' ? 0 : 1,
                        economicDaysMax: i.maxEconomicDay
                    }
                    
                    this.setState({incident: incident});
                }, 500 )
                break;
            default:
                this.setState({[name]: value});	
                break;
        }
        
        setTimeout(()=>{
            if(this.state.error_phone !== "" && this.state.error_email !== ""){
                
                this.setState({color_next:'default'});
            }else{
                this.changeColorNext();
            }
        }, 300);
        
    }
    
    setSchedule(){
        var s = this.schedule_rules.schedule_form.state.schedule;

        var item = {
            name: s.name,
            label: s.label === "" ? s.name.replace(' ', '') : s.label,
            useBreakTime: s.useBreakTime,
            activeWeekend: s.activeWeekend,                
            nightShift: s.nightShift,
            workingHour: s.workingHour,
            breakTime: s.breakTime,
            minimumHours: s.minimumHours,
            type: s.type,
            days: []
        };

        s.days.map((d, i) =>{
            d.date = d.date === undefined ? moment().format('YYYY-MM-DD') : d.date;
            var obj = {
                name: d.name,
                key: d.name,
                check:d.check
            };

            switch(s.type){
                case 'traditional':
                    if(d.check){
                        obj.startTime = d.startTime !== "" ? moment(d.date + ' ' + d.startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        obj.closingTime = d.closingTime !== "" ? moment(d.date + ' ' + d.closingTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        obj.breakTime = d.breakTime !== "" ? moment(d.date + ' ' + d.breakTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        obj.breakReturnTime = d.breakReturnTime !== "" ? moment(d.date + ' ' + d.breakReturnTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                    }
                    break;
                case 'flexible':
                    if(i === 0){
                        item.startTimeDay = d.startTime !== "" ? moment(d.date + ' ' + s.days[0].startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        item.closingTimeDay = d.closingTime !== "" ? moment(d.date + ' ' + s.days[0].closingTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        item.breakInTimeDay = d.breakTime !== "" ? moment(d.date + ' ' + s.days[0].breakTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        item.breakOutTimeDay = d.breakReturnTime !== "" ? moment(d.date + ' ' + s.days[0].breakReturnTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                    } 
                    break;
                case '24x24':
                    item.startTimeDay = d.startTime !== "" ? moment(d.date + ' ' + s.days[0].startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                    break;
                default:
                    break;
            }
            
            if(item.type === 'traditional' && obj.startTime !== undefined){
                item.days.push(obj);
            }

            return item;
        });
        
        this.setState({schedule: item});
    }

    setIncidents(){
        var i = this.incident_rules.state.incident;

        var incident = {
            name: i.name,
            enableDelays: i.supportDelays === 'No' ? false : true,
            delaysToAbsence: i.supportDelays === 'No' ? '' : i.numDelays,
            allowedDelayMinutes: i.supportDelays === 'No' ? '' : i.minutesDelays,
            enableOvertime: i.supportExtraHr === 'No' ? false : true,
            overtimeMax: i.supportExtraHr === 'No' ? '' : i.maxExtraHr,
            enableEconomicDays: i.supportEconomicDay === 'No' ? 0 : 1,
            economicDaysMax: i.supportEconomicDay === 'No' ? '' : i.maxEconomicDay
        }


        this.setState({incident: incident});
    }

	handleCheck(e){
		this.setState({ [e.target.name]: e.target.checked ? true : false });
	}

	onChange(nextStep){
    	this.setState({
    		step:nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep
        });
  	};

  	onNext(){
		if(this.props.isValidForm('form')){	
			this.setState({status:'process', color_next:'default'});

            if(this.state.step === 0){
                if(this.state.error_phone === "" && this.state.error_email === ""){
                    this.onChange(this.state.step + 1);    
                }
            }else if(this.state.step === 1){
                this.setSchedule();

                this.onChange(this.state.step + 1);    
            }else if(this.state.step === 2){
                this.setIncidents();

                this.onChange(this.state.step + 1);    
            }else{
                this.onChange(this.state.step + 1);
            }
			
  		}else{
  			this.setState({status:'error'});
  			Alert.error(this.props.t('error_alert.incomplete_fields'));

            this.changeColorNext();
  		}
  	}

  	onPrevious(){ 
        this.onChange(this.state.step - 1);
        
    }

    setImage(value){
        this.setState({photoBase64: value});
    }

    async onSave(type){
        var account_id  = atob(sessionStorage.getItem(btoa('account_id')));
        account_id = account_id.substring(1, account_id.length - 1);
        
        if(this.state.code !== ""){
            var index = this.state.codes.findIndex(item => item.value === this.state.code);
            var code = this.state.codes[index].code;
        }
    

        var obj = {
            accountId: account_id,
            managerId: account_id,
            employeeNumber:"1",
            firstName: this.state.names,
            lastName: this.state.lastname1,
            maternalSurname: this.state.lastname2,
            roleName:"",
            //contractorUserId:this.state.contractorUserId,
            contractorId:this.state.contractorUserId,
            ssn:"",
            email: this.state.email,
            phone: code+' '+this.state.phone,
            birthDate:"",
            startDate:"",
            schedule: this.state.schedule,
            incidentRule: this.state.incident,
            employeeLocations:[],
            enforceFacialRecognition:this.state.face,
            enforceGeolocation:this.state.geolocation,
            attributes:{country:this.state.code}
        }
        if(this.state.roleId !== null && this.state.roleId !== undefined){
            obj.role = {
                id: this.state.roleId
            }
        }

        var req = {
            api:'accounts',
            method:'POST',
            url:'employees/by-account/'+account_id,
            obj:obj,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.saving'),
            requireToken:true
        }

        var request = await this.props.fetchRequest(req);

        if(this.state.save_schedule){
            req.url = 'accounts/add-schedule-by-account/'+account_id;
            req.obj = this.state.schedule;
            req.modal = null;

            await this.props.fetchRequest(req);
        }

        if(this.state.save_incident){
            req.url = 'accounts/add-incident-rule-by-account/'+account_id;
            req.obj = this.state.incident;
            req.modal = null;

            await this.props.fetchRequest(req);
        }


        if(request !== undefined){

            if(this.state.photoBase64 !== ""){
                obj = {
                    employeeId: request.id,
                    photoBase64: this.state.photoBase64
                }
                req.url = 'employees/save-image';
                req.obj = obj;
                req.modal = null;
    
                this.props.fetchRequest(req);
            }
            
            
            if(type === 'finish'){
                this.onFinish();

            }else if(type === 'details'){
                this.openDetails(request.id);
            }
                
        }
        
    }
      
    onFinish(){
        this.props.history.push('/settings/employee');    
    }

    openDetails(id){
        var canActive = sessionStorage.getItem('suscription') === 'free' ? false : true;
        this.props.history.push({
			pathname: '/settings/employee/details/'+id+'/'+canActive,
			state: { 
                id: id,
                can_active: canActive
			}    
		});
    }

	render(){
		const {t} = this.props;
		const steps = [
            {component:<General t={t}
                        names={this.state.names}
                        lastname1={this.state.lastname1}
                        lastname2={this.state.lastname2}
                        code={this.state.code}
                        codes={this.state.codes}
                        phone={this.state.phone}
                        email={this.state.email}
                        face={this.state.face}
                        geolocation={this.state.geolocation}
                        error_email={this.state.error_email}
                        error_phone={this.state.error_phone}
                        handleChange={this.handleChange}
                        handleCheck={this.handleCheck}
                        changeColorNext={this.changeColorNext}
                        show_details={false}
                        edit_general={true}
                        onRef={ref => this.general = ref}
                        can_active= {this.state.can_active}
                        roles={this.state.roles}
                        roleId={this.state.roleId}
                        contractors={this.state.contractors}
                        contractorUserId={this.state.contractorUserId}
                    />
			},
            {component:<ScheduleRules t={t} {...this.props}
                        schedule_name={this.state.schedule_name}
                        handleChange={this.handleChange}
                        schedule={this.state.schedule}
                        changeColorNext={this.changeColorNext}
                        wizard={true}
                        save_schedule={this.state.save_schedule}
                        onRef={ref => this.schedule_rules = ref}     
                    /> 
			},
            {component:<IncidentRules t={t} {...this.props} 
                        incident_name={this.state.incident_name}
                        handleChange={this.handleChange}
                        incident={this.state.incident}
                        changeColorNext={this.changeColorNext}
                        onRef={ref => this.incident_rules = ref}
                        wizard={true}
                    />
            },
            {component:<Picture t={t} 
                            onRef={ref => this.picture = ref} 
                            setImage={this.setImage}
                        />
			}
		];

		return (
			<Layout {...this.props}>
                <Container>
                    <FlexboxGrid justify="center">
                        <Col xs={24} sm={22} lg={18} >
                            <Steps 
                                current={this.state.step} 
                                currentStatus={this.state.status}
                                small
                                vertical={this.state.vertical}>
                            
                                <Steps.Item 
                                    title={t('screen.settings.employee.new.step')+" - 1"} 
                                     
                                />
                                
                                <Steps.Item 
                                    title={t('screen.settings.employee.new.step')+" - 2"} 
                                     
                                />
                                <Steps.Item 
                                    title={t('screen.settings.employee.new.step')+" - 3"} 
                                     
                                />
                                <Steps.Item 
                                    title={t('screen.settings.employee.new.step')+" - 4"} 
                                     
                                />
                            </Steps>

                            <Panel>
                                {steps[this.state.step].component}
                            </Panel>
                            <hr/>
                            <FlexboxGrid justify="end">
                                <FlexboxGrid.Item colspan={20}>
                                    <ButtonToolbar style={{float:'right'}}>
                                        
                                        <Button 
                                            onClick={()=>
                                                this.state.step === 3 ?
                                                    this.onSave('details')
                                                : this.onFinish()
                                            } 
                                        >
                                            {this.state.step < 3 ?
                                                t('button.cancel')
                                            /*: this.state.step === 3 ?
                                                t('button.skip')*/
                                            : t('button.details')
                                            }
                                        </Button>
                                        
                                        {this.state.step > 0 &&
                                            <Button onClick={()=>this.onPrevious()}>{t('button.previous')}</Button>
                                        }
                                        

                                        {this.state.step === steps.length - 1 ?
                                            <Button appearance="primary" onClick={()=>this.onSave('finish')}>
                                                {t('button.finish')}
                                            </Button>

                                        : <Button appearance={this.state.color_next} onClick={()=>this.onNext()}>
                                            {t('button.next')}
                                        </Button>}
                                    </ButtonToolbar>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        
                        </Col>
                    </FlexboxGrid>
                </Container>


               
            </Layout>	
		)
	}
}

export default withTranslation('translations')(NewEmployee);