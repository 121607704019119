import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
//import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import React, { Component } from 'react';

import {Dropdown, IconButton, Icon, Button} from 'rsuite';
import { MdManageSearch } from "react-icons/md";
import { MdDone } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');
require('datatables.net-buttons/js/dataTables.buttons.min');

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require('jszip');
require('datatables.net-buttons/js/buttons.html5.min');
window.JSZip = jzip;

class TableList extends Component{
    constructor(props){
        super(props);

        this.setTable = this.setTable.bind(this);
        this.resetTable = this.resetTable.bind(this);
        
        this.state = {
            disabled_new: false,
            message:"",
        }
    }
    

    componentDidMount(){
        this.props.onRef(this);
        //console.log(this.props)
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    resetTable(){
        this.$el = $(this.el);
		this.$el.DataTable().destroy();
	}

    setTable(){
        this.$el = $(this.el);
        var fixColumns = [];
        var employeeColumns = [];
        this.props.columns.forEach((c, i)=>
            c.width !== undefined &&
                fixColumns.push(
                    {width: c.width, targets:i}
                )
        );

        var objEvidencesButton = this.props.screen === 'visit'? {
            //evidencias
            text:this.props.t('button.download_evidences'),
            className: "rs-btn rs-btn-default "+(this.props.data.length > 0 ? "s-btn-primary" : "rs-btn-disabled"),
            disabled:true,
            action:( e, dt, node, config )=>{
                /**/
                $('.rs-btn-default').blur();
                if (this.props.data.length > 0 ) {
                    this.props.downloadEvidences(); 
                }
                                     
            }
        }: {}
        
        var table = this.$el.DataTable({
            dom: 'Bfrtip',            
            buttons: this.props.report ?
                [
                    objEvidencesButton,
                    {
                        extend: 'excelHtml5',
                        className: "rs-btn rs-btn-default "+(this.props.data.length > 0 ? "s-btn-primary" : ""),
                        title: this.props.filename,                        
                        exportOptions:{
                            stripHtml: false,
                            format:{
                                header:(data, col, row)=>{
                                    if(this.props.screen === 'check-in-face'){
                                        
                                        if(data.indexOf('rs-icon-smile-o') !== -1){
                                            data = this.props.t('screen.reports.'+this.props.screen+'.columns.face')
                                        }

                                        if(data.indexOf('rs-icon-camera') !== -1){
                                            data = this.props.t('screen.reports.'+this.props.screen+'.columns.photo')
                                        }
                                        
                                    }else if(this.props.screen === 'entries'){
                                        if(data.indexOf('rs-icon-smile-o') !== -1){
                                            data = this.props.t('screen.reports.'+this.props.screen+'.columns.face')
                                        }
                                        
                                    }
                                    
                                    return data;
                                    
                                },
                                body: (data, column, row)=>{
                                    if(this.props.screen === 'check-in-face'){
                                        if(row === 2 || row === 6){
                                            if(data.indexOf('rs-icon-close') !== -1){
                                                data = this.props.t('general.no');
                                            }else{
                                                if(data !== "")
                                                    data = this.props.t('general.yes');
                                            }                                            
                                        }

                                        if((row === 3 || row === 7) && data !== ''){
                                            var dato = this.props.data[column][row].split('/');
                                            var lat = parseFloat(dato[dato.length - 2]).toFixed(4);
                                            var lon = parseFloat(dato[dato.length - 1]).toFixed(4);

                                            data = 'Lat: '+lat+', Lon:'+lon;
                                        }

                                        if(row === 4 || row === 8){
                                            data = '';
                                        }
                                    }else if(this.props.screen === 'entries'){
                                        if(row === 2 || row === 5){
                                            if(data.indexOf('rs-icon-close') !== -1){
                                                data = this.props.t('general.no');
                                            }else{
                                                if(data !== "")
                                                    data = this.props.t('general.yes');
                                            }                                            
                                        }
                                    }else if(this.props.screen === 'visit'){
                                        if(row === 2 || row === 4){
                                            if(data.indexOf('title="Initial Loc') !== -1 || data.indexOf('title="Loc. inicial') !== -1){
                                                var init_loc = this.props.data[column].findIndex(obj => 
                                                    obj.indexOf('start_location/') !== -1
                                                );

                                                if(init_loc !== -1){
                                                    var dato = this.props.data[column][row].split('/');
                                                    var lat = parseFloat(dato[dato.length - 2]).toFixed(2);
                                                    var lon = parseFloat(dato[dato.length - 1]).toFixed(2);

                                                    data = 'Lat: '+lat+', Lon:'+lon;
                                                }
                                            }

                                            if(data.indexOf('title="End Loc') !== -1 || data.indexOf('title="Loc. final') !== -1){
                                                var end_loc = this.props.data[column].findIndex(obj => 
                                                    obj.indexOf('end_location/') !== -1
                                                );

                                                if(end_loc !== -1){
                                                    var dato = this.props.data[column][row].split('/');
                                                    var lat = parseFloat(dato[dato.length - 2]).toFixed(2);
                                                    var lon = parseFloat(dato[dato.length - 1]).toFixed(2);

                                                    data = 'Lat: '+lat+', Lon:'+lon;
                                                }
                                            }                                                                                        
                                        }

                                        if(row === 6){
                                            var evidence = this.props.data[column].findIndex(obj => 
                                                obj.indexOf('evidence/one') !== -1
                                            );
    
                                            if(evidence !== -1){
                                                data = this.props.t('general.yes');
                                            }else{
                                                data = this.props.t('general.no');
                                            }
                                        }
                                    }
                                        
                                    return data;
                                    
                                },
                            }
                        }
                    },
                    {
                        extend: 'pdfHtml5',
                        className: "rs-btn rs-btn-default "+(this.props.data.length > 0 ? "s-btn-primary" : ""),
                        title: this.props.filename,
                        orientation:"landscape",
                        customize:(doc)=>{

                            doc.content[1].table.body.map((body, i)=>{
                                if(i > 0){
                                    if(this.props.screen === 'visit'){
                                        var init_loc = this.props.data[i-1].findIndex(obj => 
                                            obj.indexOf('start_location/') !== -1
                                        );

                                        if(init_loc !== -1){
                                            var data = this.props.data[i-1][init_loc].split('/');
                                            var lat = parseFloat(data[data.length - 2]).toFixed(2);
                                            var lon = parseFloat(data[data.length - 1]).toFixed(2);

                                            body[init_loc] = {text:'Lat: '+lat+', Lon:'+lon};
                                        }
                                        
                                        var end_loc = this.props.data[i-1].findIndex(obj => 
                                            obj.indexOf('end_location/') !== -1
                                        );

                                        if(end_loc !== -1){
                                            var data = this.props.data[i-1][end_loc].split('/');
                                            var lat = parseFloat(data[data.length - 2]).toFixed(2);
                                            var lon = parseFloat(data[data.length - 1]).toFixed(2);

                                            body[end_loc] = {text:'Lat: '+lat+', Lon:'+lon};
                                        }

                                        var evidence = this.props.data[i-1].findIndex(obj => 
                                            obj.indexOf('evidence/one') !== -1
                                        );

                                        if(evidence !== -1){
                                            body[evidence] = {text: this.props.t('general.yes')};
                                        }else{
                                            body[evidence] = {text: this.props.t('general.no')};
                                        }
                                        
                                    }else{
                                        var check = this.props.data[i-1].findIndex(obj => 
                                            obj.indexOf('entry/') !== -1  
                                        );

                                        if(check !== -1){ 
                                            if(this.props.data[i-1][check].indexOf('red') === -1){
                                                body[check] = {text: this.props.t('general.yes')};
                                            }else{
                                                body[check] = {text: this.props.t('general.no')};
                                            }
                                            
                                        }

                                        var close = this.props.data[i-1].findIndex(obj => 
                                            obj.indexOf('out/') !== -1  
                                        );

                                        if(close !== -1){
                                            if(this.props.data[i-1][close].indexOf('red') === -1){
                                                body[close] = {text: this.props.t('general.yes')};
                                            }else{
                                                body[close] = {text: this.props.t('general.no')};
                                            }
                                        }

                                        this.props.data[i-1].map((d, j)=>{
                                            if(d.indexOf('data:image') !== -1){
                                                body[j] = {image:d, width:30}
                                            }

                                            return d;
                                        });
                                    }
                                    
                                    
                                }else{
                                    body.map((b, j)=>{
                                        if(b.text === ""){
                                            b.text = this.props.t('screen.reports.'+this.props.screen+'.columns.'+this.props.columns[j].title)
                                        }

                                        return b;
                                    })
                                }
                                return body;
                            });

                            if(this.props.table_title.length > 0){
                                var title = [];
                                this.props.table_title.map((t)=>{
                                    title.push({
                                        text:this.props.t('screen.reports.'+this.props.screen+'.columns.'+t.title), 
                                        colSpan:t.col,
                                        style:'tableHeader',
                                        fillColor: "#2d4154"
                                    });

                                    for(var x = 0; x < t.col - 1; x++){
                                        title.push({});
                                    }
                                    return title;
                                });
                                
                                doc.content[1].table.body.unshift(title);

                                if(this.props.screen === 'check-in-face'){
                                    doc.content[1].table.widths = this.props.widths;
                                }

                                if(this.props.screen === 'entries'){
                                    doc.content[1].table.widths = this.props.widths;
                                }
                                
                                
                            }else{
                                doc.content[1].table.widths = this.props.widths;
                            }
                            
                            doc.styles.tableHeader.fontSize = 6.5;
                            doc.defaultStyle.fontSize = 6.5;
                        }
                    }
                ]
            : (this.props.screen === 'employee')?
                [
                    {
                        extend:'excelHtml5',
                        className: "rs-btn excel-btn rs-btn-default "+(this.props.data.length > 0 ? "s-btn-primary" : ""),
                        title: this.props.filename,                
                        exportOptions:{
                            stripHtml: false,
                            format:{
                                header:(data, col, row)=>{
                                    return data;
                                },
                                body: (data, col, row)=>{
                                    return data;
                                }
                            }
                        },
                        action:function(e, dt, node, config){
                            /**/
                            config.exportOptions.columns = getIndex();
                            $.fn.dataTable.ext.buttons.excelHtml5.action.call(this, e, dt, node, config);                                
                        }
                    },
                    {
                        text:this.props.t('button.download_template'),//'Descargar plantilla',
                        className: "rs-btn rs-btn-default "+
                                    (this.props.data.length > 0 ? 
                                        sessionStorage.getItem("suscription") !== "free" ?
                                            "s-btn-primary" 
                                        : "rs-btn-disabled"    
                                    : ""),
                        action:( e, dt, node, config )=>{
                            if(sessionStorage.getItem("suscription") !== "free"){
                                $('.rs-btn-default').blur();
                                this.props.download();
                            }
                            
                        }
                    },
                    {
                        text:this.props.t('button.load_data'),//'Cargar datos',
                        className: "load-file rs-btn rs-btn-default "+
                                    (this.props.data.length > 0 ? 
                                        sessionStorage.getItem("suscription") !== "free" ?
                                            "s-btn-primary" 
                                        : "rs-btn-disabled"
                                    : ""),
                    }
                ]
            : [],
            //columns: this.props.columns,
            oLanguage:{
                sProcessing: 'Procesando...',
                sZeroRecords: this.props.t('general.table.zero_records'),
                sEmptyTable: 'Ningún dato disponible en esta tabla',
                sInfoFiltered: 'Filtrado de un total de _MAX_ registros',
                sSearch: this.props.t('general.table.search'),
                sLoadingRecords: 'Cargando...',
                oPaginate:{
                    sFirst: 'Primero',
                    sLast: 'Último',
                    sNext: 'Siguiente',
                    sPrevious: 'Anterior'
                },
                fnInfoCallback: null
            },
            paging: false,
            responsive: false,
            searching: this.props.searching,
            scrollY: this.props.scroll ? '32em' : false,
            scrollX: true,
            autoWidth: false,
            info: false,
            ordering: false,
            columnDefs: fixColumns,
            fixedColumns: true
        });
        
        const getIndex = ()=>{
            employeeColumns = [];

            this.props.columns.forEach((col, i)=>{
                if(i > 0 && col.title !== '' && col.visible){
                    employeeColumns.push(i);
                }
            });

            return employeeColumns;
        }

        if(this.props.btnNew){
            $('.dataTables_filter').append(
                '<button type="button" style="margin-left:10px;"'+
                    'class="new rs-btn rs-btn-default rs-btn-icon rs-btn-icon-placement-left rs-btn-icon-circle'+
                    (this.state.disabled_new ? '" ' : ' s-primary-btn"')+
                    'title="'+
                        (this.state.message === 'new' ?
                            this.props.t('button.new')
                        : this.props.t('error_alert.suscription_free')
                        )+
                    '"'+
                '>'+
                    '<i class="rs-icon rs-icon-plus"></i>'+
                    '<span class="rs-ripple-pond">'+
                        '<span class="rs-ripple" style="width: 56.304px; height: 56.304px; left: -11.9645px; top: -12.152px;">'+
                        '</span>'+
                    '</span>'+
                '</button>'
            )

            $('.new').click((e)=>{
                e.preventDefault();

                this.props.new();
            });
        
        }

        if(this.props.screen === 'employee'){
            $('.dt-buttons').append(
                '<input type="file" id="carga" class="file-template" style="display:none;" />'
            );

            $('.load-file').click((e)=>{
                e.preventDefault();

                if(sessionStorage.getItem("suscription") !== "free"){
                    $('.rs-btn-default').blur();
                    $('.file-template').val('');
                    $('.file-template').click();
                }
                
            });

            $('.file-template').change((e)=>{
                e.preventDefault();
                this.props.preview();
            });
          
        }

        this.props.columns.map((col, i)=>{
            var column = table.column(i);
            column.visible(col.visible);

            return col;
        })
        
        $(document).on('click', '.col_show_hide', function(e){
            e.preventDefault();
             
            // Get the column API object
            var column = table.column( $(this).attr('data-column') );
    
            // Toggle the visibility
            column.visible( ! column.visible() );

            $(this).attr('checked', ! column.visible())
        });

        if(this.props.screen === 'absences'){
            $('.dt-buttons').prepend(
                '<button type="button" class="refresh-list buttons-html5 rs-btn rs-btn-default '+
                    (table.rows().indexes().length === 0 ? '' : 'rs-btn-blue')+'" '+
                    (table.rows().indexes().length === 0 ? 'disabled' : '')+'>'+
                    '<i class="rs-icon rs-icon-refresh"></i> '+this.props.t('button.support_doc')+
                    '<span class="rs-ripple-pond">'+
                        '<span class="rs-ripple" style="width: 206.77px; height: 206.77px; left: -4.15064px; top: -74.385px;"></span>'+
                    '</span>'+
                '</button>'
            );

            $(document).off().on('click', '.refresh-list', (e)=>{
                e.preventDefault();
                this.props.search(e, 'reports/valid-absences');
            })
        }

        if (table.rows().indexes().length === 0 ) {
			table.buttons('.buttons-html5').disable();
		}else {
			table.buttons('.buttons-html5').enable();
		}

        $('.dt-buttons').css('margin-right', '50px');
    }

    render(){
        return (
            <div className="col-12 form-group tbl-data" style={{fontSize: '12px'}}>
                <div style={{width: '80%', position: 'absolute', textAlign: 'right', marginLeft:'20%'}}>
                    {this.props.updateColumns &&
                        <div className="columns">
                            
                            <Dropdown 
                                title="+"
                                placement="bottomEnd"
                                style={{marginRight:20}}
                            >
                                {this.props.columns.map((c, i)=>(
                                    this.props.report ? 
                                        <Dropdown.Item key={i}>
                                            <input 
                                                type="checkbox" 
                                                className="col_show_hide" 
                                                data-column={i}
                                                checked={c.visible}
                                                onChange={(e)=>this.props.updateColumns(e, i)}
                                            /> {this.props.report ?
                                                this.props.t('screen.reports.'+this.props.screen+'.columns.'+c.name)
                                            : this.props.t('screen.settings.'+this.props.screen+'.list.columns.'+c.name)}
                                        </Dropdown.Item>
                                    :
                                        i < this.props.columns.length - 1 &&
                                            <Dropdown.Item key={i}>
                                                <input 
                                                    type="checkbox" 
                                                    className="col_show_hide" 
                                                    data-column={i}
                                                    checked={c.visible}
                                                    onChange={(e)=>this.props.updateColumns(e, i)}
                                                /> {c.special ? c.title :
                                                    this.props.report ?
                                                        this.props.t('screen.reports.'+this.props.screen+'.columns.'+c.name)
                                                    : this.props.t('screen.settings.'+this.props.screen+'.list.columns.'+c.name)
                                                }
                                            </Dropdown.Item>
                                        
                                ))}
                            </Dropdown>
                        </div>
                    }
                </div>
                
                <div className="table-responsive">
                    <table 
                        className={
                            this.props.report ? "table table-auto" : "table table-fixed"
                        } 
                        id="table" 
                        ref={el => this.el = el}
                    >
                        <thead>
                            {this.props.report ?
                                <tr>
                                    {this.props.table_title.map((tbl, i)=>
                                        <th key={i} colSpan={tbl.col} style={{textAlign:'center'}} className="s-table-titles">
                                            {this.props.t('screen.reports.'+this.props.screen+'.columns.'+tbl.title)}
                                        </th>
                                    )}
                                </tr>
                            :null}
                            <tr>
                                {this.props.columns.map((col, i)=>(
                                    <th key={i} style={{width:col.width}} className="s-table-titles">{
                                        this.props.report ?
                                            col.name === 'face' ?
                                                <Icon icon="smile-o" size="2x" />
                                            : col.name === 'photo' ?
                                                <Icon icon="camera" size="lg" />
                                            :this.props.t('screen.reports.'+this.props.screen+'.columns.'+col.name)
                                        : col.special ? 
                                            col.title
                                        : col.title !== '' ?
                                            this.props.t('screen.settings.'+this.props.screen+'.list.columns.'+col.name)
                                        : ''
                                    }</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.data.map((data, i)=>(
                                <tr key={i} className="s-table-content">
                                    {data.map((d, j)=>(
                                        data.length - 1 === j ?
                                            <td key={j}>
                                                {this.props.actions.length > 0 ?
                                                    this.props.actions.map((b, key)=>(
                                                        b.btn ?
                                                            b.icon !== null ?
                                                                <IconButton 
                                                                    icon={<Icon icon={b.icon} />} 
                                                                    key={key} 
                                                                    style={{marginLeft: 8}} 
                                                                    size="sm"
                                                                    title={b.title}
                                                                    onClick={(e)=>b.action(e, d)}
                                                                />
                                                            : <Button title={b.title} key={key} color="blue" style={{marginLeft: 8}} size="xs">
                                                                {b.name}
                                                            </Button>
                                                        : null
                                                    ))
                                                : (d.indexOf('data:image') !== -1 || d.indexOf('https') !== -1) ?
                                                    <img src={d} style={{width:'48px'}} alt="" />
                                                : d.toString().indexOf('icon') !== -1 ?
                                                    <div>
                                                        {this.props.screen === 'employees_activity' && d.split('/')[1] === 'MdManageSearch' ?
                                                        <Icon 
                                                            size="2x" 
                                                            style={{color:d.split('/')[2], cursor: d.split('/')[4] === 'no_action' ? 'no-drop' : 'pointer'}}
                                                            onClick={(e)=>
                                                                d.split('/')[4] === 'no_action' ?
                                                                    e.preventDefault()
                                                                :d.split('/')[4] !== 'one' ? 
                                                                    this.props.actionIcon(e, data[data.length-1], d.split('/')[3])
                                                                : this.props.actionIcon(e, i, d.split('/')[3])
                                                            }
                                                            title={this.props.report ?
                                                                d.split('/')[3] !== '' &&
                                                                    this.props.t('screen.reports.'+this.props.screen+'.columns.'+d.split('/')[3])
                                                            : this.props.t('screen.settings.'+this.props.screen+'.list.columns.'+d.split('/')[3])}
                                                        >
                                                            <MdManageSearch/>
                                                        </Icon>
                                                        :
                                                        this.props.screen === 'checks_map' /*||  this.props.screen === 'entries'*/ ?
                                                        <Icon 
                                                            size="2x" 
                                                            style={{color:d.split('/')[2], cursor: d.split('/')[4] === 'no_action' ? 'no-drop' : 'pointer'}}
                                                            onClick={(e)=>
                                                                d.split('/')[4] === 'no_action' ?
                                                                    e.preventDefault()
                                                                :d.split('/')[4] !== 'one' ? 
                                                                    this.props.actionIcon(e, data[data.length-1], d.split('/')[3])
                                                                : this.props.actionIcon(e, i, d.split('/')[3])
                                                            }
                                                            title={this.props.report ?
                                                                d.split('/')[3] !== '' &&
                                                                    this.props.t('screen.reports.'+this.props.screen+'.columns.'+d.split('/')[3])
                                                            : this.props.t('screen.settings.'+this.props.screen+'.list.columns.'+d.split('/')[3])}
                                                        >
                                                            {d.split('/')[1] === 'MdOutlineLocationOn'? <MdOutlineLocationOn/> : <span className='textLocationButton'>
                                                                {d.split('/')[1]}
                                                                </span> 
                                                            }
                                                             
                                                        </Icon>
                                                        :
                                                        (this.props.screen === 'entries') && (d.split('/')[1] !== 'map-marker' && d.split('/')[1] !== 'check') ?
                                                        <Icon 
                                                            size="2x" 
                                                            style={{color:d.split('/')[2], cursor: d.split('/')[4] === 'no_action' ? 'no-drop' : 'pointer'}}
                                                            onClick={(e)=>
                                                                d.split('/')[4] === 'no_action' ?
                                                                    e.preventDefault()
                                                                :d.split('/')[4] !== 'one' ? 
                                                                    this.props.actionIcon(e, data[data.length-1], d.split('/')[3])
                                                                : this.props.actionIcon(e, i, d.split('/')[3])
                                                            }
                                                            title={this.props.report ?
                                                                d.split('/')[3] !== '' &&
                                                                    this.props.t('screen.reports.'+this.props.screen+'.columns.'+d.split('/')[3])
                                                            : this.props.t('screen.settings.'+this.props.screen+'.list.columns.'+d.split('/')[3])}
                                                        >
                                                        
                                                        <>
                                                            <span className='textLocationButton'>{d.split('/')[1]}</span> 
                                                        </>    
                                                    </Icon>
                                                        :
                                                        <Icon 
                                                            icon={d.split('/')[1]} 
                                                            size="2x" 
                                                            style={{color:d.split('/')[2], cursor: d.split('/')[4] === 'no_action' ? 'no-drop' : 'pointer'}}
                                                            onClick={(e)=>
                                                                d.split('/')[4] === 'no_action' ?
                                                                    e.preventDefault()
                                                                :d.split('/')[4] !== 'one' ? 
                                                                    this.props.actionIcon(e, data[data.length-1], d.split('/')[3])
                                                                : this.props.actionIcon(e, i, d.split('/')[3])
                                                            }
                                                            
                                                            title={this.props.report ?
                                                                d.split('/')[3] !== '' &&
                                                                    this.props.t('screen.reports.'+this.props.screen+'.columns.'+d.split('/')[3])
                                                            : this.props.t('screen.settings.'+this.props.screen+'.list.columns.'+d.split('/')[3])}
                                                        />
                                                        }
                                                    </div>
                                                : d }

                                                {this.props.screen === 'employee' &&
                                                    data[0].toString().indexOf('icon') !== -1 &&
                                                        data[0].split('/')[4] === 'no_action' &&
                                                            <Icon
                                                                icon="toggle-off"
                                                                style={{marginLeft: 8}} 
                                                                size="lg"
                                                                title={this.props.t('screen.settings.employee.list.columns.active')}
                                                            />
                                                }
                                            </td>

                                        : <td key={j}>
                                            {d !== null ?
                                                d.toString().indexOf('icon') !== -1 ?
                                                    <div>
                                                    {((this.props.screen === 'employees_activity' || this.props.screen === 'checks_map') || this.props.screen === 'entries' || this.props.screen === 'check-in-face' || this.props.screen === 'visit') && (d.split('/')[1] !== 'map-marker' && d.split('/')[1] !== 'check' && d.split('/')[1] !== 'close') ?
                                                        <Icon 
                                                            size="2x" 
                                                            style={{color:d.split('/')[2], cursor: d.split('/')[4] === 'no_action' ? 'no-drop' : 'pointer'}}
                                                            onClick={(e)=>
                                                                d.split('/')[4] === 'no_action' ?
                                                                    e.preventDefault()
                                                                :d.split('/')[4] !== 'one' ? 
                                                                    this.props.actionIcon(e, data[data.length-1], d.split('/')[3])
                                                                : this.props.actionIcon(e, i, d.split('/')[3])
                                                            }
                                                            title={this.props.report ?
                                                                d.split('/')[3] !== '' &&
                                                                    this.props.t('screen.reports.'+this.props.screen+'.columns.'+d.split('/')[3])
                                                            : this.props.t('screen.settings.'+this.props.screen+'.list.columns.'+d.split('/')[3])}
                                                        >
                                                            {d.split('/')[1] === 'MdDone' ?
                                                                <MdDone/>
                                                            :
                                                            d.split('/')[1] === 'MdClose' ?
                                                                <MdClose/>
                                                            :
                                                            d.split('/')[1] === 'MdOutlineLocationOn' ?
                                                                <MdOutlineLocationOn/>
                                                            :
                                                            <>
                                                                <span className='textLocationButton'>{d.split('/')[1]}</span> 
                                                            </>    
                                                            }
                                                            
                                                        </Icon>
                                                        :
                                                        <Icon 
                                                            icon={d.split('/')[1]} 
                                                            size="2x" 
                                                            style={{color:d.split('/')[2], cursor: d.split('/')[4] === 'no_action' ? 'no-drop' : 'pointer'}}
                                                            onClick={(e)=>
                                                                d.split('/')[4] === 'no_action' ?
                                                                    e.preventDefault()
                                                                :d.split('/')[4] !== 'one' ? 
                                                                    this.props.actionIcon(e, data[data.length-1], d.split('/')[3])
                                                                : this.props.actionIcon(e, i, d.split('/')[3])
                                                            }
                                                            title={this.props.report ?
                                                                d.split('/')[3] !== '' &&
                                                                    this.props.t('screen.reports.'+this.props.screen+'.columns.'+d.split('/')[3])
                                                            : this.props.t('screen.settings.'+this.props.screen+'.list.columns.'+d.split('/')[3])}
                                                        />
                                                    }
                                                    </div>
                                                : (d.indexOf('data:image') !== -1 ||  d.indexOf('https') !== -1)?
                                                    <img src={d} style={{width:'48px'}} alt="" />
                                                : d
                                            : ""}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    
}

export default TableList;