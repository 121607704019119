import React, { Component } from 'react';
import {Panel, Grid, Icon} from 'rsuite';
import {Table} from 'react-bootstrap';

class Invoice extends Component{
    constructor(props){
        super(props);

        this.getInvoices = this.getInvoices.bind(this);
        
        this.state = {
            invoices:[]
        }
    }

    async componentDidMount(){
        this.props.onRef(this);

        await this.getInvoices();
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    async getInvoices(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        if(sessionStorage.getItem("suscription") !== "free"){
            var params = {
                api:'payments',
                url:'subscription/invoices-by-account/'+accountId,
                method:'GET',
                requireToken:true
            }

            var invoices = await this.props.fetchRequest(params);
            this.setState({invoices:invoices!==undefined?invoices:[]});
        }
    }

    render(){
        const {t} = this.props;
        return (
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {t('screen.settings.account.billing.title')}
                </i>
                <Grid fluid>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>{this.props.t('screen.settings.account.invoice.date')}</th>
                                <th>{this.props.t('screen.settings.account.invoice.amount')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            this.state.invoices !== null? 
                                this.state.invoices.map((invoice, i)=>(
                                    invoice.invoiceLink !== null &&
                                        <tr key={i}>
                                            <td>{invoice.date}</td>
                                            <td>{invoice.total} USD</td>
                                            <td>
                                                <a href={invoice.invoiceLink} target="_blank" rel="noreferrer">
                                                    <Icon icon="file-pdf-o" size="2x" style={{color:"red"}} />
                                                </a>
                                            </td>
                                        </tr>
                                ))
                            :null}
                        </tbody>
                    </Table>
                </Grid>
            </Panel>
        )
    }
 
}

export default Invoice;