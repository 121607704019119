import React, { Component } from 'react';
import moment from 'moment';

import ScheduleForm from '../general/ScheduleForm';
import { swalAction } from '../../../lib/functions';

class ScheduleRules extends Component{
    constructor(props){
        super(props);

        this.validHours = this.validHours.bind(this);
        this.changeRules = this.changeRules.bind(this);
        this.compareHours = this.compareHours.bind(this);
        this.newRule = this.newRule.bind(this);
        this.updateSchedules = this.updateSchedules.bind(this);
        this.showHideRule = this.showHideRule.bind(this);

        this.state = {
            schedules:[],
            original:[],
            select_name:true,
            scheme_types:[],
            days:['monday','tuesday','wednesday','thursday','friday','saturday','sunday']
        }
    }

    async componentDidMount(){
        this.props.onRef(this);
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var request = await this.props.fetchRequest({
            api:'accounts', 
            method:'GET', 
            url:'accounts/schedules-by-account/'+accountId, 
            requireToken:true
        });

        var schedules = [];

        if(request === undefined){
            this.props.closeSesion();
            return;
        }

        //var f = new Date();
        //var hoy = f.getFullYear() + "-" + (f.getMonth() +1) + "-" + f.getDate();

        request.map((s)=>{
            var item = {
	            name:s.name,
	            label:s.label,
	            useBreakTime:s.useBreakTime,
                activeWeekend:s.activeWeekend !== undefined ? s.activeWeekend : false,
                nightShift:s.nightShift !== undefined ? s.nightShift : false,
                minimumHours:s.minimumHours,
                type: s.type,
                days:[],
                show:true,
            };

            if(item.type === 'flexible'){
                item.days.push({
                    startTime: s.startTimeDay !== null ? moment(s.startTimeDay).format('HH:mm') : "",
                    closingTime: s.closingTimeDay !== null ? moment(s.closingTimeDay).format('HH:mm') : "",
                    breakTime: s.breakInTimeDay !== null ? moment(s.breakInTimeDay).format('HH:mm') : "",
                    breakReturnTime: s.breakOutTimeDay !== null ? moment(s.breakOutTimeDay).format('HH:mm') : "",
                    
                    error_start: "",
                    error_close: "",
                    error_break: "",
                    error_return: "",
                    
                    check: true,
                
                });

            }else if(item.type === 'traditional'){
                this.state.days.map((day)=>{
                    var index = s.days.findIndex(obj => obj.name === day);
                    var obj = {};
                    if(index !== -1){
                        obj = {
                            name: s.days[index].name,
                            startTime: s.days[index].startTime !== null ? moment(s.days[index].startTime).format('HH:mm') : "",
                            closingTime: s.days[index].closingTime !== null ? moment(s.days[index].closingTime).format('HH:mm') : "",
                            breakTime: s.days[index].breakTime !== null ? moment(s.days[index].breakTime).format('HH:mm') : "",
                            breakReturnTime: s.days[index].breakReturnTime !== null ? moment(s.days[index].breakReturnTime).format('HH:mm') : "",
                        
                            error_start: "",
                            error_close: "",
                            error_break: "",
                            error_return: "",
                            
                            check: s.days[index].startTime === null ? false : true,
                        };

                        if(obj.name === 'saturday' || obj.name === 'sunday'){
                            item.activeWeekend = true;
                        }
                    }else{
                        obj = {
                            name: day,
                            startTime: "",
                            closingTime: "",
                            breakTime: "",
                            breakReturnTime: "",
                        
                            error_start: "",
                            error_close: "",
                            error_break: "",
                            error_return: "",
                            
                            check: false,
                        };
                    }

                    return item.days.push(obj);
                });

            }else if(item.type ==='24x24'){
                item.days.push({
                    startTime: s.startTimeDay !== null ? moment(s.startTimeDay).format('HH:mm') : "",
                    closingTime: s.closingTimeDay !== null ? moment(s.closingTimeDay).format('HH:mm') : "",
                    breakTime: s.breakInTimeDay !== null ? moment(s.breakInTimeDay).format('HH:mm') : "",
                    breakReturnTime: s.breakOutTimeDay !== null ? moment(s.breakOutTimeDay).format('HH:mm') : "",
                    
                    error_start: "",
                    error_close: "",
                    error_break: "",
                    error_return: "",
                    
                    check: true,
                
                });
            }

            schedules.push(item);
            return s;
        });


        if(this.props.wizard){
            this.setState({schedules:schedules, select_name: !this.props.save_schedule, edit_schedule: true});
            
            if(schedules.length > 0){
                this.schedule_form.setState({schedule: schedules[0]});
            }
                        
            setTimeout(()=>{
                this.props.changeColorNext();
            }, 500);
        }else if(this.props.details){
            this.setState({schedules:schedules, select_name: !this.props.save_schedule, edit_schedule: true});
        }
        
    }

    compareHours(i, f){
        var inicial = moment(i, 'HH:mm');
        var final = moment(f, 'HH:mm');
        var valid = false;

        if(final.isBefore(inicial)){
            valid = true;
        }

        return valid;
    }

    validHours(schedule){
        schedule.days.map((d, j)=>{
            d.error_start = '';
            d.error_break = '';
            d.error_return = '';
            d.error_close = '';

            if(schedule.nightShift){
                if(this.compareHours( d.closingTime, d.startTime)){
                    d.error_start = 'error';
                }

                if(schedule.useBreakTime){
                    if(this.compareHours(d.breakTime, d.startTime)){
                        d.error_start = 'error';
                    }

                    if(this.compareHours(d.breakTime, d.breakReturnTime)){
                        d.error_break = 'error';
                    }
                    if(this.compareHours(d.breakReturnTime, d.startTime)){
                        d.error_start = 'error';
                    }

                    
                    if(this.compareHours(d.breakReturnTime, d.closingTime)){
                        d.error_return = 'error'
                    }

                    if(this.compareHours(d.breakTime, d.closingTime)){                		
                        d.error_break = 'error';
                    }
                }
            }else{
                if(this.compareHours(d.startTime, d.closingTime)){
                    d.error_start = 'error';
                }

                if(schedule.useBreakTime){
                    if(this.compareHours(d.startTime, d.breakTime)){
                        d.error_start = 'error';
                    }

                    if(this.compareHours(d.breakTime, d.breakReturnTime)){
                        d.error_break = 'error';
                    }
                    if(this.compareHours(d.startTime, d.breakReturnTime)){
                        d.error_start = 'error';
                    }

                    
                    if(this.compareHours(d.breakReturnTime, d.closingTime)){
                        d.error_return = 'error'
                    }

                    if(this.compareHours(d.breakTime, d.closingTime)){                		
                        d.error_break = 'error';
                    }
                }
            }


            return d;
        });
    }

    changeRules(e, i){
		var schedule = this.schedule_form.state.schedule;

		schedule.days[i][e.target.name] = e.target.type ==='checkbox' ? e.target.checked : e.target.value;

		if(i === 0 && e.target.type !== 'checkbox'){
            
			schedule.days.map((d, j)=>{
				if(j !== i){
                    d[e.target.name] = e.target.value;
                }              

                return d;
            });
		}

		if(e.target.type !== 'checkbox'){
			this.validHours(schedule)
		}

        this.schedule_form.setState({schedule: schedule});
        this.props.changeColorNext();
    }

    newRule(e){
        //this.props.handleChange({target:{name: 'schedule_name', value:this.state.schedule.name}});
        if(sessionStorage.getItem('suscription') === 'free' && this.state.schedules.length >= 1){
            this.verifySuscription();   
        }else{
            this.props.handleChange(true, {target:{name:'save_schedule', value: true}});
            this.setState({select_name: false});  
            this.schedule_form.selectRule(e); 
            this.schedule_form.setState({edit_schedule:true});
        }
        
    }

    verifySuscription(){
        var obj = {
            title           : this.props.t('modal.alert.titles.suscription'),
            text            : this.props.t('modal.alert.messages.update_suscription'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.go_plans'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.cancel'),
            values          : '',
            fn              : ()=>{this.props.history.push('/settings/account')}
        }
        
        swalAction(obj);  
    }

    async updateSchedules(){
        var schedules = this.state.schedules;
        var schedule = this.schedule_form.state.schedule;

        var index = schedules.findIndex(item => item.label === schedule.label);

        if(index !== -1){
            schedules[index] = schedule;
        }

        var items = [];
        schedules.map((s)=>{
            var item = {
                name: s.name,
                label: s.label === "" ? s.name.replace(' ', '') : s.label,
                useBreakTime: s.useBreakTime,
                activeWeekend: s.activeWeekend,                
                nightShift: s.nightShift,
                workingHour: s.workingHour,
                breakTime: s.breakTime,
                minimumHours: s.minimumHours,
                type: s.type,
                days: []
            };
    
            s.days.map((d, i) =>{
                d.date = d.date === undefined ? moment().format('YYYY-MM-DD') : d.date;
                var obj = {
                    name: d.name,
                    key: d.name,
                    check:d.check
                };
    
                switch(s.type){
                    case 'traditional':
                        if(!d.check){
                            obj.startTime = null;
                            obj.closingTime = null;
                            obj.breakTime = null;
                            obj.breakReturnTime = null;
                        }else{
                            obj.startTime = d.startTime !== "" ? moment(d.date + ' ' + d.startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            obj.closingTime = d.closingTime !== "" ? moment(d.date + ' ' + d.closingTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            obj.breakTime = d.breakTime !== "" ? moment(d.date + ' ' + d.breakTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            obj.breakReturnTime = d.breakReturnTime !== "" ? moment(d.date + ' ' + d.breakReturnTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        }
                        break;
                    case 'flexible':
                        if(i === 0){
                            item.startTimeDay = d.startTime !== "" ? moment(d.date + ' ' + s.days[0].startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            item.closingTimeDay = d.closingTime !== "" ? moment(d.date + ' ' + s.days[0].closingTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            item.breakInTimeDay = d.breakTime !== "" ? moment(d.date + ' ' + s.days[0].breakTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            item.breakOutTimeDay = d.breakReturnTime !== "" ? moment(d.date + ' ' + s.days[0].breakReturnTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        } 
                        break;
                    case '24x24':
                        item.startTimeDay = d.startTime !== "" ? moment(d.date + ' ' + s.days[0].startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        break;
                    default:
                        break;
                }
                
                if(item.type === 'traditional'){
                    item.days.push(obj);
                }

                return obj;
            });

            return items.push(item);
        });
        

        var request = await this.props.fetchRequest({
            api:'accounts',
            method:'POSt',
            url:'accounts/update-schedules',
            obj:items,
            requireToken:true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading')
        });

        if(request !== undefined){
            this.schedule_form.setState({edit_schedule: false});
        }
    }

    showHideRule(name){
        var schedules = this.state.schedules;

        schedules.map((s)=>{
            if(s.type !== name){
                s.show = false;
            }else{
                s.show = true;
            }

            return s;
        });
       
        this.setState({schedules: schedules});
    }

    render(){
        return(
            <div>
                <ScheduleForm 
                    {...this.props}
                    select_name={this.state.select_name}
                    schedules={this.state.schedules}
                    newRule={this.newRule}
                    selectRule={this.props.handleChange}
                    wizard={this.props.wizard}
                    details={this.props.details}
                    changeRules = {this.changeRules}
                    changeColorNext={this.props.changeColorNext}
                    updateSchedules={this.updateSchedules}
                    showHideRule={this.showHideRule}
                    onRef={ref => this.schedule_form = ref}
                />
            </div>
        )
    }
    
}
export default ScheduleRules