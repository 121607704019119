import React, {Component} from 'react';

import {Button, Modal, Col, FlexboxGrid, FormGroup, ControlLabel, Alert } from 'rsuite';
import { withTranslation } from 'react-i18next';
import validator from 'validator';

class ModalAccountUser extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);

		this.state = {
            show: false,
            email:"",
            error_email:"",
            userTypes:[],
            userType:'',
            locations:[],
            locationId:'',
            name:'',
            listContractors:[],
            contractorId:'',
		}
    }

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	async handleShow(roleId){
        await this.setState({show:true,userType:'',locationId:'',name:''});

        this.LoadData();
	}

    async LoadData (){
        this.props.modal_loader.handleShow()

        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            method:'GET',
            url:'lookups?type=user_type',
            requireToken:true,
        }
		
		let res1 =  await this.props.fetchRequest(req);

        if (res1) {
            //console.log(res1)
            this.setState({userTypes: res1});
        }

        var req2 = {
            api:'accounts',
            method:'GET',
            url:'locations/by-account/'+accountId,
            requireToken:true,
        }
		
		let res2 =  await this.props.fetchRequest(req2);

        if (res2) {
            this.setState({locations: res2});
        }

        var req3 = {
            api:'accounts',
            method:'GET',
            url:'lookups/by-account-and-type/'+accountId+'/contractor',
            requireToken:true
        }

        let res3 = await this.props.fetchRequest(req3);
        if (res3) {
            this.setState({listContractors: res3});
        }
        this.props.modal_loader.handleClose();

    }

	handleClose(){
		this.setState({
            show:false, 
            email:"",
            userType:'',
            locationId:'',
            name:'',
            contractorId:''
        });
    }

    async save(){
        if(this.state.email !== "" || this.state.userType !== ''){	
            //Validar correo
            if(this.state.error_email !== '' ){
                Alert.error(this.props.t('error_alert.incomplete_fields'));
                return;
            }

            //validar ubicación para cuendo es cliente
            if (this.state.userType === 'client' && this.state.locationId === '') {
                Alert.error(this.props.t('error_alert.incomplete_fields'));
                return;
            }

            //validar nombre cuando es contratista
            if (this.state.userType === 'contractor' && this.state.contractorId === '') {
                Alert.error(this.props.t('error_alert.incomplete_fields'));
                return;
            }

            var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
            accountId = accountId.substring(1, accountId.length - 1);
            

            var obj = {
                email: this.state.email,
                userType:this.state.userType,
                locationId: this.state.userType === 'client'? this.state.locationId : null,
                //name: this.state.userType === 'contractor'? this.state.name : null,
                contractorId: this.state.userType === 'contractor'? this.state.contractorId : ''
            };

            //console.log(obj)
            //return
            var req = {
                api:'auth',
                method:'POST',
                url:'users/add-user/'+accountId,
                obj:obj,
                modal:this.props.modal_loader,
                message_modal:this.props.t('modal.spin.saving'),
                requireToken:true
            }
            
            var response = await this.props.fetchRequest(req);
            if(response !== null){
                this.props.getAccountUsers();
            }
            this.handleClose();
        }else{
            Alert.error(this.props.t('error_alert.incomplete_fields'));
        }
		
    }

    handleChange(e){
		let	value = e.target.value;
        let name = e.target !== undefined ? e.target.name : e;
        
        this.setState({[name]: value});	

        if(!validator.isEmail(e.target.value)){
            this.setState({error_email:this.props.t('error_alert.invalid_email')});
        }else{
            this.setState({error_email:''});
        }
    }

	render(){
		const {t} = this.props;
		return (
			<Modal backdrop={false} show={this.state.show} size="sm">
				<Modal.Header>	
					<Modal.Title>{this.props.t('screen.settings.account.users.modal_title')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <FlexboxGrid >
                        <Col xs={24} >
                            <FormGroup>
                                <ControlLabel>{this.props.t('screen.settings.account.users.type')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                <select className="form-control form-control-sm" id="roleId" name="roleId" 
                                    value={this.state.userType} required
                                    onChange={(e)=>{
                                        //props.handleChange(e)
                                        this.setState({userType: e.target.value});
                                    }} 
                                    
                                >
                                    <option value="">{t('general.select')}.</option>
                                    {this.state.userTypes.map((r, i)=>
                                            <option value={r.key} key={r.id}>{r.value}</option>                                            
                                    )}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col xs={24} >
                            <FormGroup>
                                <ControlLabel>{this.props.t('screen.settings.account.users.email')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                <span style={{color:'red', fontSize:12, marginLeft:10}}>{this.state.error_email}</span>
                                <input type="text" className="form-control form-control-sm"
									value={this.state.email} required
									name="email"
									onChange={(e)=>this.handleChange(e)} 
								/>
                            </FormGroup>	
						</Col>
                        {this.state.userType === 'client' &&
                            <Col xs={24} >
                                <FormGroup>
                                    <ControlLabel>{this.props.t('screen.settings.account.users.location')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                    <select className="form-control form-control-sm" id="roleId" name="roleId" 
                                        value={this.state.locationId} required
                                        onChange={(e)=>{
                                            //props.handleChange(e)
                                            this.setState({locationId: e.target.value});
                                        }} 
                                        
                                    >
                                        <option value="">{t('general.select')}.</option>
                                        {this.state.locations.map((r, i)=>
                                                <option value={r.id} key={r.id}>{r.name}</option>                                            
                                        )}
                                    </select>
                                </FormGroup>
                            </Col>
                        }
                        {this.state.userType === 'contractor' &&
                        <Col xs={24} >
                            <FormGroup>
                                <ControlLabel>{this.props.t('screen.settings.account.users.contractor')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                <select className="form-control form-control-sm" id="contractorId" name="contractorId" 
                                    value={this.state.contractorId} required
                                    onChange={(e)=>{
                                        //props.handleChange(e)
                                        this.setState({contractorId: e.target.value});
                                    }} 
                                    
                                >
                                    <option value="">{t('general.select')}.</option>
                                    {this.state.listContractors.map((r, i)=>
                                            <option value={r.id} key={r.id}>{r.value}</option>                                            
                                    )}
                                </select>
                                {/*<input type="text" className="form-control form-control-sm"
                                    value={this.state.name} required
                                    name="name"
                                    onChange={(e)=>{
                                        this.setState({name: e.target.value});
                                    }} 
                                />*/}
                            </FormGroup>
                        </Col>
                        }
                    </FlexboxGrid>
				</Modal.Body>
				<Modal.Footer>
                    <Button appearance="primary" onClick={this.save}>{t('button.add')}</Button>
                    <Button appearance="default" onClick={this.handleClose}>{t('button.cancel')}</Button>
				</Modal.Footer>
			</Modal>
		);
	}

}

export default withTranslation('translations') (ModalAccountUser);