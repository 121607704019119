import React, {Component} from 'react';

import {Button, Modal, Col, FlexboxGrid, FormGroup, ControlLabel, Alert } from 'rsuite';
import { withTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';

class ModalPaymentSchemes extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getPaymentSchemes = this.getPaymentSchemes.bind(this);

		this.state = {
            show: false,
            years:[],
            year:new Date().getFullYear(),
            paymentSchemes:[],
            schemeType:""
		}
    }

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	async handleShow(schemeType){
        this.setState({show:true, schemeType:schemeType});
        await this.getExistenceYears();
        await this.getPaymentSchemes();
	}

	handleClose(){
		this.setState({show:false, year:null, paymentSchemes:[]});
    }
    
    async handleChangeYear(e){
        await this.setState({[e.target.name]: e.target.value});
        this.getPaymentSchemes();
    }

    async getExistenceYears(){
        var account_id  = atob(sessionStorage.getItem(btoa('account_id')));
        account_id = account_id.substring(1, account_id.length - 1);

        let req = {
            api:'accounts',
            method:'GET',
            url:'accounts/existence-years-by-account/'+account_id,
            requireToken:true
        };
        let response =  await this.props.fetchRequest(req);
        if(response !== null&& response !== undefined){
            this.setState({years:response});
        }
    }

    async getPaymentSchemes(){
        var account_id  = atob(sessionStorage.getItem(btoa('account_id')));
        account_id = account_id.substring(1, account_id.length - 1);

        if(this.state.year !== "" && this.state.year !== null){
            let req = {
                api:'accounts',
                method:'GET',
                url:'payment_schemes/by-year-and-account/'+this.state.year+"/"+account_id,
                requireToken:true
            };
            let response =  await this.props.fetchRequest(req);

            if(response !== null&& response !== undefined){
                this.setState({paymentSchemes:response});
            }
        }
    }

    async paymentSchemeGeneration(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        if(this.state.year === null || this.state.year === ""){
            Alert.error("Campo año no seleccionado");
            return;
        }
        let req = {
            api:'accounts',
            method:'POST',
            url:'payment_schemes/generation-by-account/'+this.state.schemeType+"/"+this.state.year+"/"+accountId,
            requireToken:true,
            modal:this.props.modal_loader,
            message_modal:'Generando....'
        };
        let response =  await this.props.fetchRequest(req);
        if(response !== null&& response !== undefined){
            this.setState({paymentSchemes: response});
        }
    }

	render(){
		const {t} = this.props;
		return (
			<Modal backdrop={false} show={this.state.show} size="md">
				<Modal.Header>	
					<Modal.Title>Días de Pago</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <FlexboxGrid >
						<Col xs={6} >
                            <FormGroup>
                                <ControlLabel>Año</ControlLabel>
                                <select className="form-control form-control-sm is-number" name="year" 
                                    id="year" value={this.state.year} onChange={(e)=>this.handleChangeYear(e)}>
                                    <option value="">{t('general.select')}</option>
									{this.state.years.map((y, i)=>(
										<option key={i} value={y}> {y}</option>
									))}
                                </select>		
                            </FormGroup>	
						</Col>
                        <Col xs={4} >
                            <FormGroup>
                                <Button className="s-btn-primary"
                                    onClick={()=>this.paymentSchemeGeneration()} style={{marginLeft:10, marginTop:23}}>
                                    Generar
                                </Button>	
                            </FormGroup>	
						</Col>
                        <Col xs={24} sm={24}>
                            <Table responsive hover style={{marginTop:'15px'}}>
                                <thead>
                                    <tr>
                                        <th>No. Pago</th>
                                        <th>Periodo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.paymentSchemes.map((s, i)=>(
                                        <tr key={i}>
                                            <td>{s.paymentNumber}</td>
                                            <td>{s.label}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </FlexboxGrid>
				</Modal.Body>
				<Modal.Footer>
					<FlexboxGrid>
						<Col xs={24} style={{textAlign:'right'}}>
							<Button appearance="ghost" onClick={this.handleClose} >
								Cerrar
							</Button>
						</Col>
					</FlexboxGrid>
				</Modal.Footer>
			</Modal>
		);
	}

}

export default withTranslation('translations') (ModalPaymentSchemes);