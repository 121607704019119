import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import { Grid, Col, ControlLabel, Button } from 'rsuite';
import Layout from '../../../components/Layout';
import TableList from '../../../components/TableList';
import Swal from 'sweetalert2';
import $ from 'jquery';
import ModalMap from '../ModalMap';
import { fileDownLoad } from '../../../lib/fetchRequest';

class Visit extends Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.updateColumns = this.updateColumns.bind(this);
        this.actionIcon = this.actionIcon.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.downloadEvidences = this.downloadEvidences.bind(this);

        this.state = {
            start_date:moment().startOf('month').format('YYYY-MM-DD'),
            end_date:moment().format('YYYY-MM-DD'),
            data:[],
            filename:'visit.name',
            columns:[
                {title:'employee', name:'employee', visible:true},
                {title:'start_date', name:'start_date', visible:true},
                {title:'start_location', name:'start_location', visible:true},
                {title:'end_date', name:'end_date', visible:true},
                {title:'end_location', name:'end_location', visible:true},
                {title:'visit_time', name:'visit_time', visible:true},
                {title:'visitName', name:'visitName', visible:true},
                {title:'evidence', name:'evidence', visible:true}
            ],
            list:[],
            visits:[],
            location:"all",
            locations:[],
            userType:'',
            locationId:''
        }
    }

    async componentDidMount(){
        console.log(this.props.location)
        if(this.props.location.state !== undefined){
            await this.setState({
                start_date: this.props.location.state.startDate,
                end_date: this.props.location.state.endDate
            });
        }else{
            this.table.setTable();
        }

        await this.GetLocations();
        
    }

    async GetLocations(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var locations = await this.props.fetchRequest({
            api:'accounts',
            method:'get',
            url:'locations/by-account/'+accountId,
            obj:null,
            requireToken:true
        });

        if(locations !== undefined){
            this.setState({locations: locations});
        }

        let user = sessionStorage.getItem('userType');
        if (user === 'client') {
            locations.map((l,i) => {
                if (l.id === sessionStorage.getItem('locationId')) {
                    this.setState({
                        location: l.name,
                        userType:'client',
                        locationId:l.id
                    });
                }
            })
        }

        await this.search(null, 'reports/visits-by-account/');
    }


    handleChange(e){
        this.setState({[e.target.name]: e.target.value});
    }

    async search(e, url){
        $('.rs-btn-default').blur();
        var error = "";

        var obj = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            locationId: this.state.userType === 'client' ? this.state.locationId : null
        };

        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api: 'reports',
            url: 'reports/visits-by-account/'+accountId,
            obj: obj,
            method: 'POST',
            modal: this.props.modal_loader,
            message_modal: this.props.t('modal.spin.searching'),
            requireToken: true
        };

        if(error === ""){
            var request = await this.props.fetchRequest(req);
            var list = [];
            //tipo/icono/color/nombre/accion
            request.map((l)=>
                list.push([
                    l.employee,
                    moment(l.visitStartDate).format('DD/MM/YYYY HH:mm'),
                    l.visitStartLocation !== null && l.visitStartLocation !== ""? 
                       'icon/' + l.visitStartLocation + '/#7E7E7E/no_location/one/'+ l.startLatitude+'/'+l.startLongitude
                    : 'icon/map-marker/'+(l.startLatitude !== null && l.startLongitude !== null ? 
                        'green/start_location/one/' : '#7E7E7E/no_location/no_action/')+
                        l.startLatitude+'/'+l.startLongitude,
                    moment(l.visitEndDate).format('DD/MM/YYYY HH:mm'),
                    l.visitEndLocation !== null && l.visitEndLocation !== "" ?
                        'icon/' + l.visitEndLocation + '/#7E7E7E/no_location/one/' + l.endLatitude+'/'+l.endLongitude
                        : 'icon/map-marker/'+(l.endLatitude !== null && l.endLongitude !== null ? 
                            'green/end_location/one/' : '#7E7E7E/no_location/no_action/')+
                            l.endLatitude+'/'+l.endLongitude,
                    l.visitTime !== null ? l.visitTime : '',
                    l.visitName === "null"?"":l.visitName,
                    "icon/image/"+(l.flagEvidence ? '#2196F3' : '#7E7E7E')+"/evidence/"+(l.flagEvidence ? 'one' : 'no_action')
                ])
            );

            setTimeout(()=>{
                this.props.modal_loader.handleShow(this.props.t('modal.spin.loading'));
                this.table.resetTable();
            }, 500);

            setTimeout(()=>{
                this.props.modal_loader.handleClose();
                this.setState({list: list, visits: request});
                this.table.setTable();

            }, 1000);
        }else{
            Swal.fire('Error', error, 'error');
            
        }
        

    }

    async actionIcon(e, i, d){
        if(d === 'evidence'){
            var obj = this.state.visits[i];
            var id = obj.visitId;

            this.props.history.push({                
                pathname: '/reports/visit/details/'+id,
                state: { 
                    startDate: this.state.start_date,
                    endDate: this.state.end_date
                }
            });
        }else{
            this.getLocation(e, i, d);
        }

        

    }

    updateColumns(e, i){
        var columns = this.state.columns;
        columns[i].visible = e.target.checked;
        this.setState({columns: columns});
    }

    getLocation(e, i, d){
        var list = this.state.list[i];
        //console.log(list)
        var index = list.findIndex(obj => obj.indexOf('map-marker/green/'+d) !== -1 );

        if(index !== -1){
            var name = list[0];
            var lat = parseFloat(list[index].split('/')[5]);
            var lng = parseFloat(list[index].split('/')[6]);

            this.modal_map.handleShow(lat, lng, name)
        }else {
            var name = list[0];
            var lat = parseFloat(list[2].split('/')[5]);
            var lng = parseFloat(list[2].split('/')[6]);

            this.modal_map.handleShow(lat, lng, name)
        }
        
    }

    async downloadEvidences(){
        //console.log('descarga');
        if (this.state.list.length === 0) {
            console.log('sin datos')
            return;
        }
        
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);
        var obj = {
            startDate: this.state.start_date,
            endDate: this.state.end_date
        };
        var date1 = this.state.start_date.replace(/-/g, "");
        var date2 = this.state.end_date.replace(/-/g, "");
        //console.log(date1)
        //console.log(date2)
        //console.log('evidencias_'+date1+'_al_'+date2);
        //return;
        /*var suscription = sessionStorage.getItem('suscription');

        if(suscription === 'free' && this.state.employees.length >= 5){
            this.verifySuscription();
        }else{*/
            var res = await this.props.fetchRequest({
                api:'reports',
                method:'POST',
                url:'reports/visits-list/'+accountId,
                requireToken:true,
                obj:obj,
                modal:this.props.modal_loader,
                message_modal:'Descargando...',
                is_file:true
            });
    
            if(res !== undefined){
                //console.log(res)
                //fileDownLoad('template', ".xlsx", 'application/vnd.ms-excel', res);
                
                await fileDownLoad('evidencias_'+date1+'_al_'+date2, ".xlsx", 'application/vnd.ms-excel', res);
            }
        /*}    */    
    } 

    render(){
        const {t} = this.props;
        return (
            <Layout {...this.props}>
                <Grid fluid>
                    <Col xs={24} style={{textAlign:'center'}}>
                        <h3>{t('screen.reports.'+this.state.filename)}</h3>
                    </Col>
                    <Col xs={24} className="form-group">
                        <Col xs={24} sm={4}>
                            <ControlLabel className="s-table-title">{t('screen.reports.absences.form.start_date')}</ControlLabel>
                            <input type="date" name="start_date" id="start_date" 
                                className="form-control form-control-sm"
                                value={this.state.start_date}
                                onChange={this.handleChange} />
                        </Col>

                        <Col xs={24} sm={4}>
                            <ControlLabel className="s-table-title">{t('screen.reports.absences.form.end_date')}</ControlLabel>
                            <input type="date" name="end_date" id="end_date" 
                                className="form-control form-control-sm"
                                value={this.state.end_date}
                                onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} sm={4}>
                           <ControlLabel style={{marginBottom:'2px'}} className="s-table-titles">{t('screen.reports.overtime.form.location')}</ControlLabel>
                            <select className="form-control form-control-sm" name="location" 
                                id="location" value={this.state.location}
                                onChange={(e)=>this.handleChange(e)} 
                                disabled={this.state.userType === 'client'? true : false}
                            >
                                <option value="all">{t('screen.reports.all')}</option>
                                {this.state.locations.map((loc, i)=>
                                    <option value={loc.name} key={i}>{loc.name}</option>
                                )}
                            </select>
                        </Col>


                        <Col xs={24} sm={6} style={{marginTop:24}}>
                            <Button className="s-btn-primary" onClick={(e)=>this.search(e, 'reports/visits')}>
                                {t('button.search')}
                            </Button>
                            <Button onClick={(e)=>this.props.history.goBack()} style={{marginLeft:8}}>
                                {t('button.return')}
                            </Button>
                        </Col>
                    </Col>
                    <Col xs={24}>
                        <Col xs={24}>
                            <TableList 
                                {...this.props} 
                                onRef={ref => this.table = ref} 
                                columns={this.state.columns}
                                btnNew={false}
                                data={this.state.list}
                                actions={[]}
                                actionIcon={this.actionIcon}
                                updateColumns={this.updateColumns}
                                screen={"visit"}
                                searching={false}
                                report={true}
                                table_title={[]}
                                filename={t('screen.reports.'+this.state.filename)}
                                scroll={false}
                                widths={['25%','15%','10%','15%','10%','10%','15%']}
                                search={this.search}
                                downloadEvidences={this.downloadEvidences}
                            />
                        </Col>
                    </Col> 
                </Grid>

                <ModalMap {...this.props} onRef={ref => this.modal_map = ref} />
            </Layout>
        )
    }
}

export default withTranslation('translations')(Visit);