import React, {Component} from 'react';

import {
	Modal, Button, Grid, Col
} from 'rsuite';

import BootstrapTable from 'react-bootstrap-table-next';
//import cellEditFactory from 'react-bootstrap-table2-editor';
import cellEditFactory from 'react-bootstrap-table2-editor';

class ModalTemplate extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onSave = this.onSave.bind(this);

		this.state = {
            show: false,
            columns:[],
            data:[],
		}
	}

	componentDidMount(){
        
		this.props.onRef(this);
	}

	/*componentDidUmount(){
		this.props.onRef(null);
	}*/

    componentWillUnmount() {
        this.props.onRef(null);
    }

	async handleShow(l, c){
        /*console.log(cellEditFactory)
        console.log(c)
        console.log(l)*/
       
        await this.setState({
            data:l,
            columns:c
        });

        await this.setState({
            show:true,
            /*data:l,
            columns:c*/
        });
        
	}

	handleClose(){
		this.setState({show:false, data:[], columns:[]});
    }

    async onSave(){
        var obj = [];
        let flagNumber = false, flagFirst = false, flagLast = false, flagEmail = false;;
        this.state.data.map((d)=>{
            var item = {};
            var keyNames = Object.keys(d);
            var special_fields = [];

            keyNames.forEach((key)=>{
                if(key.indexOf('@') === -1){
                    if (key != 'key'){
                        item = {
                            ...item,
                            [key]:d[key]
                        }
                    }
                }else{
                    special_fields.push({
                        title: key.replace('@', ''),
                        value: d[key].toString()
                    });
                }
            });

            item = {
                ...item,
                attributes:{
                    ...item.attributes,
                    special_fields: special_fields
                }
            }

            if ((item.employeeNumber === undefined || item.employeeNumber === null || item.employeeNumber === '') ) {
                flagNumber = true;          
            }

            if ((item.firstName === undefined || item.firstName === null || item.firstName === '')) {
                flagFirst = true
            }

            if ((item.lastName === undefined || item.lastName === null || item.lastName === '')) {
                flagLast = true;
            }

            if ((item.email === undefined || item.email === null || item.email === '')) {
                flagEmail = true;
            }

            return obj.push(item);
        });

        if (flagNumber === true) {
            this.props.Alert.error(this.props.t('error_alert.number_required'));
            return;
        }

        if (flagFirst === true) {
            this.props.Alert.error(this.props.t('error_alert.name_required'));
            return;
        }

        if (flagLast === true) {
            this.props.Alert.error(this.props.t('error_alert.last_required'));
            return;
        }

        if (flagEmail === true) {
            this.props.Alert.error(this.props.t('error_alert.email_required'));
            return;
        }

        //console.log(this.props.accountId);
        //return
        var res = await this.props.fetchRequest({
            api:'accounts',
            url:'employees/save-many/'+this.props.accountId,
            method:'POST',
            obj: obj,
            requireToken:true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading')
        });

        if(res !== undefined){
            setTimeout(()=>{
                this.props.reloadTable();
                this.handleClose();
            }, 500);
            
        }
        
    }

	render(){
        const {t} = this.props;
		return (
			<Modal size="xl" /*style={{width:'90%'}}*/ show={this.state.show} onHide={this.handleClose} backdrop={false}>
                <Modal.Title>{t('screen.settings.employee.modal_preview.title')}</Modal.Title>
				<Modal.Body>
					<div className="form-group">
						<Grid fluid>
                            <Col xs={24}>
                               {this.state.data.length > 0 &&
                                <BootstrapTable 
                                    //keyField={this.state.columns.length > 0 ? this.state.columns[0].dataField : ""}
                                    keyField={'key'}
                                    data={this.state.data}
                                    columns={this.state.columns}
                                    
                                    cellEdit={ cellEditFactory({
                                        mode: 'click',
                                        blurToSave: true
                                    }) }
                                />}
                            </Col>
                        </Grid>
					</div>
				</Modal.Body>
                <Modal.Footer>
                    <div style={{marginTop:10}}>
                        <Button color="blue" onClick={()=>this.onSave()}>{this.props.t('button.load')}</Button>
                        <Button style={{marginLeft:10}} onClick={this.handleClose}>{this.props.t('button.cancel')}</Button>
                    </div>                    
                </Modal.Footer>
			</Modal>
		);
	}

}

export default ModalTemplate;