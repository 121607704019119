import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import { Grid, Col, ControlLabel, AutoComplete, Button,  Icon, Table, Checkbox} from 'rsuite';

import Layout from '../../components/Layout';
import Swal from 'sweetalert2';
import $ from 'jquery';
import pdfMake from 'pdfmake/build/pdfmake';
import {
    ExcelExport, ExcelExportColumn, ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';

import {swalAction} from '../../lib/functions';

const { Column, HeaderCell, Cell } = Table;
class Attendance extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeBox = this.handleChangeBox.bind(this);
        this.search = this.search.bind(this);
        this.makeDocument = this.makeDocument.bind(this);

        this.state = {
            filename:'name',
            periods:[],
            period:"",
            employee:this.props.t('screen.reports.all'),
            employees:[],
            data:[],
            all_days_checked:false,
            attendanceList:[],
            pdf:[],
            list_xls:[],
            columns_xls:[
                {field:"Employee", title:'attendance.form.employee'},
                {field:"Date", title:'attendance.form.date'},
                {field:"CheckIn", title:'attendance.form.check_in'},
                {field:"CheckOut", title:'attendance.form.check_out'},
                {field:"Details", title:'attendance.form.details'},
                {field:"Worked", title:'attendance.form.worked'},
                {field:"Scheduled", title:'attendance.form.scheduled'},
                {field:"Differance", title:'attendance.form.difference'}
            ]
        }
    }
    _exporter;
    async componentDidMount(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            url:'employees/by-account/'+accountId,
            method:'GET',
            requireToken:true
        }
        var employees = await this.props.fetchRequest(req);
        var data = [this.props.t('screen.reports.all')];
        employees.map((e)=>(
            data.push(e.firstName + " "+e.lastName+(e.maternalSurname === null ? '' : ' '+e.maternalSurname))
            /*e.label = e.firstName +' '+ e.lastName+' '+e.maternalSurname,
            e.value = e.id*/
        ));

        var params = {
            api:'accounts',
            url:'payment_schemes/last-ten-records-by-account/'+accountId,
            method:'GET',
            requireToken:true
        }

        var periods = await this.props.fetchRequest(params);

        if(periods.length === 0){
            swalAction({
                title           : this.props.t('modal.alert.titles.alert'),
                text            : this.props.t('modal.alert.messages.pay_days'),
                icon            : 'warning',
                showConfirm     : true,
                showCancel      : true,
                textConfirm     : this.props.t('modal.alert.confirms.go_to'),
                colorConfirm    : '#3085d6',
                colorCancel     : '#d33',
                textcancel      : this.props.t('modal.alert.confirms.no'),
                values          : '',
                fn              : ()=>{this.props.history.push("/settings/account")}
            });
        }
        this.setState({employees: employees, data: data, periods:periods});
        
    }

    handleChange(e){
        this.setState({[e.target.name]: e.target.value});
    }

    handleChangeBox(value) {
        this.setState({all_days: !value});
    }

    async search(e, url){
        $('.rs-btn-default').blur();
        var id = '';
        var error = "";
        var period = {}
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);
        
        for (let index = 0; index < this.state.periods.length; index++) {            
            if (this.state.periods[index].id === this.state.period) {
                period = this.state.periods[index];
                break;
            }
        }

        if(this.state.period === ''){
            error = this.props.t('error_alert.no_period');
        }

        if(this.state.employee === this.props.t('screen.reports.all')){
            id = 'all';
        }else if(this.state.employee === ""){
            error = this.props.t('error_alert.no_employee');
        }else{
            var index = this.state.employees.findIndex(obj =>
                obj.firstName +" "+obj.lastName+(obj.maternalSurname === null ? '' : ' '+obj.maternalSurname) === this.state.employee
            );            

            id = this.state.employees[index].id;
        }

        if(error === ''){
            var obj = {
                startDate: period.startDate.substring(0,10),
                endDate: period.endDate.substring(0,10),
                employeeId: id
            };

            var req = {
                api: 'reports',
                url: "reports/attendance-by-account/"+accountId,
                obj: obj,
                method: 'POST',
                modal: this.props.modal_loader,
                message_modal: this.props.t('modal.spin.searching'),
                requireToken: true
            };

            var request = await this.props.fetchRequest(req);
            var list = [];

            request.forEach(r => {
                var item = {};
                item.employee = r.employee.name;
                item.date = moment(r.date).format('DD/MM/YYYY');

                if (r.restDay === false) {
                    if (r.details.checkIn) {
                        item.check_in = r.details.checkIn.timeFormatted.substring(11,16);

                        if (r.details.checkIn.validFace === true) {
                            item.chin_color = 'green';
                            item.chin_tooltip = "La identidad del usuario fue Validada";
                        } else {
                            item.chin_color = 'red';
                            item.chin_tooltip = "La identidad del usuario NO fue Validada"
                        }

                        if (r.details.checkIn.location) {
                            item.chinloc_color = 'green';
                        } else {
                            item.chinloc_color = 'red';
                        }
                        item.chin_location = r.details.checkIn.location
                        item.delay = r.details.checkIn.delay;
                    } else {
                        item.check_in = null;
                        item.chin_tooltip = "Sin registro de check principalmente"
                    }

                    if (r.details.checkOut) {
                        item.check_out = r.details.checkOut.timeFormatted.substring(11,16);

                        if (r.details.checkOut.validFace === true) {
                            item.chout_color = 'green';
                            item.chout_tooltip = "La identidad del usuario fue Validada"
                        } else {
                            item.chout_color = 'red';
                            item.chout_tooltip = "La identidad del usuario NO fue Validada"
                        }

                        if (r.details.checkOut.location) {
                            item.choutloc_color = 'green';
                        } else {
                            item.choutloc_color = 'red';
                        }
                        item.chout_location = r.details.checkOut.location
                    } else {
                        if (r.absence === false) {
                            item.check_out = r.details.expectedCheckout !== null ? r.details.expectedCheckout.substring(11,16) : "";
                            item.checkout_bgcolor = 'grey';
                            item.chout_tooltip = "Sin registro de check principalmente"
                        }
                        
                    }
                    
                    //item.check_out = r.details.checkOut? r.details.checkOut.timeFormatted.substring(11,16)  :null;
                    item.total = r.details.worked;

                    item.worked = r.details.worked;
                    item.scheduled = r.details.scheduled;
                    item.differance = r.details.differance;
                    item.absence = r.absence;
                    if(item.differance !== undefined && item.differance !== null){
                        if (item.differance.includes("-")) {
                            item.color = 'red';
                        } else {
                            item.color = 'green';
                        }
                    }

                    if (r.absence === true) {
                        item.bg_color = '#ff000073';
                    }else {
                        item.bg_color = null;
                    }

                    list.push(item)                
                } else {
                    if (this.state.all_days === true) {
                        list.push(item);
                    }
                }

            });

            var pdf = [];
            var xls = [];
            list.map((l, i)=>{
                pdf.push([
                    {text:l.employee, style:(i % 2 === 0 ? 'tableBodyEven' : 'tableBodyOdd')},
                    {text:l.date, style:(i % 2 === 0 ? 'tableBodyEven' : 'tableBodyOdd')},
                    {text:l.check_in, style:(i % 2 === 0 ? 'tableBodyEven' : 'tableBodyOdd')},
                    {text:l.check_out, style:(i % 2 === 0 ? 'tableBodyEven' : 'tableBodyOdd')},
                    {text:"", style:(i % 2 === 0 ? 'tableBodyEven' : 'tableBodyOdd')},
                    {text:l.worked, style:(i % 2 === 0 ? 'tableBodyEven' : 'tableBodyOdd')},
                    {text:l.scheduled !== null ? l.scheduled : "", style:(i % 2 === 0 ? 'tableBodyEven' : 'tableBodyOdd')},
                    {text:l.differance !== null ? l.differance : "", style:(i % 2 === 0 ? 'tableBodyEven' : 'tableBodyOdd')},
                ]);

                xls.push({
                    Employee:l.employee,
                    Date:l.date,
                    CheckIn:l.check_in,
                    CheckOut:l.check_out,
                    Details:"",
                    Worked:l.worked,
                    Scheduled:l.scheduled !== null ? l.scheduled : "",
                    Differance:l.differance !== null ? l.differance : "",
                })
            });

            this.setState({attendanceList: list, pdf: pdf, list_xls:xls});
        }else{
            Swal.fire('Error', error, 'error');
        }
    }

    makeDocument(){
        var doc = {
            content:[
                {text: this.props.t('screen.reports.attendance.'+this.state.filename), alignment:'center', fontSize:15, bold:1, margin:[0,0,0,12]},
                {table:{
                    body:[[
                        {text:this.props.t('screen.reports.attendance.form.employee'), style:"tableHeader", fillColor:"#2d4154"},
                        {text:this.props.t('screen.reports.attendance.form.date'), style:"tableHeader", fillColor:"#2d4154"},
                        {text:this.props.t('screen.reports.attendance.form.check_in'), style:"tableHeader", fillColor:"#2d4154"},
                        {text:this.props.t('screen.reports.attendance.form.check_out'), style:"tableHeader", fillColor:"#2d4154"},
                        {text:this.props.t('screen.reports.attendance.form.details'), style:"tableHeader", fillColor:"#2d4154"},
                        {text:this.props.t('screen.reports.attendance.form.worked'), style:"tableHeader", fillColor:"#2d4154"},
                        {text:this.props.t('screen.reports.attendance.form.scheduled'), style:"tableHeader", fillColor:"#2d4154"},
                        {text:this.props.t('screen.reports.attendance.form.difference'), style:"tableHeader", fillColor:"#2d4154"}
                    ]],
                    widths:["20%","10%", "10%", "10%", "20%", "10%", "10%", "10%"]
                },
                layout:"noBorders"
                }
            ],
            styles:{
                tableHeader:{
                    bold:true, fontSize:9, color:'white', fillColor:'#2d4154', alingment:'center'
                },
                tableBodyOdd:{fillColor:"#f3f3f3"},
                tableBodyEven:{}
            },
            defaultStyle:{
                fontSize:8
            }
        };
        this.state.pdf.map((t)=>
            doc.content[1].table.body.push(t)
        );
        
        pdfMake.createPdf(doc).download(this.props.t('screen.reports.attendance.'+this.state.filename));
    }

    export = ()=>{
        this._exporter.save();
    }

    render(){
        const {t} = this.props;
        return (
            <Layout {...this.props}>
                <Grid fluid>
                    <Col xs={24} style={{textAlign:'center'}}>
                        <h3>{t('screen.reports.attendance.'+this.state.filename)}</h3>
                    </Col>
                    <Col xs={24} >
                        <Col sm={8} md={4}>
                            <ControlLabel className="s-table-title">{t('screen.reports.attendance.form.period')}</ControlLabel>
                            <select className="form-control form-control-sm" name="period" id="period" 
                                value={this.state.period} onChange={(e)=>this.handleChange(e)} required >
                                    <option value="">{t('general.select')}</option>
                                    {this.state.periods.map((inc, i)=>(
                                        <option key={i} value={inc.id}>
                                            {inc.label}
                                        </option>
                                    ))}
                            </select>
                        </Col>
                        
                        <Col sm={8} md={4} className="form-group">
                            <ControlLabel className="s-table-title">{t('screen.reports.attendance.form.employee')}</ControlLabel>
                            <AutoComplete
                                data={this.state.data}
                                value={this.state.employee}
                                onChange={(e)=>this.handleChange({target:{name:'employee', value:e}})}
                                id="employee"
                                autoComplete="off"
                                required
                            />
                            
                        </Col>
                        <Col sm={8} md={4} className="form-group">
                            <br/>
                            <Checkbox id="all_days" name="all_days"
                                value={this.state.all_days}
                                /*onChange={(e)=>this.handleChange({target:{name:'all_days', value:e}})}*/
                                onChange={this.handleChangeBox}
                                > 
                                {t('screen.reports.attendance.form.all_days')}
                            </Checkbox>
                        </Col>
                        <Col sm={8} md={4} style={{textAlign:'center'}}>
                            <br/>
                            <Button className="s-btn-primary" onClick={(e)=>this.search(e, 'reports/attendance')}>
                                {t('button.search')}
                            </Button>
                            <Button onClick={(e)=>this.props.history.goBack()} style={{marginLeft:8}}>
                                {t('button.return')}
                            </Button>
                        </Col>
                    </Col>
                    <Col xs={24} style={{textAlign:'right'}}>
                        <Button style={{marginRight:8}} onClick={()=>this._exporter.save()} disabled={this.state.list_xls.length === 0}>Excel</Button>
                        <Button style={{marginRight:8}} onClick={()=>this.makeDocument()} disabled={this.state.pdf.length === 0}>
                            PDF
                        </Button>
                    </Col>
                    <Col xs={24}>
                        <Table
                            data={this.state.attendanceList}
                            rowClassName={(rowData) => {
                                //console.log(rowData)
                                return rowData?.bg_color ? "karya-table-row" : "";
                              }}
                            onRowClick={data => {
                                console.log(data);
                            }}
                            autoHeight={true}
                        >
                             <Column width={Math.round(window.innerWidth * (15/100))} fixed>
                                <HeaderCell>{t('screen.reports.attendance.form.employee')}</HeaderCell>
                                <Cell dataKey="employee" />
                            </Column>
                            <Column width={Math.round(window.innerWidth * (10/100))} fixed>
                                <HeaderCell>{t('screen.reports.attendance.form.date')}</HeaderCell>
                                <Cell dataKey="date" />
                            </Column>

                            <Column width={Math.round(window.innerWidth * (10/100))} fixed>
                                <HeaderCell>{t('screen.reports.attendance.form.check_in')}</HeaderCell>
                                
                                <Cell>{rowData => <div >{rowData.check_in}</div>}</Cell>
                            </Column>

                            <Column width={Math.round(window.innerWidth * (10/100))}>
                                <HeaderCell>{t('screen.reports.attendance.form.check_out')}</HeaderCell>
                                
                                <Cell>{rowData => <div style={{backgroundColor:rowData.checkout_bgcolor,height: '100% !important'}}>{rowData.check_out}</div>}</Cell>
                            </Column>
                            {/*
                            <Column width={Math.round(window.innerWidth * (10/100))}>
                                <HeaderCell>{t('screen.reports.attendance.form.total')}</HeaderCell>
                                
                                <Cell>{rowData => <div >{rowData.total}</div>}</Cell>
                            </Column>
                            */}

                            <Column width={Math.round(window.innerWidth * (20/100))}>
                                <HeaderCell>{t('screen.reports.attendance.form.details')}</HeaderCell>
                                <Cell>{rowData => <div>
                                                { rowData.absence === false?
                                                    <div >
                                                        <Icon title={rowData.chin_tooltip} style={{color:rowData.chin_color}} icon='smile-o' size="2x" />
                                                        <span> </span>
                                                        <Icon title={rowData.chin_location} style={{color:rowData.chinloc_color}} icon='map-marker' size="2x" />
                                                        <span>  <span>  &nbsp; </span> </span>
                                                        
                                                        <Icon title={rowData.chout_tooltip} style={{color:rowData.chout_color}} icon='smile-o' size="2x" />
                                                        <span> </span>
                                                        <Icon title={rowData.chout_location} style={{color:rowData.choutloc_color}} icon='map-marker' size="2x" />
                                                        <span>  <span> &nbsp; </span>   </span>
                                                        
                                                        {rowData.delay === true?
                                                            <Icon title="Em empleado tiene retardo" style={{color:'red'}} icon='clock-o' size="2x" />
                                                            :null
                                                        }
                                                    </div>
                                                    :null
                                                }
                                                </div>
                                        }
                                </Cell>
                            </Column>

                            <Column width={Math.round(window.innerWidth * (10/100))}>
                                <HeaderCell>{t('screen.reports.attendance.form.worked')}</HeaderCell>
                                
                                <Cell>{rowData => <div >{rowData.worked}</div>}</Cell>
                            </Column>

                            <Column width={Math.round(window.innerWidth * (10/100))}>
                                <HeaderCell>{t('screen.reports.attendance.form.scheduled')}</HeaderCell>
                                
                                <Cell>{rowData => <div >{rowData.scheduled}</div>}</Cell>
                            </Column>
                            
                            <Column width={Math.round(window.innerWidth * (10/100))}>
                                <HeaderCell>{t('screen.reports.attendance.form.difference')}</HeaderCell>
                                
                                <Cell>{rowData => <span style={{color:rowData.color}}>{rowData.differance}</span>}</Cell>
                            </Column>
                        </Table>
                    </Col> 
                </Grid>
                <div>
                    <ExcelExport
                        data={this.state.list_xls}
                        fileName={t('screen.reports.attendance.'+this.state.filename)}
                        ref={ref => this._exporter = ref}
                    >
                        <ExcelExportColumnGroup
                            title={t('screen.reports.attendance.'+this.state.filename)}
                            headerCellOptions={{textAlign:'center', background:'#FFF', color:'#000'}}
                        >
                            {this.state.columns_xls.map((c, i)=>
                                <ExcelExportColumn key={i}
                                    field={c.field}
                                    title={t('screen.reports.'+c.title)}
                                    headerCellOptions={{
                                        background: '#FFF', color:'#000', bold: true, 
		                				borderBottom:{color:'#aaa8a8', size:'1'},
		                				borderLeft:{color:'#aaa8a8', size:'1'},
		                				borderRight:{color:'#aaa8a8', size:'1'},
		                				borderTop:{color:'#aaa8a8', size:'1'}
                                    }}
                                />
                            )}
                        </ExcelExportColumnGroup>
                    </ExcelExport>
                </div>
            </Layout>
        )
    }
}

export default withTranslation('translations')(Attendance);