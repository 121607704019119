import React, { useEffect, useState, useRef } from 'react';
import {withTranslation} from 'react-i18next';
import { useTranslation } from 'react-i18next';
import {withRouter} from "react-router-dom";
import Layout from '../components/Layout';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import { FlexboxGrid, Col, Grid, Panel, Icon, Divider, Button,  Input, InputGroup, Row } from 'rsuite';
import TableList from '../components/TableList';

import ModalLocationMap from './reports/ModalLocationMap';

import SearchIcon from '@rsuite/icons/Search';
import GridIcon from '@rsuite/icons/Grid';
import ListIcon from '@rsuite/icons/List';
import HistoryIcon from '@rsuite/icons/History';

import { MdOutlinePersonOutline } from "react-icons/md";
import { MdTagFaces } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";

const styles = {
	marginBottom: 10
};

const CustomInput = ({ ...props }) => <Input {...props} style={styles} />;

const CustomInputGroup = ({ placeholder, ...props }) => (
  <InputGroup {...props} style={styles}>
    <Input placeholder={placeholder} />
    <InputGroup.Addon>
      <SearchIcon />
    </InputGroup.Addon>
  </InputGroup>
);

const Home = (props)=>{
	const { t } = useTranslation("translations");
	const [listActivities, setListActivities] = useState([]);
	const [listActivitiesAll, setListActivitiesAll] = useState([]);
	const [cardView, setCardView] = useState(true);
	const modal_location_ref = useRef(null);
	const [checkMaxRaduis, setCheckMaxRaduis] = useState(0);
	const [columns, setColumns] = useState([
		{title:'employee', name:'employee', visible:true, width:'30%'},
		{title:'face', name:'face', visible:true, width:'15%'},
		{title:'location', name:'location', visible:true, width:'15%'},
		{title:'date', name:'date', visible:true, width:'15%'},
		{title:'type', name:'type', visible:true, width:'15%'},
		{title:'evidence', name:'evidence', visible:true, width:'10%'}
	])
	const [list, setList] = useState([]);
	const table_ref = useRef(null);
	const [showModalLocation, setShowModalLocation] = useState(false);
	const [currentLat, setCurrentLat] = useState('');
	const [currentLng, setCurrentLng] = useState('')
	const [currentName, setCurrentName] = useState('');

	const [userType, setUserType] = useState(sessionStorage.getItem('userType'))
	//reports/visit/details/679a64fb7ec4b31b96d13713

	useEffect(() => {
		//let user = sessionStorage.getItem('userType');

		if (userType !== 'contractor') {
			GetAllowedDistance();
		}

			
    }, []);

	const GetAllowedDistance = async() => {
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

		var req = {
            api:'accounts',
            method:'GET',
            url:'accounts/'+accountId,
            requireToken:true,
        }
		props.modal_loader.handleShow()
		let response =  await props.fetchRequest(req);

		if(response !== null&& response !== undefined){
			//console.log(response);
			let dis = response.accountConfig && response.accountConfig.checkMaxRaduis? response.accountConfig.checkMaxRaduis : 0; 
			setCheckMaxRaduis(dis)
			await FetchData(dis);
			props.modal_loader.handleClose();
			const intervalId = setInterval(() => {
				
				if (sessionStorage.getItem('token')) {
					//console.log('interval');
					//console.log(sessionStorage.getItem('token'))
					FetchData(dis);
				}
				
			}, 60000/**5*/);
			
			return () => {
				clearInterval(intervalId);
			};
		}

		props.modal_loader.handleClose();
	}

	const FetchData = async(dis) => {
		var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);
		let allowed = dis? dis : checkMaxRaduis;

		let userType = sessionStorage.getItem('userType')

		var req = {
            api:'reports',
            method:'POST',
            url:'reports/employees-activity/'+accountId,
            requireToken:true,
			obj:{
				locationId: userType === 'client'? sessionStorage.getItem('locationId') : null
			}
        }
		
        let response =  await props.fetchRequest(req);

		if(response !== null&& response !== undefined){
			//var list = [];
			response.map((col, i) => {
				let meters = col.metersFromLocation? col.metersFromLocation : 0;
				col.showLocation = meters <= allowed? false : true;
				col.checkLabel = t('screen.reports.employees_activity.check_type_label_'+col.checkType)
				col.localCheckTime = moment(col.localCheckTime).format('DD/MM/YYYY HH:mm');
				/*list.push([
					col.employeeName,
					col.validFace ? 
                        'icon/MdDone/green/entry/no_action'
                    	: 'icon/MdClose/red/entry/no_action',
					meters <= allowed?
						'icon/'+col.location+'/#7E7E7E/no_location/one/'+
                        col.latitude+'/'+col.longitude
						:'icon/MdOutlineLocationOn/'+'red/start_location/one/' +
                        col.latitude+'/'+col.longitude,
					col.localCheckTime,
					col.checkLabel,
					col.checkType === 'visit-out'?  "icon/MdManageSearch/"+('#2196F3')+"/evidence/"+(col.checkType === 'visit-out'? 'one' : 'no_action') : ''
				])*/
			})
			//console.log(response)
			setListActivities(response);
			setListActivitiesAll(response);
			//setList(list)
			FetchTable(response, dis)
		}

		
	}

	const FetchTable = (response, dis) => {
		let allowed = dis? dis : checkMaxRaduis;
		if(response !== null&& response !== undefined){
			var list = [];
			response.map((col, i) => {
				let meters = col.metersFromLocation? col.metersFromLocation : 0;
				/*col.showLocation = meters <= allowed? false : true;
				col.checkLabel = t('screen.reports.employees_activity.check_type_label_'+col.checkType)
				col.localCheckTime = moment(col.localCheckTime).format('DD/MM/YYYY HH:mm');*/
				list.push([
					col.employeeName,
					col.validFace ? 
                        'icon/MdDone/green/entry/no_action'
                    	: 'icon/MdClose/red/entry/no_action',
					meters <= allowed?
						'icon/'+col.location+'/#7E7E7E/no_location/one/'+
                        col.latitude+'/'+col.longitude
						:'icon/MdOutlineLocationOn/'+'red/start_location/one/' +
                        col.latitude+'/'+col.longitude,
					col.localCheckTime,
					col.checkLabel,
					col.checkType === 'visit-out'?  "icon/MdManageSearch/"+('#2196F3')+"/evidence/"+(col.checkType === 'visit-out'? 'one' : 'no_action') : ''
				])
			})
			//console.log(response)
			//setListActivities(response);
			setList(list)
		}
	}

	const actionIcon = (e, i, d) => {
		//console.log(e)
		console.log(i)
		console.log(d)

		if (d === 'evidence') {
			GoToVisitDetails(listActivities[i]);
		} else {
			OpenModalMap(listActivities[i])
		}
		
	}

	const FilterData = async(filter) => {
		//console.log(filter)
		if (filter && filter !== "") {
			//console.log('filtrar')
			var fils = listActivitiesAll.filter(function(item) {
				var str = filter
				//item.row.findIndex(row => row.text.toString().toLowerCase().includes(target.value.toLowerCase()))
				if (item.employeeName.toString().toLowerCase().includes(filter.toLowerCase()) === true || 
					item.location.toString().toLowerCase().includes(filter.toLowerCase())  === true) {
					
					/*console.log(item.employeeName.toString().toLowerCase().includes(filter.toLowerCase()))
					console.log(item.location.toString().toLowerCase().includes(filter.toLowerCase()))
					console.log('encuentra') */
					return true;

				}else {
					return false
				}
				
			});
		}else {
			//console.log('sin')
			var fils = listActivitiesAll;
		}

		//console.log(fils)
		setListActivities(fils)
		FetchTable(fils, null)
	}

	

	const GoToVisitDetails = (item) => {
		//67ae35c515b44e2e8d2636ed
		let url = "/reports/visit/details/"+item.visitId;
		//console.log(url)
		//props.history.push(url);
		props.history.push({                
			pathname: url,
			state: { 
				origin: 'home'
			}
		});
	}

	const OpenModalMap = (item) => {
		//console.log(modal_location_ref);
		setCurrentLat(item.latitude);
		setCurrentLng(item.longitude);
		setCurrentName(item.employeeName)
		setShowModalLocation(true);
		//modal_location_ref.current.handleShow(item.latitude, item.longitude, item.name)
	}

	const CloseModalMap = () => {
		console.log('cerrar')
		setCurrentLat('');
		setCurrentLng('');
		setCurrentName('')
		setShowModalLocation(false);
	}

	return (
		<Layout {...props}>
			{userType !== 'contractor' &&
			<>
			<h3 className="s-title-page">{t('screen.reports.employees_activity.title')}</h3>
			
			<Grid fluid style={{marginBottom:'16px'}}>
				<Col xs={18}>
					<span>{t('screen.reports.employees_activity.last_hours')}</span>
					<HistoryIcon style={{marginLeft:'10px'}} className='iconHome' 
						onClick={()=>{
							console.log('reload')
							FetchData()

						}}
					/>
				</Col>
				<Col xs={6} style={{textAlign:'right'}}>
					
						<ListIcon style={{color:!cardView? 'rgb(0,72,130)':''}} className='iconHome'
							onClick={() =>{
								setCardView(false);
							}}
						/>
						<GridIcon  style={{color:cardView? 'rgb(0,72,130)' :''}} className='iconHome'
							onClick={() =>{
								setCardView(true);
							}}
						/>
					
					
				</Col>
			</Grid>
			<Grid fluid>
				<Col xs={5}>
					<CustomInputGroup size="md" placeholder={t('general.table.search')} 
						onChange= {(e) => {
							console.log(e.target.value);
							FilterData(e.target.value)
						}}
					/>
				</Col>

			</Grid>
			<Grid fluid>
				{cardView === true?
					<>
					<ul className='cards'>
					{listActivities.map((item, i) => {
						return (
							<>
								<Panel className='cards_item' >
									<Panel shaded bordered  className="panel-report" style={{padding:'10px', height:'100%'}}>
										<Grid fluid style={{textAlign:'center'}}>
											<Col xs={20}>
												<img src={item.urlCheckFace} height="100" />
											</Col>
											<Col xs={4}>
												{item.validFace === true?
													<MdTagFaces className='iconCheck' style={{color:'green'}}/>
													:
													<MdTagFaces className='iconCheck' style={{color:'red'}}/> 
												}
												
											</Col>
										</Grid>
										<Grid fluid style={{textAlign:'left'}}>
											<span style={{fontWeight:'bold',color:'rgb(0,72,130)'}}>{item.employeeName}</span>
										</Grid>
										<Grid fluid>
											<Col xs={20} style={{textAlign:'left'}}>
												<span >{item.checkLabel}</span>
												{!item.showLocation ?
													<>
														<br></br>
														<span className='labelLocation'
															onClick={()=>{
																OpenModalMap(item)
															}}
														>
															{item.location}
														</span>
													</>
													:
													<>
														<br></br>
														<MdOutlineLocationOn color='red' className='iconCheck'
															style={{cursor:'pointer'}}
															onClick={()=>{
																OpenModalMap(item)
															}}
														/>
													</>
													
												}	
											</Col>
											<Col xs={4}>
												{item.checkType === 'visit-out'?
													<MdManageSearch className='iconHome'
														onClick={()=>{
															GoToVisitDetails(item)
														}}
													/>:null
												}
											</Col>
										</Grid>
										<Grid fluid style={{textAlign:'right'}}>
											<span >{item.localCheckTime}</span>
										</Grid>
									</Panel>
								</Panel>
							</>
						)
					})}
					</ul>
					</>
					:
					<>
						<Col xs={24}>
                            <TableList 
                                //{...this.props} 
								t={t}
                                onRef={ref => table_ref} 
                                columns={columns}
                                btnNew={false}
                                data={list}
                                actions={[]}
                                actionIcon={actionIcon}
                                //updateColumns={updateColumns}
                                screen={"employees_activity"}
                                searching={false}
                                report={true}
                                table_title={[]}
                                //filename={t('screen.reports.'+filename)}
                                scroll={false}
                                widths={['25%','15%','10%','15%','10%','10%','15%']}
                                //search={this.search}
                                //downloadEvidences={downloadEvidences}
								
                            />
                        </Col>
					</>
				}
			</Grid>
			</>}

			{showModalLocation &&
				<ModalLocationMap 
					open={showModalLocation}
					close={CloseModalMap}
					lat={currentLat}
					lng={currentLng}
					name={currentName}
					ref={modal_location_ref}
				/>
			}
				
			
		</Layout>
	)
}

export default withTranslation('translations') (Home);