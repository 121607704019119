import React, {Component} from 'react';
import {FlexboxGrid, Col, Alert, Button} from 'rsuite';
import {withTranslation} from 'react-i18next';
import moment from 'moment';

import Layout from '../../components/Layout';

import General from '../../components/settings/account/General';
import CheckValidation from '../../components/settings/account/CheckValidation';
import Payment from '../..//components/settings/account/Payment';
import CheckerRules from '../../components/settings/account/CheckerRules';
import IncidentRules from '../../components/settings/account/IncidentRules';
import EmployFieldSettings from '../../components/settings/account/EmployFieldSettings';
import Unsuscribe from '../../components/settings/account/Unsuscribe';
import TaxData from '../../components/settings/account/TaxData';
import SubscriptionData from '../../components/settings/account/SubscriptionData';
import Billing from "../../components/settings/account/Billing";
import { isValidForm, swalAction } from '../../lib/functions';
import Special from '../../components/settings/account/Special';
import Invoice from '../../components/settings/account/Invoice';
import Roles from '../../components/settings/account/Roles';
import AccountUsers from '../../components/settings/account/AccountUsers';
import AccountContractors from '../../components/settings/account/AccountContractors';

class Account extends Component{
    constructor(props){
        super(props);

        this.dataUpdate = this.dataUpdate.bind(this);
        this.getGeneral = this.getGeneral.bind(this);
        this.getCheckValidation = this.getCheckValidation.bind(this);
        this.getCheckerRules = this.getCheckerRules.bind(this);
        this.getIncidentRules = this.getIncidentRules.bind(this);
        this.getSystemExchange = this.getSystemExchange.bind(this);
        this.getEmployFields = this.getEmployFields.bind(this);
        this.getTaxData = this.getTaxData.bind(this);
        this.getPayment = this.getPayment.bind(this);
        this.cancel = this.cancel.bind(this);
        this.update = this.update.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.getSubscriptionData = this.getSubscriptionData.bind(this);
        this.getSpecial = this.getSpecial.bind(this);
        this.confirmCancelSubscription = this.confirmCancelSubscription.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
        this.getMethods = this.getMethods.bind(this);
        this.deleteMethod = this.deleteMethod.bind(this);
        this.changeDefaultMethod = this.changeDefaultMethod.bind(this);

        this.state = {
            id:"",
            show_form: false,
            method_schema:'',
            schema_id:"",
            subscription:{},
            days:['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
            methods:[]
        }
    }
    
    compare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
    }

    async componentDidMount(){   
        await this.getSubscriptionData();
        await this.getMethods();
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);
        
        let req = {
            api:'accounts',
            method:'GET',
            url:'accounts/'+accountId,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading'),
            requireToken:true
        };

        let request = await this.props.fetchRequest(req);
        if(request === undefined){
            this.props.closeSesion();
            return;
        }
        
        this.setState({id:request.id});

        req = {
            api:'accounts',
            method:'GET',
            url:'schemas/by-account-id/'+accountId,
            requireToken:true
        };
        let scheme = await this.props.fetchRequest(req);
        
        this.setState({method_schema: scheme === undefined ? 'POST' : 'PUT', schema: scheme});

        if(scheme !== undefined){
            var schemas = [];
            var required = [];
            scheme.forEach((s)=>{
                var last = Object.keys(s.schema)[Object.keys(s.schema).length-1];
                
                required = s.schema[last].required;

                for(var name in s.schema[last].properties){
                    var index = schemas.findIndex(item => item.name === name);
                    var index_req = required.findIndex(item => item === name);
                    if(index === -1){
                        schemas.push({
                            schema:s.schema[last].properties[name],
                            name: name,
                            active: true, //scheme[0].schema[last].properties[name].active   
                            required: index_req !== -1
                        });

                    }                    
                }
            });

            this.employ_field.setState({
                schemas: schemas,
            });
            
        }
        
        this.setState({id: request.id, account: request});
        
        this.general.setState({
            company_name: request.companyName,
            industry: request.industry,
            industryOther: request.attributes !== null? request.attributes.industryOther:null,
            country: request.country,
            companySize: request.companySize,
            time_zone: request.timeZone,
            language: request.language,
            update:false
        });
    
        this.check_validation.setState({
            enforceFacialRecognition: request.accountConfig.enforceFacialRecognition,
            enforceGeolocation : request.accountConfig.enforceGeolocation
        });

        this.payment.setState({
            payments: request.cutsPayroll !== undefined ? request.cutsPayroll : []
        });

        var schedules = [];
        request.schedules.map((s)=>{
            var item = {
	            name:s.name,
	            label:s.label,
	            useBreakTime:s.useBreakTime,
                activeWeekend:s.activeWeekend !== undefined ? s.activeWeekend : false,
                nightShift:s.nightShift !== undefined ? s.nightShift : false,
                minimumHours:s.minimumHours,
                type: s.type,
                days:[]
            };


            if(item.type === 'flexible'){
                item.days.push({
                    startTime: s.startTimeDay !== null ? moment(s.startTimeDay).format('HH:mm') : "",
                    closingTime: s.closingTimeDay !== null ? moment(s.closingTimeDay).format('HH:mm') : "",
                    breakTime: s.breakInTimeDay !== null ? moment(s.breakInTimeDay).format('HH:mm') : "",
                    breakReturnTime: s.breakOutTimeDay !== null ? moment(s.breakOutTimeDay).format('HH:mm') : "",
                    
                    error_start: "",
                    error_close: "",
                    error_break: "",
                    error_return: "",
                    
                    check: true,
                
                });

            }else if(item.type === 'traditional'){
                this.state.days.map((day)=>{
                    var index = s.days.findIndex(obj => obj.name === day);
                    var obj = {}
                    if(index !== -1){
                        obj = {
                            name: s.days[index].name,
                            startTime: s.days[index].startTime !== null ? moment(s.days[index].startTime).format('HH:mm') : "",
                            closingTime: s.days[index].closingTime !== null ? moment(s.days[index].closingTime).format('HH:mm') : "",
                            breakTime: s.days[index].breakTime !== null ? moment(s.days[index].breakTime).format('HH:mm') : "",
                            breakReturnTime: s.days[index].breakReturnTime !== null ? moment(s.days[index].breakReturnTime).format('HH:mm') : "",
                        
                            error_start: "",
                            error_close: "",
                            error_break: "",
                            error_return: "",
                            
                            check: s.days[index].startTime === null ? false : true,
                        };
                    }else{
                        obj = {
                            name: day,
                            startTime:"",
                            closingTime:"",
                            breakTime:"",
                            breakReturnTime:"",
                            error_start: "",
                            error_close: "",
                            error_break: "",
                            error_return: "",
                            check: false
                        };
                    }

                    return item.days.push(obj);
                });


            }else if(item.type ==='24x24'){
                item.days.push({
                    startTime: s.startTimeDay !== null ? moment(s.startTimeDay).format('HH:mm') : "",
                    closingTime: s.closingTimeDay !== null ? moment(s.closingTimeDay).format('HH:mm') : "",
                    breakTime: s.breakInTimeDay !== null ? moment(s.breakInTimeDay).format('HH:mm') : "",
                    breakReturnTime: s.breakOutTimeDay !== null ? moment(s.breakOutTimeDay).format('HH:mm') : "",
                    
                    error_start: "",
                    error_close: "",
                    error_break: "",
                    error_return: "",
                    
                    check: true,
                
                });
            }

            schedules.push(item);
            return s;
        });

        this.checker_rules.setState({
            schedules: schedules,
            checkOffsetHours: request.accountConfig.checkOffsetHours !== null ?
                                request.accountConfig.checkOffsetHours
                            : "",
            breakOutOffsetMinutes: request.accountConfig.breakOutOffsetMinutes !== null ?
                                        request.accountConfig.breakOutOffsetMinutes
                                    : "",
            show_form: true,
            rule: schedules.length > 0 ? schedules[0].label : "",
            index: schedules.length > 0 ? 0 : -1
        });

        var incidents = [];
        request.accountConfig.incidentRules.map((inc)=>{
            return incidents.push({
                name:inc.name,
                label:inc.name.toLowerCase().replace(' ', ''),
                supportDelays:inc.enableDelays ? 'Si' : 'No',
                minutesDelays: inc.allowedDelayMinutes,
                numDelays: inc.delaysToAbsence,
                supportExtraHr: inc.enableOvertime ? 'Si' : 'No',
                maxExtraHr: inc.overtimeMax,
                supportEconomicDay: inc.enableEconomicDays === 0 ? 'No' : 'Si',
                maxEconomicDay: inc.economicDaysMax
            });
        });

        this.incident_rules.setState({
            incidents: incidents,
            rule: incidents.length > 0 ? incidents[0].label : "",
            show_form: incidents.length > 0 ? true : false
        });

        /*this.system_exchange.setState({
            aspel: request.accountConfig.supportAspel,
            contpaq: request.accountConfig.supportContpaq,
            no_interface: !request.accountConfig.supportAspel && !request.accountConfig.supportContpaq ? true : false
        });*/

        if(request.attributes.taxData !== undefined){
            this.tax_data.setState({
                enable: request.attributes.taxData.enable,
                business_name: request.attributes.taxData.business_name,
                rfc: request.attributes.taxData.rfc,
                address: request.attributes.taxData.address,
            });
        }

        this.special.setState({
            checkAheadOffsetHours: request.accountConfig.checkAheadOffsetHours !== null ? request.accountConfig.checkAheadOffsetHours : "",
            checkBackOffsetHours: request.accountConfig.checkBackOffsetHours !== null ? request.accountConfig.checkBackOffsetHours : "",
            breakOutOffsetMinutes: request.accountConfig.breakOutOffsetMinutes !== null ? request.accountConfig.breakOutOffsetMinutes : "",
            checkMaxRaduis: request.accountConfig.checkMaxRaduis !== null ? request.accountConfig.checkMaxRaduis : "",
        });
        
    }

    async getMethods(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var response = await this.props.fetchRequest({
            api:'payments',
            url:'payment-methods/by-account-id/'+accountId,
            requireToken:true
        });

        if(response !== undefined){
            this.setState({methods: response});
        }
    }
    
    async deleteMethod(id){
        var response = await this.props.fetchRequest({
            method: 'DELETE',
            api: 'payments',
            url: 'payment-methods/'+id,
            requireToken: true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading')
        });

        if(response !== undefined){
            this.setState({methods: response});
        }
    }

    async changeDefaultMethod(id){
        var response = await this.props.fetchRequest({
            method: 'POST',
            api: 'payments',
            url: 'payment-methods/change-default-payment/'+id,
            requireToken: true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading')
        });

        if(response !== undefined){
            this.setState({methods: response});
        }
    }

    async getSubscriptionData(){
        let req = {
            api:'payments',
            method:'GET',
            url:'subscription',
            requireToken:true
        };
        var response = await this.props.fetchRequest(req);
        
        if(response !== undefined){
            this.setState({subscription: response});
        }
    }

    getGeneral(modal){
        var obj = this.state.account;

        if(obj.attributes === null){
            obj.attributes = {};
        }
        
        obj.companyName             = this.general.state.company_name;
        obj.industry                 = this.general.state.industry;
        obj.country                  = this.general.state.country;
        obj.attributes.industryOther = this.general.state.other;
        obj.companySize              = this.general.state.companySize;
        obj.timeZone                 = this.general.state.time_zone.split(' ')[0];
        obj.attributes.industryOther = this.general.state.industryOther;
        obj.language                = this.general.state.language;

        if(isValidForm('div.general-account')){
            
            this.dataUpdate(obj, modal);

            sessionStorage.setItem(btoa("lang"), btoa(this.general.state.language));

            this.general.setState({is_saved: true, active_save: false, disabled:true});
        }else{
            Alert.error(this.props.t('error_alert.incomplete_fields'));
        }

        
    }

    getCheckValidation(modal){
        var obj = this.state.account;
        obj.accountConfig.enforceFacialRecognition = this.check_validation.state.enforceFacialRecognition;
        obj.accountConfig.enforceGeolocation       = this.check_validation.state.enforceGeolocation;

        if(isValidForm('form.checker-validation')){
            this.dataUpdate(obj, modal);

            this.check_validation.setState({is_saved: true, active_save: false, disabled:true});
        }else{
            Alert.error(this.props.t('error_alert.incomplete_fields'));
        }
    }

    getPayment(modal){
        var obj = this.state.account;

        if(this.payment.state.payments !== null){
            if(this.payment.state.payments.length > 0){
                obj.cutsPayroll = this.payment.state.payments;
            }else{
                delete obj.cutsPayroll;
            }
        

            var weekly = this.payment.state.payments.findIndex(obj => obj.name === 'weekly');
            var biweekly = this.payment.state.payments.findIndex(obj => obj.name === 'biweekly');
            var monthly = this.payment.state.payments.findIndex(obj => obj.name === 'monthly');
        

            obj.accountConfig.payrollWeekly = weekly !== -1;
            obj.accountConfig.payrollBieeekly = biweekly !== -1;
            obj.accountConfig.payrollMounthly = monthly !== -1;

            this.dataUpdate(obj, modal);

            this.payment.setState({is_saved: true, active_save: false,disabled:true});
        }
    }

    getCheckerRules(modal){
        var f = new Date();
        var month = f.getMonth() + 1;
        var day = f.getDate();
        
        var hoy = f.getFullYear() + "-" + (month < 10 ? '0'+month : month) + "-" + (day < 10 ? '0'+day : day);

        var obj = this.state.account;
        
        var schedules = [];
        var error = '';
        this.checker_rules.state.schedules.map((s)=>{
            var item = {
                name: s.name,
                label: s.label,
                useBreakTime: s.useBreakTime,
                activeWeekend: s.activeWeekend,                
                nightShift: s.nightShift,
                workingHour: s.workingHour,
                breakTime: s.breakTime,
                minimumHours: s.minimumHours,
                type: s.type,
                days: []
            };

            if(s.name === ''){
                error = this.props.t('error_alert.title_checker_rules');
            }
            
            s.days.map((d, i) =>{
                var obj = {
                    name: d.name,
                    key: d.name,    
                };

                switch(s.type){
                    case 'traditional':
                        if(d.check){
                            obj.startTime = d.startTime !== "" ? moment(hoy + ' ' + d.startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            obj.closingTime = d.closingTime !== "" ? moment(hoy + ' ' + d.closingTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            obj.breakTime = d.breakTime !== "" ? moment(hoy + ' ' + d.breakTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            obj.breakReturnTime = d.breakReturnTime !== "" ? moment(hoy + ' ' + d.breakReturnTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        }
                        break;
                    case 'flexible':
                        if(i === 0){
                            item.startTimeDay = d.startTime !== "" ? moment(hoy + ' ' + s.days[0].startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            item.closingTimeDay = d.closingTime !== "" ? moment(hoy + ' ' + s.days[0].closingTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            item.breakInTimeDay = d.breakTime !== "" ? moment(hoy + ' ' + s.days[0].breakTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                            item.breakOutTimeDay = d.breakReturnTime !== "" ? moment(hoy + ' ' + s.days[0].breakReturnTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        } 
                        break;
                    case '24x24':
                        item.startTimeDay = d.startTime !== "" ? moment(hoy + ' ' + s.days[0].startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        //obj.startTime = item.startTimeDay;
                        break;
                    default:
                        break;
                }
                
                if(item.type === 'traditional' && obj.startTime !== undefined){
                    item.days.push(obj);
                }                

                return item;
            });

            
            
            schedules.push(item);

            return schedules;
        });

        obj.schedules = schedules;
        
        if(error === ''){
            if(isValidForm('div.checker-rules')){
                this.dataUpdate(obj, modal);

                this.checker_rules.setState({is_saved: true, active_save: false, disabled:true});
            }else{
                Alert.error(this.props.t('error_alert.incomplete_fields'));
            }
        }else{            
            Alert.error(error);
        }
        
    }

    getIncidentRules(modal){
        var obj = this.state.account;
        var incidents = [];
        this.incident_rules.state.incidents.map((i)=>{
            
            return incidents.push({
                name: i.name,
                enableDelays: i.supportDelays === 'No' ? false : true,
                delaysToAbsence: i.numDelays,
                allowedDelayMinutes: i.minutesDelays,
                enableOvertime: i.supportExtraHr === 'No' ? false : true,
                overtimeMax: i.maxExtraHr,
                enableEconomicDays: i.supportEconomicDay === 'No' ? 0 : 1,
                economicDaysMax: i.maxEconomicDay
            });
        });

        obj.accountConfig.incidentRules = incidents;

        this.dataUpdate(obj, modal);

        this.incident_rules.setState({is_saved: true, active_save: false, disabled:true});

    }

    async getEmployFields(modal){
        var obj =  {}

        if(this.state.method_schema === 'POST'){
            obj = {
                accountId: this.state.id,
                schema:{},
                schemaUI:{}
            }
        }else{
            obj = this.state.schema[0];
        }

        var last = Object.keys(obj.schema)[Object.keys(obj.schema).length-1];
        var name = '';
        
        if(last === undefined){
            name = 'schema_1';
        }else{
            name = last;
        }  
        
        obj.schema[name] = {
            type: 'object',
            required: this.employ_field.state.required,
            properties:{}
        }

        this.employ_field.state.schemas.map((s, i)=>{
            obj.schema[name].properties[s.name] = s.schema;
            obj.schema[name].properties[s.name].active = s.active;

            if(s.required){
                var index = obj.schema[name].required.findIndex(item => item === name);
                if(index === -1){
                    obj.schema[name].required.push(s.name);
                }
                
            }
            return obj;
        });
        var account_id  = atob(sessionStorage.getItem(btoa('account_id')));
        account_id = account_id.substring(1, account_id.length - 1);

        let req = {
            api:'accounts',
            method: this.state.method_schema,
            url:'schemas' + (this.state.method_schema === 'POST' ? '/by-account/'+account_id : '/'+obj.id),
            obj:obj,
            modal:modal === null ? modal : this.props.modal_loader,
            message_modal:this.props.t('modal.spin.saving'),
            requireToken:true
        }
        await this.props.fetchRequest(req);

        this.employ_field.setState({is_saved: true, active_save: false, disabled:true});
    }

    getSystemExchange(modal){
        if(this.system_exchange !== undefined){
            var obj = this.state.account;
            obj.accountConfig.supportAspel =  this.system_exchange.state.aspel;
            obj.accountConfig.supportContpaq = this.system_exchange.state.contpaq;

            this.dataUpdate(obj, modal);

            this.system_exchange.setState({is_saved: true, active_save: false, disabled:true});
        }
    }

    getTaxData(modal){
        var obj = this.state.account;
        
        obj.attributes['taxData'] = {
            enable: this.tax_data.state.enable,
            business_name: this.tax_data.state.business_name,
            rfc: this.tax_data.state.rfc,
            address: this.tax_data.state.address,
        };

        this.dataUpdate(obj, modal);

        this.tax_data.setState({is_saved: true, active_save: false, disabled:true});
    }

    getSpecial(){
        var obj = this.state.account;

        obj.accountConfig.checkAheadOffsetHours = this.special.state.checkAheadOffsetHours;
        obj.accountConfig.checkBackOffsetHours = this.special.state.checkBackOffsetHours;
        obj.accountConfig.breakOutOffsetMinutes = this.special.state.breakOutOffsetMinutes;
        obj.accountConfig.checkMaxRaduis = this.special.state.checkMaxRaduis;

        this.dataUpdate(obj);

        this.special.setState({is_saved: true, active_save: false, disabled:true});
    }

    async dataUpdate(obj, modal = this.props.modal_loader, message = this.props.t('modal.spin.saving')){
        let req = {
            api:'accounts',
            method:'PUT',
            url:'accounts/by-account/'+this.state.id,
            obj:obj,
            modal: modal,
            message_modal: message,
            requireToken:true
        }
        await this.props.fetchRequest(req);
        
    }

    onCancel(){
        this.props.history.push('/');
    }

    cancel(){
        var by_update = false;

        if(this.general.state.active_save){
            by_update = true;
        }

        if(this.check_validation.state.active_save){
            by_update = true;
        }

        if(this.payment.state.active_save){
            by_update = true;
        }

        if(this.checker_rules.state.active_save){
            by_update = true;
        }

        if(this.incident_rules.state.active_save){
            by_update = true;
        }

        if(this.employ_field.state.active_save){
            by_update = true;
        }

        if(this.system_exchange !== undefined){
            if(this.system_exchange.state.active_save){
                by_update = true;
            }
        }

        if(this.tax_data.state.active_save){
            by_update = true;
        }

        if(!by_update){
            this.props.history.push('/');
        }else{
            var obj = {
                title           : this.props.t('alert.titles.alert'),
                text            : this.props.t('alert.messages.cancel_update_employee'),
                icon            : 'warning',
                showConfirm     : true,
                showCancel      : true,
                textConfirm     : this.props.t('alert.confirms.exit'),
                colorConfirm    : '#3085d6',
                colorCancel     : '#d33',
                textcancel      : this.props.t('alert.confirms.cancel'),
                values          : '',
                fn              : this.onCancel
            }
            
            swalAction(obj); 
        }

        
    }

    update(){
        if(isValidForm('div.general-account')){
            this.getGeneral(null);
            this.getCheckValidation(null);
            this.getPayment(null);
            this.getCheckerRules(null);
            this.getIncidentRules(null);
            this.getEmployFields(null);
            this.getSystemExchange(null);
            this.getTaxData(null);
            this.getSpecial();

        }else{
            Alert.error(this.props.t('error_alert.incomplete_fields'));
        }
    }

    confirmCancelSubscription(){
        var obj = {
            title           : this.props.t('modal.alert.titles.unsuscribe'),
            text            : this.props.t('modal.alert.messages.remove_subscription'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.yes'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.no'),
            values          : '',
            fn              : ()=>{this.cancelSubscription()}
        }
        
        swalAction(obj);  
    }

    async cancelSubscription(){
        let req = {
            api:'payments',
            method:'POST',
            url:'subscription/cancel',
            requireToken:true
        };
        let response =  await this.props.fetchRequest(req);
        if(response !== null&& response !== undefined){
            sessionStorage.setItem("suscription", response.subscriptionType);
            window.location.reload();
        }
    }

    render(){
        const {t} = this.props;
        const suscription = sessionStorage.getItem("suscription");
        return(
            <Layout {...this.props}>
                <h3 className="s-title-page">{t('screen.settings.account.title')}</h3>
            <fieldset>
                <FlexboxGrid justify="center">
                    <Col xs={24} sm={20} md={20} className="s-content">
                        <legend></legend>
                        <General {...this.props} onRef={ref => this.general = ref} 
                            dataUpdate={this.getGeneral} />
                        <br/>
                        <CheckValidation {...this.props} onRef={ref => this.check_validation = ref} 
                            dataUpdate={this.getCheckValidation} />
                        <br/>
                        <Payment {...this.props} onRef={ref => this.payment = ref}
                            dataUpdate={this.getPayment}
                        />
                        <br/>
                        
                        <CheckerRules {...this.props} onRef={ref => this.checker_rules = ref} 
                            dataUpdate={this.getCheckerRules} />
                        <br/>
                        <IncidentRules {...this.props} onRef={ref => this.incident_rules = ref} 
                            dataUpdate={this.getIncidentRules} />
                        <br/>
                        <EmployFieldSettings {...this.props} onRef={ref => this.employ_field = ref}
                            dataUpdate={this.getEmployFields} />
                        <br/>
                        {/*<SystemExchangeFields {...this.props} onRef={ref => this.system_exchange = ref}
                            dataUpdate={this.getSystemExchange}
                        />
                        <br/>*/}
                        <TaxData {...this.props} onRef={ref => this.tax_data = ref} 
                            dataUpdate={this.getTaxData} />
                        <br/>

                        <Special {...this.props} onRef={ref => this.special = ref} suscription={suscription}
                            dataUpdate={this.getSpecial}
                        />
                        <br/>

                        {suscription !== 'free' &&
                            <div style={{padding:"0px"}}>
                                <SubscriptionData {...this.props} 
                                    getMethods={this.getMethods}
                                    deleteMethod={this.deleteMethod}
                                    changeDefaultMethod={this.changeDefaultMethod}
                                    onRef={ref => this.subscription_data = ref} 
                                    subscriptionData={this.state.subscription} 
                                    suscription={suscription} 
                                    methods={this.state.methods} 
                                />
                                <br/>
                            </div>
                        }

                        {suscription !== 'free' &&
                            <Billing {...this.props} onRef={ref => this.billing = ref}/>
                        }
                        <br/>
                        {suscription !== 'free' &&
                            <Invoice {...this.props} onRef={ref => this.Invoice = ref}/>
                        }
                        <br/>
                            <Roles {...this.props} onRef={ref => this.Roles = ref}/>
                        <br/>
                        <br/>
                            <AccountContractors {...this.props} onRef={ref => this.AccountUsers = ref}/>
                        <br/>
                        <br/>
                            <AccountUsers {...this.props} onRef={ref => this.AccountUsers = ref}/>
                        <br/>
                        {suscription !== 'free' &&
                            <Unsuscribe {...this.props} confirmCancelSubscription={this.confirmCancelSubscription} />
                        }

                        <div style={{marginTop:10, marginBottom:10, textAlign:'right'}}>
                            <Button onClick={this.cancel}>{t('button.cancel')}</Button>
                            <Button onClick={this.update} style={{marginLeft:10}} className="s-btn-primary">{t('button.update')}</Button>
                        </div>
                    </Col>
                </FlexboxGrid>
            </fieldset>
        </Layout>
        
        )
    }
}

export default withTranslation('translations')(Account);