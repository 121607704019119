import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import { Grid, Col, ControlLabel, AutoComplete, Button } from 'rsuite';

import Layout from '../../components/Layout';
import TableList from '../../components/TableList';
import Swal from 'sweetalert2';
import $ from 'jquery';
import ModalMap from './ModalMap';


class Entries extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.updateColumns = this.updateColumns.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.search = this.search.bind(this);

        this.state = {
            start_date:moment().startOf('month').format('YYYY-MM-DD'),
            end_date:moment().format('YYYY-MM-DD'),
            employee:this.props.t('screen.reports.all'),
            employees:[],
            location:"",
            locations:[],
            data:[],
            filename:'name',
            columns:[
                {title:'employee', name:'employee', visible:true, width:'20%'},
                {title:'date', name:'date', visible:true, width:'15%'},
                {title:'face', name:'face', visible:true, width:'5%'},
                {title:'location', name:'location', visible:true, width:'20%'},
                {title:'date', name:'date', visible:true, width:'15%'},
                {title:'face', name:'face', visible:true, width:'5%'},
                {title:'location', name:'location', visible:true, width:'20%'}
            ],
            list:[],
            userType:'',
        }
    }

    async componentDidMount(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            url:'employees/by-account/'+accountId,
            method:'GET',
            requireToken:true
        }
        var employees = await this.props.fetchRequest(req);
        var data = [this.props.t('screen.reports.all')];
        if(employees !== undefined){
            employees.map((e)=>(
                data.push(e.firstName+' '+e.lastName+' '+(e.maternalSurname === null ? '' : e.maternalSurname))
            ));
            
            this.setState({employees: employees, data: data});
        }
        //console.log(employees)
        
        var locations = await this.props.fetchRequest({
            api:'accounts',
            method:'get',
            url:'locations/by-account/'+accountId,
            obj:null,
            requireToken:true
        });

        if(locations !== undefined){
            this.setState({locations: locations});
        }
        //console.log(locations)
        let user = sessionStorage.getItem('userType');
        //console.log(user)
        if (user === 'client') {
            //console.log(sessionStorage.getItem('locationId'))
            locations.map((l,i) => {
                if (l.id === sessionStorage.getItem('locationId')) {
                    //console.log(l)
                    //console.log('match')
                    this.setState({
                        location: l.name,
                        userType:'client'
                    });
                }
            })
            
        }else {
            this.setState({
                location: 'all'
            });
        }

        this.table.setTable();
        await this.search();
    }

    componentDidUpdate(){
        var data = this.state.data;
        if(data.length > 0){
            if(data[0] !== this.props.t('screen.reports.all')){
                data[0] = this.props.t('screen.reports.all');

                this.setState({data: data, employee: data[0]});
            }            
        }        
    }

    handleChange(e){
        console.log(e.target.value)
        this.setState({[e.target.name]: e.target.value});
    }

    async search(){
        $('.rs-btn-default').blur();
        var id = '';
        var error = "";

        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);
        
        if(this.state.employee === this.props.t('screen.reports.all')){
            id = 'all';
        }else if(this.state.employee === ""){
            error = this.props.t('error_alert.no_employee');
        }else{
            var index = this.state.employees.findIndex(obj => 
                obj.firstName+' '+obj.lastName+' '+obj.maternalSurname === this.state.employee
            );

            id = this.state.employees[index].id;
        }

        var obj = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            employeeId: id,
            locationName: this.state.location
        };

        var req = {
            api: 'reports',
            url: 'reports/time-check-in-by-account/'+accountId,
            obj: obj,
            method: 'POST',
            modal: this.props.modal_loader,
            message_modal: this.props.t('modal.spin.searching'),
            requireToken: true
        };

        if(error === ""){
            var request = await this.props.fetchRequest(req);
            var list = [];
            
            if(request === undefined){
                request = [];
            }
            
            request.map(async(r, i) =>{
                var obj = [r.employee.name];

                var entry = await r.checks.findIndex(item => 
                    item.checkType === 'in'
                );

                var out = await r.checks.findIndex(item => 
                    item.checkType === 'out'
                );
                //'icon/'+col.location+'/#7E7E7E/no_location/one/'+col.latitude+'/'+col.longitude
                //let noLoc = r.checks[entry].metersFromLocation.toFixed(0)+' mts. de '+r.checks[entry].location;
                //console.log(noLoc)
                obj.push(
                    entry !== -1 ? moment(r.checks[entry].timeFormatted).format('DD/MM/YYYY HH:mm') : "",
                    entry !== -1 ? r.checks[entry].validFace ? 
                        'icon/check/green/entry/no_action'
                    : 'icon/close/red/entry/no_action' : "",
                    entry !== -1 ? 
                        r.checks[entry].metersFromLocation !== null ? 
                            r.checks[entry].location !== "" ?
                               'icon/'+ r.checks[entry].metersFromLocation.toFixed(0)+' mts. de '+r.checks[entry].location +'/#7E7E7E/no_location/one/'+r.checks[entry].latitude+'/'+r.checks[entry].longitude
                            : 'icon/map-marker/'+(r.checks[entry].latitude !== null && r.checks[entry].longitude !== null ? 
                                'green/entry/one/' : '#7E7E7E/no_location/no_action/')+
                                r.checks[entry].latitude+'/'+r.checks[entry].longitude
                            
                        : ""
                    : "",
                    out !== -1 ? moment(r.checks[out].timeFormatted).format('DD/MM/YYYY HH:mm') : "",
                    out !== -1 ? r.checks[out].validFace ? 
                        'icon/check/green/out/no_action'
                    : 'icon/close/red/out/no_action' : "",
                    out !== -1 ? 
                        r.checks[out].metersFromLocation !== null ? 
                            r.checks[out].location !== "" ?
                               'icon/'+ r.checks[out].metersFromLocation.toFixed(0)+' mts. de '+r.checks[out].location +'/#7E7E7E/no_location/one/'+r.checks[out].latitude+'/'+r.checks[out].longitude
                            : 'icon/map-marker/'+(r.checks[out].latitude !== null && r.checks[out].longitude !== null ? 
                                'green/out/one/' : '#7E7E7E/no_location/no_action/')+
                                r.checks[out].latitude+'/'+r.checks[out].longitude
                        : ""
                    : ""
                )

                return list.push(obj)
            });

            setTimeout(()=>{
                this.props.modal_loader.handleShow(this.props.t('modal.spin.loading'));
                this.table.resetTable();
            }, 500);

            setTimeout(()=>{
                this.props.modal_loader.handleClose();
                this.setState({list: list});
                this.table.setTable();

            }, 1000);
        }else{
            Swal.fire('Error', error, 'error');
        }
            

    }

    updateColumns(e, i){
        var columns = this.state.columns;

        columns[i].visible = e.target.checked;

        this.setState({columns: columns});
    }

    getLocation(e, i, d){
        //console.log('location')
        var list = this.state.list[i];
        //console.log(list)
        //console.log(d)
        var index = list.findIndex(obj => obj.indexOf('map-marker/green/'+d) !== -1 );

        if(index !== -1){
            var name = list[0];
            var lat = parseFloat(list[index].split('/')[5]);
            var lng = parseFloat(list[index].split('/')[6]);

            this.modal_map.handleShow(lat, lng, name)
        }else {
            var name = list[0];
            var lat = parseFloat(list[3].split('/')[5]);
            var lng = parseFloat(list[3].split('/')[6]);

            /*console.log(name)
            console.log(lat)
            console.log(lat)*/
            this.modal_map.handleShow(lat, lng, name)
        }
        
    }

    render(){
        const {t} = this.props;
        return (
            <Layout {...this.props}>
                <Grid fluid>
                    <Col xs={24} style={{textAlign:'center'}}>
                        <h3 className="s-title-page">{t('screen.reports.entries.'+this.state.filename)}</h3>
                    </Col>
                    <Col className="form-group" xs={24} style={{borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
                        <Col xs={24} sm={3}>
                            <ControlLabel className="s-table-titles">{t('screen.reports.entries.form.start_date')}</ControlLabel>
                            <input type="date" name="start_date" id="start_date" 
                                className="form-control form-control-sm"
                                value={this.state.start_date}
                                onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} sm={3}>
                            <ControlLabel className="s-table-titles">{t('screen.reports.entries.form.end_date')}</ControlLabel>
                            <input type="date" name="end_date" id="end_date" 
                                className="form-control form-control-sm"
                                value={this.state.end_date}
                                onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} sm={4} className="form-group">
                            <ControlLabel className="s-table-titles">{t('screen.reports.entries.form.location')}</ControlLabel>
                            <select 
                                className="form-control form-control-sm" name="location" 
                                id="location" value={this.state.location}
                                disabled={this.state.userType === 'client'? true : false}
                                onChange={(e)=>this.handleChange(e)}>
                                <option value="all">{t('screen.reports.all')}</option>
                                {this.state.locations.map((loc, i)=>
                                    <option value={loc.name} key={i}>{loc.name}</option>
                                )}
                            </select>
                        </Col>
                        <Col xs={24} sm={6} className="form-group">
                            <ControlLabel className="s-table-titles">{t('screen.reports.entries.form.employee')}</ControlLabel>
                            <AutoComplete
                                data={this.state.data}
                                value={this.state.employee}
                                onChange={(e)=>this.handleChange({target:{name:'employee', value:e}})}
                                id="employee"
                                name='employee'
                                autoComplete="off"
                                required
                                size="sm"
                            />
                        </Col>
                        <Col xs={24} sm={6} style={{marginTop:30}}>
                            <Button className="s-btn-primary" onClick={(e)=>this.search(e)}>
                                {t('button.search')}
                            </Button>
                            <Button onClick={(e)=>this.props.history.goBack()} style={{marginLeft:8}} >
                                {t('button.return')}
                            </Button>
                        </Col>
                    </Col>
                    <Col xs={24}>
                        <Col xs={24}>
                            <TableList 
                                {...this.props} 
                                onRef={ref => this.table = ref} 
                                columns={this.state.columns}
                                btnNew={false}
                                data={this.state.list}
                                actions={[]}
                                updateColumns={this.updateColumns}
                                actionIcon={this.getLocation}
                                screen={"entries"}
                                searching={false}
                                report={true}
                                table_title={[{title:'entry', col:4},{title:'out', col:3}]}
                                filename={t('screen.reports.entries.'+this.state.filename)}
                                scroll={true}
                                widths={['20%', '10%', '10%', '15%', '10%', '10%', '15%']}
                            />
                        </Col>
                    </Col> 
                </Grid>

                <ModalMap {...this.props} onRef={ref => this.modal_map = ref} />
            </Layout>
        )
    }
}

export default withTranslation('translations')(Entries);