import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { Modal, Col, Grid } from 'rsuite';
import moment from "moment";
import Geocode from "react-geocode";

import {Map, InfoWindow, Marker, GoogleApiWrapper, APIProvider, Circle,
    Polyline
} from 'google-maps-react';

import TableList from "../../../components/TableList";


Geocode.setApiKey("AIzaSyAig1H6xWKyyS5J6NeADJhi8YR2A2N6akI");
Geocode.setLanguage("es");
Geocode.enableDebug();

//const ModalLocationMap = (props) => {
const ModalChecksMap = forwardRef((props , ref) => {
    const [lat, setLat] = useState('');
    const [long, setLong] = useState('');
    const [name, setName] = useState('');
    const [show, setShow] = useState(false);
    //const [item, setItem] = useState('')
    const [columns, setColumns] = useState([
        {title:'index', name:'index', visible:true, width:'10%'},
        {title:'date', name:'started_at', visible:true, width:'40%'},
        {title:'face', name:'face', visible:true, width:'20%'},
        {title:'location', name:'location', visible:true, width:'30%'},
    ])
    const [list, setList] = useState([]);
    const [listChecks, setListChecks] = useState([])

    // Estado para almacenar las coordenadas de la ruta
    const [pathCoordinates, setPathCoordinates] = useState([]);


    const table_ref = useRef(null);

    useImperativeHandle(ref, ()=>({
        handleShow,
        //GoogleApiWrapper
    }));

    useEffect(() => {
        //console.log(props.item)
        if(props.open===true){
            setLat(props.item.startLocation.latitude);
            setLong(props.item.startLocation.longitude);
            setName(moment(props.item.visitStartDate).format('DD/MM/YYYY HH:mm'));
            GetAllowedDistance(props.item.id)
            setShow(true)
        }
        
    }, []);

    // Función para actualizar las coordenadas de la ruta cuando se cargan los checks
    useEffect(() => {
        if (listChecks.length > 0) {
            const sortedChecks = [...listChecks].sort((a, b) => 
                new Date(a.localCheckTime) - new Date(b.localCheckTime)
            );
            const coordinates = sortedChecks.map(check => ({
                lat: check.latitude,
                lng: check.longitude
            }));
            
            setPathCoordinates(coordinates);
        }
    }, [listChecks]);

    const GetAllowedDistance = async(id) => {
		var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

		var req = {
            api:'accounts',
            method:'GET',
            url:'accounts/'+accountId,
            requireToken:true,
        }
		props.modal_loader.handleShow()
		let response =  await props.fetchRequest(req);

		if(response !== null&& response !== undefined){
			//console.log(response);
			let dis = response.accountConfig && response.accountConfig.checkMaxRaduis? response.accountConfig.checkMaxRaduis : 0; 
			
            await GetRouteChecks(id, dis);
			
		}

		props.modal_loader.handleClose();
	}

    const GetRouteChecks = async(id, dis) => {
        //props.modal_loader.handleShow()
        var req = {
            api: 'reports',
            url: 'reports/checks-by-route/'+id,
            //obj: obj,
            method: 'GET',
            modal: props.modal_loader,
            message_modal: props.t('modal.spin.searching'),
            requireToken: true
        };

        var request = await props.fetchRequest(req);

        if (request) {
            let allowed = dis//? dis : checkMaxRaduis;
            //console.log(allowed)
            var list = [];
			request.map((col, i) => {
				let meters = col.metersFromLocation? col.metersFromLocation : 0;
                let idx = i+1;
                //console.log(allowed +' '+meters +' ');
                console.log(idx.toString())
				/*col.showLocation = meters <= allowed? false : true;
				col.checkLabel = t('screen.reports.employees_activity.check_type_label_'+col.checkType)
				col.localCheckTime = moment(col.localCheckTime).format('DD/MM/YYYY HH:mm');*/
				list.push([
                    idx.toString(),
					col.localCheckTime? moment(col.localCheckTime).format('DD/MM/YYYY HH:mm') : '',
					col.validFace ? 
                        'icon/MdDone/green/entry/no_action'
                    	: 'icon/MdClose/red/entry/no_action',
					meters <= allowed?
						'icon/'+col.location+'/#7E7E7E/no_location/no_action/'+
                        col.latitude+'/'+col.longitude
						:'icon/MdOutlineLocationOn/'+'red/start_location/no_action/' +
                        col.latitude+'/'+col.longitude
				])
			})
			//console.log(response)
			//setListActivities(response);
			setList(list)
            setListChecks(request)
        }

        //props.modal_loader.handleClose();
    }

    const actionIcon = (e, i, d) => {
		//console.log(e)
		/*console.log(i)
		console.log(d)

		if (d === 'evidence') {
			GoToVisitDetails(listActivities[i]);
		} else {
			OpenModalMap(listActivities[i])
		}*/
		
	}

    const handleShow = (lat, lng, name) => {
        
        console.log(props)
        
        setShow(true);
        //this.setState({lat:lat, long:lng, name:name, show:true});
    }

    const handleClose = () => {
        setLat('');
        setLong('');
        setName('');
        props.close();
        setShow(false);
        //this.setState({show: false, lat:"", long:"", name:""});
    }

    return(
        <Modal style={{width:'95%'}} show={show} onHide={handleClose} >
            <Modal.Header></Modal.Header>
            <Modal.Body>
                <div className="form-group">
                <Grid fluid>
                    <Col xs={24} sm={10}>
                        <TableList 
                            t={props.t}
                            onRef={ref => table_ref} 
                            columns={columns}
                            btnNew={false}
                            data={list}
                            actions={[]}
                            actionIcon={actionIcon}
                            screen={"checks_map"}
                            searching={false}
                            report={true}
                            table_title={[]}
                            scroll={false}
                        />
                    </Col>
                    <Col xs={24} sm={14} style={{height:"500px"}}>
                        
                        <Map google={props.google} initialCenter={{
                                lat: lat,
                                lng: long
                            }}
                            className="s-w-map"
                            zoom={15}
                            >
                                {
                                    listChecks.map((item, i) => (
                                            <Marker
                                                title={item.localCheckTime? moment(item.localCheckTime).format('DD/MM/YYYY HH:mm') : ''}
                                                name={item.localCheckTime? moment(item.localCheckTime).format('DD/MM/YYYY HH:mm') : ''}
                                                zoom={15}
                                                position={{lat: item.latitude, lng: item.longitude}} 
                                                label={(i+1).toString()}
                                            />
                                            
                                        )
                                    )
                                }

                                {/* Línea que une los marcadores */}
                                <Polyline
                                    path={pathCoordinates}
                                    strokeColor="rgb(33, 150, 243)"
                                    strokeOpacity={0.8}
                                    strokeWeight={2}
                                />
                                
                                <InfoWindow >
                                    <div>
                                        <h1>{"test"}</h1>
                                    </div>
                                </InfoWindow>
                        </Map>
                    
                    
                    </Col>
                </Grid>
                    
                </div>
            </Modal.Body>
        </Modal>
    )
})

export default (
    GoogleApiWrapper({
        apiKey: ("AIzaSyAig1H6xWKyyS5J6NeADJhi8YR2A2N6akI")
    })(ModalChecksMap)
);
