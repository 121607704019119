import React, { Component } from "react";
import {withTranslation} from 'react-i18next';
import validator from 'validator';
import moment from 'moment';
import { FlexboxGrid, Col, Panel, Grid, Button, Alert, Icon, IconButton } from "rsuite";

import Layout from "../../../components/Layout";
import General from "../../../components/settings/employee/General";
import WithFacial from "../../../components/settings/employee/WithFacial";
import TaxData from "../../../components/settings/employee/TaxData";
import ScheduleRules from "../../../components/settings/employee/ScheduleRules";
import IncidentRules from "../../../components/settings/employee/IncidentRules";
import { isPhone, swalAction } from "../../../lib/functions";
import Picture from "../../../components/settings/employee/Picture";
import Documents from "../../../components/settings/employee/Documents";

class EmployeeDetails extends Component{
    constructor(props){
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.changeIncident = this.changeIncident.bind(this);
        this.changeRules = this.changeRules.bind(this);
        this.sendInvitation = this.sendInvitation.bind(this);
        this.activeEmployee = this.activeEmployee.bind(this);
        this.confirmEnable = this.confirmEnable.bind(this);
        this.setImage = this.setImage.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.move = this.move.bind(this);
        this.verifySave = this.verifySave.bind(this);
        this.getRolesByAccount = this.getRolesByAccount.bind(this);

        this.state = {
            schedule: {
	            name:"",
	            label:"",
	            useBreakTime:false,
                activeWeekend:true,                
                nightShift:false,
	            days:[
	                {name:'monday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:true},
	                {name:'tuesday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'wednesday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'thursday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'friday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'saturday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'sunday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	            ]
            },
            incident:{
                name:"",
                label:"",
                supportDelays:"No",
                minutesDelays:"",
                numDelays:"",
                supportExtraHr:"No",
                maxExtraHr:"",
                supportEconomicDay:"No",
                maxEconomicDay:""
            },
            facial:false,

            codes:[],
            names:"",
            lastname1:"",
            lastname2:"",
            birthday:"",
            sex:"",
            phone:"",
            code:"",
            country:"",
            email:"",
            error_email:"",
            error_phone:"",
            active:false,

            rfc:"",
            nss:"",
            salary:"",

            sms:false,
            send_email:false,
            show_direction: false,
            schedule_name:"",
            incident_name:"",
            suscription:"",
            campos:[],
            show_image:false,
            photoBase64:"",
            saved:false,
            edit_general:false,
            days:['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
            supervisor: false,
            roleId: null,
            edit_tax:false,
            roles:[],

            contractors:[],
            contractorUserId:'',
        }
    }

    async componentDidMount(){
        var id = this.props.match.params.id;
        await this.getRolesByAccount();
        
        var employee = await this.props.fetchRequest({
                            api:'accounts', 
                            method:'GET', 
                            url:'employees/'+id, 
                            requireToken:true,
                            modal:this.props.modal_loader,
                            message_modal:this.props.t('modal.spin.loading')
                        });
    
        if(employee !== undefined){
            await fetch('https://countriesnow.space/api/v0.1/countries/codes')
                .then(res => {
                    return res.json().then((r)=>{
                        return r;
                    });
                }).then(response => {
                    var codes = [];
                    response.data.map((res)=>
                        codes.push({
                            code: res.dial_code,
                            label: res.code,
                            value: res.code,
                        })
                    );
                    this.setState({codes: codes});
                });

            var ip = await fetch('https://get.geojs.io/v1/ip/country.json')
                        .then(res => {
                            return res.json().then((r)=>{
                                var index = this.state.codes.findIndex(obj => obj.value === r.country);
                      
                                if(employee.phone === ""){
                                    if(index !== -1){
                                        this.setState({code: this.state.codes[index].value, country:this.state.codes[index].label});
                                    }
                                }
                                return r.ip;
                            });
                        });

            var code = "";
            var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
            accountId = accountId.substring(1, accountId.length - 1);

            var req = {
                api:'accounts',
                method:'GET',
                url:'schemas/by-account-id/'+accountId,
                requireToken:true
            };
            let scheme = await this.props.fetchRequest(req);

            if(scheme !== undefined){
                var campos = [];
                scheme.map((s)=>{
                    var properties = s.schema.schema_1.properties;
                    var required = s.schema.schema_1.required;

                    var keyNames = Object.keys(properties);

                    if(keyNames.length > 0){
                        keyNames.map((k)=>{
                            var obj = properties[k];
                            obj.id = k;
                            obj.required = false;

                            var index = required.findIndex(item => item === k);

                            if(index !== -1){
                                obj.required = true;
                            }

                            return campos.push(obj);
                        });
                    }
                    
                    
                    return s;
                });

                this.setState({campos: campos});
                
            }

            if(employee.attributes !== null){
                if(employee.attributes.faceId !== undefined){
                    if(employee.attributes.faceId !== ""){
                        var image = await this.props.fetchRequest({api:'accounts', method:'GET', url:'employees/image/'+id, requireToken: true});
                        
                        if(image.base64 !== null){
                            this.with_facial.setState({
                                image: 'data:image/jpeg;base64,'+image.base64
                            });
                        }
                        
                        this.setState({saved: true});
                    }
                    
                }

                if(employee.attributes.special_fields !== undefined){
                    
                    employee.attributes.special_fields.map(item => {
                        if(document.getElementById(item.title) !== null){
                            document.getElementById(item.title).value = item.value;
                        }

                        return item;
                    });
                }

                if(employee.attributes.salary !== undefined){
                    this.setState({salary: employee.attributes.salary});
                }
            }

            var schedule = employee.schedule;

            this.state.days.map((day, i)=>{
                if(schedule.type === 'traditional'){
                    var index = schedule.days.findIndex(obj => obj.name === day);
                    
                    if(index !== -1){
                        schedule.days[index].startTime = schedule.days[index].startTime !== null ? moment(schedule.days[index].startTime).format('HH:mm') : "";
                        schedule.days[index].closingTime = schedule.days[index].closingTime !== null ? moment(schedule.days[index].closingTime).format('HH:mm') : "";
                        schedule.days[index].breakTime = schedule.days[index].breakTime !== null ? moment(schedule.days[index].breakTime).format('HH:mm') : "";
                        schedule.days[index].breakReturnTime = schedule.days[index].breakReturnTime !== null ? moment(schedule.days[index].breakReturnTime).format('HH:mm') : "";
                    
                        schedule.days[index].error_start = "";
                        schedule.days[index].error_close = "";
                        schedule.days[index].error_break = "";
                        schedule.days[index].error_return = "";
                        
                        schedule.days[index].check = schedule.days[index].startTime === null ? false : true;

                        schedule.activeWeekend = false;
                        if(schedule.days[index].name === 'saturday' || schedule.days[index].name === 'sunday'){
                            schedule.activeWeekend = true;
                        }
                        //schedule.days.splice(i, 0, schedule.days[index]);
                        this.move(schedule.days, index, i);
                    }else{
                        schedule.days.splice(i, 0, {
                            name: day,
                            startTime:"",
                            closingTime:"",
                            breakTime:"",
                            breakReturnTime:"",
                            error_start: "",
                            error_close: "",
                            error_break: "",
                            error_return: "",
                            check: false
                        });
                        
                    }
                }else if(schedule.type === 'flexible'){
                    schedule.days.push({
                        startTime: schedule.startTimeDay !== null ? moment(schedule.startTimeDay).format('HH:mm') : "",
                        closingTime: schedule.closingTimeDay !== null ? moment(schedule.closingTimeDay).format('HH:mm') : "",
                        breakTime: schedule.breakInTimeDay !== null ? moment(schedule.breakInTimeDay).format('HH:mm') : "",
                        breakReturnTime: schedule.breakOutTimeDay !== null ? moment(schedule.breakOutTimeDay).format('HH:mm') : "",
                        
                        error_start: "",
                        error_close: "",
                        error_break: "",
                        error_return: "",
                        
                        check: true,
                    
                    });
    
                }else if(schedule.type ==='24x24'){
                    schedule.days.push({
                        startTime: schedule.startTimeDay !== null ? moment(schedule.startTimeDay).format('HH:mm') : "",
                        closingTime: schedule.closingTimeDay !== null ? moment(schedule.closingTimeDay).format('HH:mm') : "",
                        breakTime: schedule.breakInTimeDay !== null ? moment(schedule.breakInTimeDay).format('HH:mm') : "",
                        breakReturnTime: schedule.breakOutTimeDay !== null ? moment(schedule.breakOutTimeDay).format('HH:mm') : "",
                        
                        error_start: "",
                        error_close: "",
                        error_break: "",
                        error_return: "",
                        
                        check: true,
                    
                    });
                }
                
                return schedule.days;
                
            });

            var incident = {
                name: employee.incidentRule.name,
                label: employee.incidentRule.name.toLowerCase().replace(' ', ''),
                supportDelays: employee.incidentRule.enableDelays ? 'Si' : 'No',
                minutesDelays: employee.incidentRule.allowedDelayMinutes,
                numDelays: employee.incidentRule.delaysToAbsence,
                supportExtraHr: employee.incidentRule.enableOvertime ? 'Si' : 'No',
                maxExtraHr: employee.incidentRule.overtimeMax,
                supportEconomicDay: employee.incidentRule.enableEconomicDays === 0 ? 'No' : 'Si',
                maxEconomicDay: employee.incidentRule.economicDaysMax
            };
            
            var phone = '';
            if(employee.phone !== null){
                var full_phone = employee.phone.split(' ');
                
                full_phone.map((f, i)=>{
                    if(i === 0){
                        if(employee.attributes !== null){
                            if(employee.attributes.country !== undefined){
                                if(employee.attributes.country === ""){
                                    employee.attributes.country = 'MX';
                                }
                                
                                var index = this.state.codes.findIndex(obj => 
                                    obj.value === employee.attributes.country && obj.code === f                                    
                                )
                      
                                if(index !== -1){
                                    code = this.state.codes[index].value;    
                                }
                                
                            }else{
                                var index = this.state.codes.findIndex(obj => 
                                    obj.code === f                                    
                                )
                      
                                if(index !== -1){
                                    employee.attributes.country = this.state.codes[index].value;
                                    code = this.state.codes[index].value;    
                                }
                            }
                        }
                    }else{
                        phone += f+' ';
                    }

                    return f;
                })
            }
   
            this.setState({
                facial: employee.enforceFacialRecognition,
                names:employee.firstName,
                lastname1:employee.lastName,
                lastname2:employee.maternalSurname,
                birthday: employee.birthDate !== null ? moment(employee.birthDate.split('T')[0]).format('YYYY-MM-DD') : "",
                sex:employee.gender !== null ? employee.gender : "",
                phone: phone.trim(),
                code: code,
                email:employee.email,
                active:employee.active,
                incident: incident,
                schedule: schedule, 
                employee: employee,
                schedule_name: schedule.name,
                incident_name: incident.name,
                suscription: sessionStorage.getItem('suscription'),
                supervisor: employee.supervisor,
                rfc:employee.taxId,
                nss: employee.ssn,
                roleId: employee.role !== null? employee.role.id:null,
                contractorUserId: employee.contractorId ? employee.contractorId : ''
            });

            await this.GetContractors();

            this.rules_scheme.schedule_form.setState({schedule});      
            
            this.rules_incident.setState({incident});
        }
    }

    async getRolesByAccount(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            method:'GET',
            url:'roles/by-account/'+accountId,
            requireToken:true
        }
        let response =  await this.props.fetchRequest(req);

        if(response !== null&& response !== undefined){
            this.setState(prevState => ({
                roles: response
            }));
        }
    }

    async GetContractors() {
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            method:'GET',
            url:'lookups/by-account-and-type/'+accountId+'/contractor',
            requireToken:true
        }
        let response =  await this.props.fetchRequest(req);

        if (response !== null&& response !== undefined && response.length > 0) {
            

            this.setState({
                contractors:response,
               // contractorUserId: sessionStorage.getItem('userType') === 'contractor' ? response[0].id : ''
            })

            if (sessionStorage.getItem('userType') === 'contractor') {
                let email = sessionStorage.getItem('userEmail');
                let idx = response.findIndex(obj => obj.id === sessionStorage.getItem('contractorId'));

                this.setState({
                   contractorUserId: idx !== -1? response[idx].id : ''
                })
            }
        }
    }

    move(arr, old_index, new_index) {
       while (old_index < 0) {
           old_index += arr.length;
       }
       while (new_index < 0) {
           new_index += arr.length;
       }
       if (new_index >= arr.length) {
           var k = new_index - arr.length;
           while ((k--) + 1) {
               arr.push(undefined);
           }
       }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);  
       return arr;
    }

    handleChange(value, e){
		if(e === undefined){
			e = value;
			value = e.target.value
		}
        
		let name = e.target !== undefined ? e.target.name : e;
    
        switch(name){
            case 'email':
                this.setState({ [name]: e.target.value });
            
                if(e.target.value === ""){
                    this.setState({error_email:''});
                }else{
                    if(!validator.isEmail(e.target.value)){
                        this.setState({error_email:this.props.t('error_alert.invalid_email')});
                    }else{
                        this.setState({error_email:''});
                    }
                }
                break;
            case 'phone':
                var index = this.general.codes.findIndex(obj =>
                    obj.value === this.state.code    
                );
                //var length = 10;

                if(index !== -1){
                    //length = this.general.codes[index].digitos;
                }
                if(e.target.value === ""){
                    this.setState({ [name]: e.target.value });	
                }
                if(isPhone(e.target.value)){
                    this.setState({ [name]: e.target.value });	
                }				
    
                if(e.target.value === ""){
                    this.setState({error_phone:''});
                }else{
                    if(!validator.isMobilePhone(e.target.value, ['es-MX'])){
                        //this.setState({error_phone:this.props.t('error_alert.invalid_phone')});
                    }else{
                        this.setState({error_phone:''});
                    }
                }
                break;
            case 'schedule_name':
                this.setState({[name]: value});	
                this.schedule_rules.selectRule(e);

                setTimeout(()=>{
                    var s = this.schedule_rules.state.schedule;

                    var item = {
                        name: s.name,
                        label: s.label,
                        useBreakTime: s.useBreakTime,
                        activeWeekend: s.activeWeekend,                
                        nightShift: s.nightShift,
                        workingHour: s.workingHour,
                        breakTime: s.breakTime,
                        days: []
                    };

                    s.days.map(d =>{
                        item.days.push({
                            name: d.name,
                            key: d.name,
                            startTime: moment(d.date + ' ' + d.startTime).format('YYYY-MM-DDTHH:mm:ss.sss'),
                            closingTime: moment(d.date + ' ' + d.closingTime).format('YYYY-MM-DDTHH:mm:ss.sss'),
                            breakTime: moment(d.date + ' ' + d.breakTime).format('YYYY-MM-DDTHH:mm:ss.sss'),
                            breakReturnTime: moment(d.date + ' ' + d.breakReturnTime).format('YYYY-MM-DDTHH:mm:ss.sss')
                        });
        
                        return item;
                    });

                    this.setState({schedule: item});
                }, 500);
 
                break;
            case 'incident_name':
                this.setState({[name]: value});	
                this.incident_rules.selectIncident(e);

                setTimeout(()=>{
                    var i = this.incident_rules.state.incident;

                    var incident = {
                        name: i.name,
                        enableDelays: i.supportDelays === 'No' ? false : true,
                        delaysToAbsence: i.numDelays,
                        allowedDelayMinutes: i.minutesDelays,
                        enableOvertime: i.supportExtraHr === 'No' ? false : true,
                        overtimeMax: i.maxExtraHr,
                        enableEconomicDays: i.supportEconomicDay === 'No' ? 0 : 1,
                        economicDaysMax: i.maxEconomicDay
                    }
                    
                    this.setState({incident: incident});
                }, 500 )
                break;
            case 'birthday':
                this.setState({[name]: value});  
                break;
            case 'show_image':
                this.setState({show_image: !this.state.show_image});
                break;
            case 'salary':
                if(e.target.value === ""){
                    this.setState({[e.target.name]: e.target.value});
                }else{
                    
                    if(validator.isFloat(e.target.value)){
                        if(e.target.value.indexOf('.') === -1){
                            this.setState({[e.target.name]: e.target.value});
                        }else{
                            if((e.target.value.length - 1) - e.target.value.indexOf('.') <= 2){
                                this.setState({[e.target.name]: e.target.value});
                            }
                        }
                        
                    }
                }
                break;
            default:
                this.setState({[name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value});	
                break;
        }        
	}

	handleCheck(e){
		this.setState({ [e.target.name]: e.target.checked ? true : false });
    }

    changeIncident(e){
        var value = e.target.value;
        this.setState({[e.target.name]: value});
        
        this.rules_incident.selectIncident(e);
    }
    
    onCancel(){
        this.props.history.push('/settings/employee');
    }

    async onUpdate(refresh = true){
        var f = new Date();
        var month = f.getMonth() + 1;
        var day = f.getDate();
        var hoy = f.getFullYear() + "-" + (month < 10 ? '0'+month : month) + "-" + (day < 10 ? '0'+day : day);
        var obj = this.state.employee;
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var s = this.rules_scheme.schedule_form.state.schedule;

        var schedule = {
            name: s.name,
            label: s.label === "" ? s.name.replace(' ', '') : s.label,
            useBreakTime: s.useBreakTime,
            activeWeekend: s.activeWeekend,                
            nightShift: s.nightShift,
            workingHour: s.workingHour,
            breakTime: s.breakTime,
            minimumHours: s.minimumHours,
            type: s.type,
            days: []
        };

        s.days.map((d, i) =>{
            d.date = d.date === undefined ? moment().format('YYYY-MM-DD') : d.date;
            var obj = {
                name: d.name,
                key: d.name,
            };

            switch(s.type){
                case 'traditional':
                    if(d.check){
                        obj.startTime = d.startTime !== "" ? moment(hoy + ' ' + d.startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        obj.closingTime = d.closingTime !== "" ? moment(hoy + ' ' + d.closingTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        obj.breakTime = d.breakTime !== "" ? moment(hoy + ' ' + d.breakTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        obj.breakReturnTime = d.breakReturnTime !== "" ? moment(hoy + ' ' + d.breakReturnTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                    }
                    break;
                case 'flexible':
                    if(i === 0){
                        schedule.startTimeDay = d.startTime !== "" ? moment(hoy + ' ' + s.days[0].startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        schedule.closingTimeDay = d.closingTime !== "" ? moment(hoy + ' ' + s.days[0].closingTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        schedule.breakInTimeDay = d.breakTime !== "" ? moment(hoy + ' ' + s.days[0].breakTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                        schedule.breakOutTimeDay = d.breakReturnTime !== "" ? moment(hoy + ' ' + s.days[0].breakReturnTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                    } 
                    break;
                case '24x24':
                    schedule.startTimeDay = d.startTime !== "" ? moment(hoy + ' ' + s.days[0].startTime).format('YYYY-MM-DDTHH:mm:ss.sss') : null;
                    break;
                default:
                    break;
            }

            if(schedule.type === 'traditional' && obj.startTime !== undefined){
                schedule.days.push(obj);
            } 
            

            return schedule;
        });

        var i = this.rules_incident.state.incident;
        var incident = {
            name: i.name,
            enableDelays: i.supportDelays === 'No' ? false : true,
            delaysToAbsence: i.numDelays,
            allowedDelayMinutes: i.minutesDelays,
            enableOvertime: i.supportExtraHr === 'No' ? false : true,
            overtimeMax: i.maxExtraHr,
            enableEconomicDays: i.supportEconomicDay === 'No' ? 0 : 1,
            economicDaysMax: i.maxEconomicDay
        }

        if(this.state.code !== ""){
            var index = this.state.codes.findIndex(item => item.value === this.state.code);
            var code = this.state.codes[index].code;
        }

        obj.firstName = this.state.names;
        obj.lastName = this.state.lastname1;
        obj.maternalSurname = this.state.lastname2;
        obj.birthDate = this.state.birthday !== "" ? moment(this.state.birthday).format('YYYY-MM-DD'): "";
        obj.gender = this.state.sex;
        obj.phone = code+' '+this.state.phone;
        obj.email = this.state.email;
        obj.schedule = schedule;
        obj.incidentRule = incident;
        obj.supervisor = this.state.supervisor;
        obj.active = this.state.active;
        obj.taxId = this.state.rfc;
        obj.ssn = this.state.nss;
        //obj.contractorUserId = this.state.contractorUserId;
        obj.contractorId = this.state.contractorUserId;

        if(this.state.roleId !== null && this.state.roleId !== undefined){
            obj.role = {
                id: this.state.roleId
            }
        }

        if(this.state.show_direction){
            obj.address = this.address_form.state.address;
        }

        var special_fields = [];
        this.state.campos.map((c)=>{
            var item = {
                title: c.id,
                value: document.getElementById(c.id).value
            };

            if(item.value !== ""){
                special_fields.push(item);
            }
            return c;
        });
    
        if(obj.attributes !== null){
            if(special_fields.length > 0){
                obj.attributes.special_fields = special_fields;    
            }
            
            obj.attributes.salary = this.state.salary;
            obj.attributes.country = this.state.code;
        }else{
            if(special_fields.length > 0){
                obj.attributes = {
                    special_fields: special_fields,
                    salary: this.state.salary,
                    country: this.state.code
                };
            }else{
                obj.attributes = {                  
                    salary: this.state.salary,
                    country: this.state.code
                };
            }
            
        }
        
        var req = {
            api:'accounts',
            method:'PUT',
            url:'employees/by-account/'+obj.id+"/"+accountId,
            obj:obj,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.saving'),
            requireToken:true
        }

        var request = await this.props.fetchRequest(req);

        if(request !== undefined && refresh){
            this.props.history.push('/settings/employee');
        }

        if(!refresh){
            this.setState({edit_general: false, edit_tax: false});
        }
    }

    changeRules(e, i){
		var schedule = this.state.schedule;
     
        if(e.target.name === 'activeWeekend'){
            schedule.activeWeekend = e.target.checked;

        }else if(e.target.name === 'useBreakTime'){
            schedule.useBreakTime = e.target.checked;
        }else{
            schedule.days[i][e.target.name] = e.target.type ==='checkbox' ? e.target.checked : e.target.value;

            if(i === 0 && e.target.type !== 'checkbox'){
                
                schedule.days.map((d, j)=>{
                    if(j !== i){
                        d[e.target.name] = e.target.value;
                    }              

                    return d;
                });
            }

            if(e.target.type !== 'checkbox'){
                this.validHours(schedule)
            }
        }

		this.setState({schedule: schedule});
    }

    async sendInvitation(type){
        var req = {
            api:'accounts',
            method:'POST',
            url:'employees/invite',
            obj:{
                type: type,
                employeeId: this.state.employee.id
            },
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.sending'),
            requireToken:true
        }
        
        var send = await this.props.fetchRequest(req);

        if(send){
            Alert.success(this.props.t('success_alert.correct'));
        }
        
    
       
    }

    compareHours(i, f){
        var inicial = moment(i, 'HH:mm');
        var final = moment(f, 'HH:mm');
        var valid = false;

        if(final.isBefore(inicial)){
            valid = true;
        }

        return valid;
    }

    validHours(schedule){
        schedule.days.map((d, j)=>{
            d.error_start = '';
            d.error_break = '';
            d.error_return = '';
            d.error_close = '';

            if(schedule.nightShift){
                if(this.compareHours( d.closingTime, d.startTime)){
                    d.error_start = 'error';
                }

                if(schedule.useBreakTime){
                    if(this.compareHours(d.breakTime, d.startTime)){
                        d.error_start = 'error';
                    }

                    if(this.compareHours(d.breakTime, d.breakReturnTime)){
                        d.error_break = 'error';
                    }
                    if(this.compareHours(d.breakReturnTime, d.startTime)){
                        d.error_start = 'error';
                    }

                    
                    if(this.compareHours(d.breakReturnTime, d.closingTime)){
                        d.error_return = 'error'
                    }

                    if(this.compareHours(d.breakTime, d.closingTime)){                      
                        d.error_break = 'error';
                    }
                }
            }else{
                if(this.compareHours(d.startTime, d.closingTime)){
                    d.error_start = 'error';
                }

                if(schedule.useBreakTime){
                    if(this.compareHours(d.startTime, d.breakTime)){
                        d.error_start = 'error';
                    }

                    if(this.compareHours(d.breakTime, d.breakReturnTime)){
                        d.error_break = 'error';
                    }
                    if(this.compareHours(d.startTime, d.breakReturnTime)){
                        d.error_start = 'error';
                    }

                    
                    if(this.compareHours(d.breakReturnTime, d.closingTime)){
                        d.error_return = 'error'
                    }

                    if(this.compareHours(d.breakTime, d.closingTime)){                      
                        d.error_break = 'error';
                    }
                }
            }


            return d;
        });
    }

    async confirmEnable(id){
        var res = await this.props.fetchRequest({
                    api:'accounts',
                    method:'POST',
                    url:'employees/change-active-status/'+id,
                    modal:this.props.modal_loader,
                    message_modal:this.props.t('modal.spin.inactivating'),
                    requireToken:true
                });
        
        this.setState({active: res.active});
    }

    activeEmployee(e){
        var id = this.props.location.state.id;

        var obj = {
            title           : this.props.t('modal.alert.titles.alert'),
            text            : this.props.t('modal.alert.messages.enable_employee'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.yes'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.no'),
            values          : id,
            fn              : this.confirmEnable
        }

        swalAction(obj);
        
    }

    setImage(value){
        this.setState({photoBase64: value});

        setTimeout(() => {
            this.saveImage();
        }, 500);
    }

    async saveImage(){
        if(this.state.photoBase64 !== ""){
            var obj = {
                employeeId: this.state.employee.id,
                photoBase64: this.state.photoBase64
            }

            var req = {
                api:'accounts',
                method:'POST',
                url: this.state.saved ? 'employees/update-image-base64' : 'employees/save-image',
                obj:obj,
                modal:this.props.modal_loader,
                message_modal: this.state.saved ? this.props.t('modal.spin.updating') : this.props.t('modal.spin.saving'),
                requireToken:true
            }

            var res = await this.props.fetchRequest(req);

            if(res !== undefined){
                if(res.attributes !== null){
                    if(res.attributes.faceId !== undefined){
                        if(res.attributes.faceId !== "" && res.attributes.faceId !== undefined){
                            var id = this.props.location.state.id;
                            var image = await this.props.fetchRequest({api:'accounts', method:'GET', url:'employees/image/'+id, requireToken: true});
                            
                            this.with_facial.setState({
                                image: 'data:image/jpeg;base64,'+image.base64
                            });
                            this.setState({saved: true, show_image:false});
                        }
                    }
                }
            }
        }
    }

    verifySave(){
        if(this.state.edit_general || this.rules_scheme.schedule_form.state.edit_schedule ||
            !this.rules_incident.state.disable_fields || this.state.edit_tax ){
            return false;
        }
        
        return true;
    }

    render(){
        const {t} = this.props;
        return (
            <Layout {...this.props} verify={this.verifySave}>
                <FlexboxGrid justify="center">
                    <Col xs={24} sm={20} md={20}>                     
                        <Panel header={t('screen.settings.employee.details.personal')} shaded
                            style={{marginBottom:10}}
                        >
                            
                            <Grid fluid>
                        
                                <Col xs={24} sm={2} md={1}>
                                    <IconButton 
                                        icon={<Icon icon={this.state.edit_general ? "save" : "pencil"} />}
                                        onClick={()=>this.state.edit_general ? this.onUpdate(false) : this.setState({edit_general: !this.state.edit_general})}
                                        color="blue"
                                        size="sm"
                                    />
                                </Col>
                        
                                <Col xs={24} sm={12} md={5}>
                                    <WithFacial {...this.props} onRef={ref=> this.with_facial = ref}
                                        handleChange={this.handleChange}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={18}>
                                    {!this.state.show_image ?
                                        <General 
                                            {...this.props}
                                            show_details={true} 
                                            onRef={ref => this.general = ref}
                                            names={this.state.names}
                                            lastname1={this.state.lastname1}
                                            lastname2={this.state.lastname2}
                                            code={this.state.code}
                                            phone={this.state.phone}
                                            email={this.state.email}
                                            sex={this.state.sex}
                                            codes={this.state.codes}
                                            birthday={this.state.birthday}
                                            active={this.state.active}
                                            error_email={this.state.error_email}
                                            error_phone={this.state.error_phone}
                                            campos={this.state.campos}
                                            edit_general={this.state.edit_general}
                                            supervisor={this.state.supervisor}
                                            roleId={this.state.roleId}
                                            handleChange={this.handleChange}
                                            handleCheck={this.handleCheck}
                                            activeEmployee={this.activeEmployee}
                                            invite={this.sendInvitation}
                                            roles={this.state.roles}
                                            contractors={this.state.contractors}
                                            contractorUserId={this.state.contractorUserId}
                                        />
                                    :
                                        <div>
                                            <Picture {...this.props} onRef={ref => this.picture = ref}
                                                setImage={this.setImage}
                                            />
                                            <div style={{marginTop:10, textAlign:'right'}}>
                                                <Button onClick={()=>this.setState({show_image: !this.state.show_image})}>
                                                    {t('button.cancel')}
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </Col>
                            </Grid>
                            
                        </Panel>

                        <Panel header={t('screen.settings.employee.details.documents')} shaded
                            style={{marginBottom:10}}>
                            <Documents {...this.props}
                                onRef={ref => this.documents = ref}
                            />
                        </Panel>
                                                
                        <Panel header={t('screen.settings.employee.details.schedule')} shaded
                            style={{marginBottom:10}}>
                            <ScheduleRules {...this.props}
                                changeColorNext={()=>{}}
                                //schedule={this.state.schedule}
                                onUpdate={this.onUpdate}
                                wizard={false}
                                details={true}
                                onRef={ref => this.rules_scheme = ref}
                            />
                        </Panel>

                                            
                        <Panel header={t('screen.settings.employee.details.schedule_rule')} shaded
                            style={{marginBottom:10}}
                        >
                            <IncidentRules 
                                {...this.props}
                                incident_name={this.state.incident_name}
                                //incident={this.state.incident}
                                handleChange={this.changeIncident}
                                onUpdate={this.onUpdate}
                                wizard={false}
                                details={true}
                                onRef={ref => this.rules_incident = ref}
                            />
                        </Panel>
                        {this.state.suscription !== 'free' &&
                            <Panel header={t('screen.settings.employee.details.tax_data')} shaded
                                style={{marginBottom:10}}
                            >
                                <Grid fluid>
                                    <Col xs={24} sm={24} md={1} className="icon-save-tax" >
                                        <IconButton 
                                            icon={<Icon icon={this.state.edit_tax ? "save" : "pencil"} />}
                                            onClick={()=>this.state.edit_tax ? this.onUpdate(false) : this.setState({edit_tax: !this.state.edit_tax})}
                                            color="blue"
                                            size="sm"
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={23}>
                                        <TaxData 
                                            {...this.props}
                                            rfc={this.state.rfc}
                                            nss={this.state.nss}
                                            salary={this.state.salary}
                                            edit_tax={this.state.edit_tax}
                                            handleChange={this.handleChange}
                                        />
                                    </Col>
                                </Grid>
                            </Panel>
                        }

                        <Col xs={24}>
                            <div style={{textAlign:'right'}}>
                                <Button onClick={(e)=>this.onCancel(e)}>
                                    {t('button.cancel')}
                                </Button>
                                <Button color="blue" style={{marginLeft:8}} onClick={(e)=>this.onUpdate(e)}>
                                    {t('button.update')}
                                </Button>
                            </div>
                        </Col>
                    </Col>
                
                </FlexboxGrid>
            </Layout>
        )
    }
}

export default withTranslation('translations')(EmployeeDetails);