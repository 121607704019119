import React, {Component} from 'react';
import { FlexboxGrid, Grid, Col} from 'rsuite';
import {withTranslation} from 'react-i18next';
import { swalAction } from '../../../lib/functions';

import Layout from '../../../components/Layout';
import TableList from '../../../components/TableList';

class Locations extends Component{
    constructor(props){
        super(props);
        this.deleteItem = this.deleteItem.bind(this);
        this.newLocation = this.newLocation.bind(this);
        this.updateColumns = this.updateColumns.bind(this);
        this.openEdit = this.openEdit.bind(this);
        this.openDetails = this.openDetails.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.goToPlans = this.goToPlans.bind(this);
        this.getLocationTypes = this.getLocationTypes.bind(this);

        this.state = {
            columns:[
                {title:'type', name:'type',  visible:true, width:'5%'},
                {title:'name', name:'name',  visible:true, width:'10%'},
                {title:'address', name:'address',  visible:true, width:'25%'},
                {title:'reference', name:'reference',  visible:false, width:'10%'},
                {title:'city', name:'city',  visible:false, width:'10&'},
                {title:'state', name:'state',  visible:false, width:'10%'},
                {title:'postal_code', name:'postal_code',  visible:false, width:'10%'},
                {title:'latitude', name:'latitude',  visible:true, width:'10%'},
                {title:'longitude', name:'longitude',  visible:true, width:'10%'},
                {title:'country', name:'country',  visible:true, width:'10%'},
                {title:'actions', name:'actions', visible:true, width:'150px'}
            ],
            data:[],
            locationsTypes:[],
            actions:[
                {
                    btn:true,
                    icon:'search',
                    title:this.props.t('button.details'),
                    clickFn:'details',
                    action: this.openDetails
                },
                {
                    btn:true,
                    icon:'edit',
                    title:this.props.t('button.edit'),
                    clickFn:'edit',
                    action: this.openEdit
                },
                {
                    btn:true,
                    icon:'trash-o',
                    title:this.props.t('button.delete'),
                    clickFn:'delete',
                    action: this.deleteItem
                }
            ],
            lang:""
        }
    }

    async componentDidMount(){
        await this.setState({lang:sessionStorage.getItem(btoa("lang"))})
        var columnsLocation = sessionStorage.getItem('columnsLocation');
        if(columnsLocation === null){
            sessionStorage.setItem('columnsLocation', JSON.stringify(this.state.columns));
        }else{
            this.setState({columns: JSON.parse(columnsLocation)});
        }

        await this.getLocationTypes();
        this.getLocations();
    }

    async componentDidUpdate(){
        var auxLang = sessionStorage.getItem(btoa("lang"));
        if(this.state.lang !== auxLang){
            await this.setState({lang:auxLang});
            this.getLocationTypes();
        }
    }

    goToPlans(){
        this.props.history.push("/subscription-plans");
    }

    async getLocations(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        let req = {
            api:'accounts',
            method:'get',
            url:'locations/by-account/'+accountId,
            obj:null,
            requireToken:true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.start')
        }

        let request = await this.props.fetchRequest(req);

        if(request !== undefined){

            let values = [];
            for (let index = 0; index < request.length; index++) {
                var type = this.state.locationsTypes.find(obj => {
                    return obj.key === request[index].type
                });


                let temp = [
                    type !== null && type !== undefined? type.value:"",
                    request[index].name,
                    request[index].address !== null? request[index].address.addressLine1:"",
                    request[index].address !== null? request[index].address.addressLine2:"",
                    request[index].address !== null? request[index].address.city:"",
                    request[index].address !== null? request[index].address.state:"",
                    request[index].address !== null? request[index].address.zipCode:"",
                    request[index].latitude.toFixed(4),
                    request[index].longitude.toFixed(4),
                    request[index].address !== null? request[index].address.country:"",
                    request[index].id
                ];

               values.push(temp);
            }

            var suscription = sessionStorage.getItem('suscription');
            var disabled = false;
            var message = 'new';

            if(values.length >= 1 && suscription === 'free'){
                disabled = true;
            }

            if(values.length >= 10 && suscription === 'Basic Account'){
                disabled = true;
            }

            this.table.setState({disabled_new: disabled, message: message});

            this.setState({data:values});

            this.table.setTable();
        }
    }

    async getLocationTypes(){

        this.setState({
            locationsTypes: await this.props.fetchRequest({
                api:'accounts', 
                url:'lookups/location_type/'+atob(this.state.lang)+"/ordered",
                requireToken:true
            })
       });	
    }

    async deleteItem(e,id){
        let req = {
            api:'accounts',
            method:'delete',
            url:'locations/'+id,
            obj:null,
            requireToken:true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.start')
        }

        let request = await this.props.fetchRequest(req);

        if(request !== undefined){
            setTimeout(()=>{
                this.table.resetTable();
                this.getLocations();
            }, 300);
            
        }else {
            //Alert.error("Error al borrar");
            setTimeout(()=>{
                this.table.resetTable();
                this.getLocations();
            }, 300);
        }
    }

    newLocation(){
        var suscription = sessionStorage.getItem('suscription');

        if((suscription === 'free' && this.state.data.length >= 1) || (suscription === 'Basic Account' && this.state.data.length >= 10)){
            this.verifySuscription();
        }else{
            this.props.history.push('/settings/locations/new');
        }
        
    }

    verifySuscription(){
        var obj = {
            title           : this.props.t('modal.alert.titles.suscription'),
            text            : this.props.t('modal.alert.messages.update_suscription'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.go_plans'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.cancel'),
            values          : '',
            fn              : this.goToPlans
        }
        swalAction(obj);  
    }

    openEdit(e, id){
        this.props.history.push({
			pathname: '/settings/locations/edit/'+id
		});
    }

    openDetails(e, id){
        this.props.history.push({
			pathname: '/settings/locations/details/'+id
		});
    }

    updateColumns(e, i){
        var columns = this.state.columns;

        columns[i].visible = e.target.checked;

        sessionStorage.setItem('columnsLocation', JSON.stringify(columns));

        this.setState({columns: columns});
    }

    render(){
        const {t} = this.props;
        return (
            <div>
                <Layout {...this.props}>
                    <h3 className="s-title-page">{t('screen.settings.location.list.title')}</h3>
                    <Grid fluid>
                        <FlexboxGrid justify="center">
                            <Grid fluid style={{width:'100%'}}>
                                <Col xs={24}>
                                    <TableList 
                                        {...this.props}
                                        onRef={ref => this.table = ref}
                                        columns={this.state.columns}
                                        btnNew={true}
                                        data={this.state.data}
                                        actions={this.state.actions}
                                        updateColumns={this.updateColumns}
                                        new={this.newLocation}
                                        screen={"location"}
                                        report={false}
                                        scroll={false}
                                    />
                                </Col>
                            </Grid>
                        </FlexboxGrid>
                    </Grid>
                </Layout>
            </div>
        )
    }
}

export default withTranslation('translations') (Locations);