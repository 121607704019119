import React, { useEffect, useState, useRef } from 'react';
import {withTranslation} from 'react-i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Grid, Col, ControlLabel, Button } from 'rsuite';
import Layout from '../../../components/Layout';
import TableList from '../../../components/TableList';
import Swal from 'sweetalert2';
import $ from 'jquery';
import ModalMap from '../ModalMap';
import ModalLocationMap from '../ModalLocationMap';
import ModalChecks from './ModalChecks';
import { fileDownLoad } from '../../../lib/fetchRequest';

//class Visit extends Component{
const TrackingChecks = (props)=>{
    const { t } = useTranslation("translations");
    var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
    accountId = accountId.substring(1, accountId.length - 1);
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [data, setData] = useState([]);
    const [filename, setFilename] = useState('tracking.name');
    const [columns, setColumns] = useState([
        {title:'employee', name:'employee', visible:true},
        {title:'start_date', name:'start_date', visible:true},
        {title:'start_location', name:'start_location', visible:true},
        {title:'end_date', name:'end_date', visible:true},
        {title:'end_location', name:'end_location', visible:true},
        {title:'visit_time', name:'visit_time', visible:true},
        {title:'roue', name:'route', visible:true}
    ]);
    const [list, setList] = useState([]);
    const [listRoute, setListRoutes] = useState([]);

    const table_ref = useRef(null);
    const modal_location_ref = useRef(null);
    const modal_check_ref = useRef(null);

    const [showModalLocation, setShowModalLocation] = useState(false);
    const [showModalChecks, setShowModalChecks] = useState(false);
    const [currentLat, setCurrentLat] = useState('');
    const [currentLng, setCurrentLng] = useState('')
    const [currentName, setCurrentName] = useState('')
    const [currentItem, setCurrentItem] = useState('')
    const [showTable, setShowTable] = useState(false)

    useEffect(() => {
        StarLoad();
    }, []);

    /*useEffect(() => {
        //console.log('reload list')
        //console.log(list)
    }, [list]);*/

    const StarLoad = async() => {
        //console.log(props.location.state);
        
        if(props.location.state !== undefined){
            console.log('cargar datos')
            setStartDate(props.location.state.startDate);
            setEndDate(props.location.state.endDate);
            await search(null, null, 'reports/route-checks');
        }else{
            //table.setTable();
            let date = new Date();
            let mont = (date.getMonth()+1) < 10 ? '0'+ (date.getMonth()+1) : (date.getMonth()+1);
            let day = date.getDate() < 10 ? '0'+ date.getDate() : date.getDate();
            //console.log(day)
            let dates = {
                start:date.getFullYear()+'-'+mont+'-'+'01',
                end:date.getFullYear()+'-'+mont+'-'+day
            }
            setStartDate(dates.start);
            setEndDate(dates.end);
            //table_ref.current.setTable();
            await search(dates, null, 'reports/route-checks');
        }
    }

    const handleChange = (e) => {
        //this.setState({[e.target.name]: e.target.value});
    }

    const search = async (dates, e, url) => {
        //console.log('search');
        $('.rs-btn-default').blur();
        var error = "";

        var obj = {
            startDate:dates? dates.start : start_date,
            endDate: dates? dates.end : end_date
        };

        var req = {
            api: 'reports',
            url: url+'/'+accountId,
            obj: obj,
            method: 'POST',
            modal: props.modal_loader,
            message_modal: props.t('modal.spin.searching'),
            requireToken: true
        };

        if(error === ""){
            var request = await props.fetchRequest(req);
            var list = [];
            //console.log(request);

            if (request) {
                
            
                request.map((l)=>
                    list.push([
                        l.employeeName? l.employeeName:'',
                        moment(l.startDate).format('DD/MM/YYYY HH:mm'),
                        /*l.startLocation !== null && l.startLocation !== ""? 
                            l.startLocation
                        :*/ 'icon/map-marker/'+(l.startLocation !== null && l.startLocation !== null ? 
                            'green/start_location/one/' : '#7E7E7E/no_location/no_action/')+
                            l.startLocation.latitude+'/'+l.startLocation.longitude,
                        moment(l.endDate).format('DD/MM/YYYY HH:mm'),
                        /*l.visitEndLocation !== null && l.visitEndLocation !== "" ?
                            l.visitEndLocation
                        :*/ 'icon/map-marker/'+(l.endLocation !== null && l.endLocation !== null ? 
                                'green/end_location/one/' : '#7E7E7E/no_location/no_action/')+
                                l.endLocation.latitude+'/'+l.endLocation.longitude,
                        l.routeTime !== null ? l.routeTime : '',
                        "icon/map/"+ '#2196F3' +"/route/"+'one'
                    ])
                );
                //console.log(list)

                props.modal_loader.handleClose();
                    
                setList(list);
                setListRoutes(request);
                setShowTable(true)
                //console.log(table_ref.current)
                if (dates) {
                    //console.log('entrar')
                    table_ref.current.setTable()
                }
                
                //table.setTable();

                
            }
        }else{
            Swal.fire('Error', error, 'error');
            
        }
        

    }

    const actionIcon = async (e, i, d) => {
        //console.log(i)
        //console.log(d)
        //return
        if(d === 'route'){
            OpenModalChecks(listRoute[i])
        }else{
            //getLocation(e, i, d);
            OpenModalMap(listRoute[i], d)
        }

        

    }

    const updateColumns = (e, i) => {
        var columns = columns;
        columns[i].visible = e.target.checked;
        setColumns(columns);
        
    }

    const getLocation = (e, i, d) => {
        /*console.log(i)
        console.log(d)
        console.log(list)*/
        var list = list[i];
        var index = list.findIndex(obj => obj.indexOf('map-marker/green/'+d) !== -1 );

        if(index !== -1){
            var name = list[0];
            var lat = parseFloat(list[index].split('/')[5]);
            var lng = parseFloat(list[index].split('/')[6]);

            //this.modal_map.handleShow(lat, lng, name)
            OpenModalMap({latitude:lat, longitude:lng, employeeName:name})
        }
        
    }

    const OpenModalMap = (item, d) => {
		//console.log(item);
        //console.log(d);
        let lat = '', lng = '';
        if (d === 'start_location') {
            lat = item.startLocation.latitude;
            lng = item.startLocation.longitude;
        }
        if (d === 'end_location') {
            lat = item.endLocation.latitude;
            lng = item.endLocation.longitude;
        }

		setCurrentLat(lat);
		setCurrentLng(lng);
		setCurrentName(item.employeeName)
		setShowModalLocation(true);
		//modal_location_ref.current.handleShow(item.latitude, item.longitude, item.name)
	}

	const CloseModalMap = () => {
		console.log('cerrar')
		setCurrentLat('');
		setCurrentLng('');
		setCurrentName('')
		setShowModalLocation(false);
	}

    const OpenModalChecks = (item) => {
        //console.log(item)
        setCurrentItem(item);
        setShowModalChecks(true);
    }

    const CloseModalChecks = () => {
        setCurrentItem('');
        setShowModalChecks(false);
    }

    const downloadEvidences = async () => {
        //console.log('descarga');
        if (list.length === 0) {
            console.log('sin datos')
            return;
        }
        
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);
        var obj = {
            startDate: start_date,
            endDate: end_date
        };
        var date1 = start_date.replace(/-/g, "");
        var date2 = end_date.replace(/-/g, "");
        //console.log(date1)
        //console.log(date2)
        //console.log('evidencias_'+date1+'_al_'+date2);
        //return;
        /*var suscription = sessionStorage.getItem('suscription');

        if(suscription === 'free' && this.state.employees.length >= 5){
            this.verifySuscription();
        }else{*/
            var res = await props.fetchRequest({
                api:'reports',
                method:'POST',
                url:'reports/visits-list/'+accountId,
                requireToken:true,
                obj:obj,
                modal:props.modal_loader,
                message_modal:'Descargando...',
                is_file:true
            });
    
            if(res !== undefined){
                //console.log(res)
                //fileDownLoad('template', ".xlsx", 'application/vnd.ms-excel', res);
                
                await fileDownLoad('evidencias_'+date1+'_al_'+date2, ".xlsx", 'application/vnd.ms-excel', res);
            }
        /*}    */    
    } 

    return (
        <Layout {...props}>
            <Grid fluid>
                <Col xs={24} style={{textAlign:'center'}}>
                    <h3>{t('screen.reports.'+filename)}</h3>
                </Col>
                <Col xs={24} className="form-group">
                    <Col xs={24} sm={4}>
                        <ControlLabel className="s-table-title">{t('screen.reports.absences.form.start_date')}</ControlLabel>
                        <input type="date" name="start_date" id="start_date" 
                            className="form-control form-control-sm"
                            value={start_date}
                            onChange={(e)=>setStartDate(e.target.value)} />
                    </Col>

                    <Col xs={24} sm={4}>
                        <ControlLabel className="s-table-title">{t('screen.reports.absences.form.end_date')}</ControlLabel>
                        <input type="date" name="end_date" id="end_date" 
                            className="form-control form-control-sm"
                            value={end_date}
                            onChange={(e)=>setEndDate(e.target.value)} />
                    </Col>

                    <Col xs={24} sm={6} style={{marginTop:24}}>
                        <Button className="s-btn-primary" onClick={(e)=>search(null, e, 'reports/route-checks')}>
                            {t('button.search')}
                        </Button>
                        <Button onClick={(e)=>props.history.goBack()} style={{marginLeft:8}}>
                            {t('button.return')}
                        </Button>
                    </Col>
                </Col>
                <Col xs={24}>
                    <Col xs={24}>
                        {showTable && <TableList 
                            //{...props} 
                            t={t}
                            ref={table_ref}
                            onRef={ref => table_ref} 
                            columns={columns}
                            btnNew={false}
                            data={list}
                            actions={[]}
                            actionIcon={actionIcon}
                            updateColumns={updateColumns}
                            screen={"tracking"}
                            searching={false}
                            report={true}
                            table_title={[]}
                            filename={t('screen.reports.'+filename)}
                            scroll={false}
                            widths={['25%','15%','10%','15%','10%','10%','15%']}
                            search={search}
                            downloadEvidences={downloadEvidences}
                        />}
                    </Col>
                </Col> 
            </Grid>

            {showModalLocation &&
                <ModalLocationMap 
                    open={showModalLocation}
                    close={CloseModalMap}
                    lat={currentLat}
                    lng={currentLng}
                    name={currentName}
                    ref={modal_location_ref}
                //onRef={ref => modal_location_ref}
                />
            }

            {showModalChecks &&
                <ModalChecks 
                    open={showModalChecks}
                    close={CloseModalChecks}
                    item={currentItem}
                    ref={modal_location_ref}
                    fetchRequest={props.fetchRequest}
                    modal_loader={props.modal_loader}
                    t={t}
                //onRef={ref => modal_location_ref}
                />
            }
        </Layout>
    )
    
}

export default withTranslation('translations')(TrackingChecks);