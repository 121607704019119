import React, { Component } from 'react';
import {Panel, Grid, Icon, Col, IconButton} from 'rsuite';
import {Table} from 'react-bootstrap';
import ModalAccountUser from './ModalAccountUser';
import { swalAction } from '../../../lib/functions';


class AccountUsers extends Component{
    constructor(props){
        super(props);

        this.getAccountUsers = this.getAccountUsers.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.deleteRole = this.deleteRole.bind(this);
        
        this.state = {
            accountUsers: []
        }
    }

    async componentDidMount(){
        this.props.onRef(this);
        await this.getAccountUsers();
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    openModal(){
        this.modal_account_users.handleShow();
    }    

    async getAccountUsers(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'auth',
            method:'GET',
            url:'users/by-account/'+accountId,
            requireToken:true
        }
        let response =  await this.props.fetchRequest(req);

        //console.log(response)
        if(response !== null&& response !== undefined){
            this.setState(prevState => ({
                accountUsers: response
            }));
        }
    }

    onDelete(roleId){
        console.log(roleId);
        var obj = {
            title           : "Esta acción eliminará el usuario seleccionado",
            text            : "¿Deseas continuar?",                                
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.yes'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.no'),
            values          : roleId,
            fn              : this.deleteRole
        };
        swalAction(obj);
    }

    async deleteRole(accountUserId){
        var req = await this.props.fetchRequest({
            api:'auth',
			method:'DELETE',
			url:'users/'+accountUserId,
			requireToken:true,
			modal:this.props.modal_loader,
			message_modal:this.props.t('modal.spin.deleting')
        });
        
        await this.getAccountUsers();
    }

    render(){
        const {t} = this.props;

        return (
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {t('screen.settings.account.users.title')}
                </i>
                <Grid fluid className="form-group">
                    <Col xs={4}>
                        <IconButton className="s-primary-btn" 
                            icon={<Icon icon="plus" />}
                            circle
                            size="sm"
                            onClick={()=>this.openModal()}
                        />
                    </Col>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>{this.props.t('screen.settings.account.users.name')}</th>
                                <th>{this.props.t('screen.settings.account.users.type')}</th>
                                <th>{this.props.t('screen.settings.account.users.contractor')}</th>
                                <th>{this.props.t('screen.settings.account.users.location')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.accountUsers.map((u, i)=>(
                                <tr key={i}>
                                    <td>{ u.email}</td>
                                    <td> {
                                        u.type === "owner" ? this.props.t('screen.settings.account.users.owner'):
                                        this.props.t('screen.settings.account.users.'+u.type)
                                    }</td>
                                    <td>{ u.name }</td>
                                    <td>{ u.locationName }</td>
                                    <td style={{width:'5%'}} align='right'>
                                        {u.type !== "owner"?
                                            <Icon icon="trash" size="lg" 
                                                onClick={()=>this.onDelete(u.id)}
                                                title={t('button.delete')}	/>
                                        :null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Grid>
                <ModalAccountUser {...this.props} onRef={ref => this.modal_account_users = ref} getAccountUsers={this.getAccountUsers} />
            </Panel>
        )
    }
 
}

export default AccountUsers;