import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, Col } from 'rsuite';
import Geocode from "react-geocode";

import {Map, InfoWindow, Marker, GoogleApiWrapper, APIProvider} from 'google-maps-react';


Geocode.setApiKey("AIzaSyAig1H6xWKyyS5J6NeADJhi8YR2A2N6akI");
Geocode.setLanguage("es");
Geocode.enableDebug();

//const ModalLocationMap = (props) => {
const ModalLocationMap = forwardRef((props , ref) => {
    const [lat, setLat] = useState('');
    const [long, setLong] = useState('');
    const [name, setName] = useState('');
    const [show, setShow] = useState(false);

    useImperativeHandle(ref, ()=>({
        handleShow,
        //GoogleApiWrapper
    }));

    useEffect(() => {
        console.log(props)
        if(props.open===true){
            setLat(props.lat);
            setLong(props.lng);
            setName(props.name);
            setShow(true)
        }
        
    }, []);

    const handleShow = (lat, lng, name) => {
        
        console.log(props)
        
        setShow(true);
        //this.setState({lat:lat, long:lng, name:name, show:true});
    }

    const handleClose = () => {
        setLat('');
        setLong('');
        setName('');
        props.close();
        setShow(false);
        //this.setState({show: false, lat:"", long:"", name:""});
    }

    return(
        <Modal size="lg" show={show} onHide={handleClose} >
            <Modal.Header></Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <Col xs={24} style={{height:"500px"}}>
                    
                        <Map google={props.google} initialCenter={{
                                lat: lat,
                                lng: long
                            }}
                            className="s-w-map"
                            zoom={15}
                            >
                                <Marker
                                    title={name}
                                    name={name}
                                    zoom={15}
                                    position={{lat: lat, lng: long}} />
                                <InfoWindow >
                                    <div>
                                        <h1>{"test"}</h1>
                                    </div>
                                </InfoWindow>
                        </Map>
                    
                    
                    </Col>
                </div>
            </Modal.Body>
        </Modal>
    )
})

export default (
    GoogleApiWrapper({
        apiKey: ("AIzaSyAig1H6xWKyyS5J6NeADJhi8YR2A2N6akI")
    })(ModalLocationMap)
);
