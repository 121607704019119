import React,  { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import {Button, Modal, Col, FlexboxGrid, FormGroup, ControlLabel, Alert } from 'rsuite';
import { withTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';

//class ModalChangePassword extends Component{
const ModalChangePassword = forwardRef((props , ref) => {
    const {t} = props.t;
    const [show, setShow] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')

	useImperativeHandle(ref, ()=>({
        handleShow,
        //GoogleApiWrapper
    }));

	const handleShow = async() => {
        //await this.setState({show:true});
        await setShow(true);
	}

	const handleClose = () => {
        setShow(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('')
		
    }

    const handleChange = (e) => {
		/*let	value = e.target.value;
        let name = e.target !== undefined ? e.target.name : e;
        
        this.setState({[name]: value});	*/
    }

    const save = async() => {
        if(currentPassword === "" || newPassword === "" || confirmPassword === ""){	
            Alert.error(props.t('error_alert.incomplete_fields'));
            return;
        }

        if(newPassword !== confirmPassword){
            Alert.error(props.t('error_alert.password_no_match'));
            return;
        }
        var obj = {
            email:props.email,
            newPassword:newPassword,
            oldPassword:currentPassword
        }
        console.log(obj);
        console.log("Saving...");
        var req = {
            api:'auth',
            method:'POST',
            url:'auth/force-change-password',
            obj:obj,
            modal:props.modal_loader,
            message_modal:props.t('modal.spin.saving'),
            requireToken:true
        }
        //console.log(obj);
        //return
        var response = await props.fetchRequest(req);
        if(response !== null){
            if(response.action === "SELECT_ACCOUNT"){
                //console.log(response);
                props.OpenModalSelectAccount(props.email)
            } else {
                props.initSession(response);
            }
        }
        handleClose();
    }

	
    return (
        <Modal backdrop={false} show={show} size="md">
            <Modal.Header>	
                <Modal.Title>{props.t('screen.login.change_password')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexboxGrid >
                    <Col xs={18} >
                        <FormGroup>
                            <ControlLabel>{props.t('screen.login.current_password')}</ControlLabel> <span style={{color:'red'}}>*</span>
                            <input type="password" className="form-control form-control-sm"
                                value={currentPassword} required
                                name="currentPassword"
                                onChange={(e)=>{
                                    //this.handleChange(e)
                                    setCurrentPassword(e.target.value)
                                }} 
                            />
                        </FormGroup>	
                    </Col>
                    <Col xs={18} style={{paddingTop:'10px'}}>
                        <FormGroup>
                            <ControlLabel>{props.t('screen.login.new_password')}</ControlLabel> <span style={{color:'red'}}>*</span>
                            <input type="password" className="form-control form-control-sm"
                                value={newPassword} required
                                name="newPassword"
                                onChange={(e)=>{
                                    //this.handleChange(e)
                                    setNewPassword(e.target.value)
                                }} 
                            />
                            <PasswordStrengthBar
                            password={newPassword}
                            minLenght={8}
                            scoreWords={[
                                <ControlLabel>{props.t('screen.passwords.very_weak')}</ControlLabel>,
                                <ControlLabel>{props.t('screen.passwords.weak')}</ControlLabel>,
                                <ControlLabel>{props.t('screen.passwords.good')}</ControlLabel>,
                                <ControlLabel>{props.t('screen.passwords.very_good')}</ControlLabel>,
                                <ControlLabel>{props.t('screen.passwords.strong')}</ControlLabel>
                            ]}
                            shortScoreWord={<ControlLabel>{props.t('screen.passwords.small')}</ControlLabel>}
                            />
                        </FormGroup>	
                    </Col>
                    <Col xs={18} style={{paddingTop:'10px'}}>
                        <FormGroup>
                            <ControlLabel>{props.t('screen.login.confirm_password')}</ControlLabel> <span style={{color:'red'}}>*</span>
                            <input type="password" className="form-control form-control-sm"
                                value={confirmPassword} required
                                name="confirmPassword"
                                onChange={(e)=>{
                                    //this.handleChange(e)
                                    setConfirmPassword(e.target.value)
                                }} 
                            />
                        </FormGroup>	
                    </Col>
                </FlexboxGrid>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" onClick={save}>{props.t('button.add')}</Button>
                <Button appearance="default" onClick={handleClose}>{props.t('button.cancel')}</Button>
            </Modal.Footer>
        </Modal>
    );
	

})

export default ModalChangePassword;