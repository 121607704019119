import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import {FlexboxGrid, Col, Button, Grid} from 'rsuite';

import Layout from '../..//components/Layout';
import FreePlan from './FreePlan';
import BasicPlan from './BasicPlan';
import PremiumPlan from './PremiumPlan';
import PaymentPlanCard from './PaymentPlanCard';
import SubscriptionData from '../settings/account/SubscriptionData';
import Unsuscribe from '../settings/account/Unsuscribe';

import Swal from 'sweetalert2';
import { swalAction } from '../../lib/functions';
import DeleteAccount from './DeleteAccount';

class Plans extends Component{
	constructor(props){
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.changePlan = this.changePlan.bind(this);
		this.updatePlan = this.updatePlan.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.changeSubscription = this.changeSubscription.bind(this);
		this.cancelSubscription = this.cancelSubscription.bind(this);
		this.confirmCancelSubscription = this.confirmCancelSubscription.bind(this);
		this.onPayment = this.onPayment.bind(this);
		this.getMethods = this.getMethods.bind(this);
		this.changeDefaultMethod = this.changeDefaultMethod.bind(this);
        this.deleteMethod = this.deleteMethod.bind(this);
		
		this.state = {
			free:false,
			basic:false,
			premium:false,
			suscription:"",
			locations:0,
			employees:0,
			methods:[],
		}
	}

	async componentDidMount(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

		await this.getMethods();
		await this.setState({suscription:sessionStorage.getItem("suscription")});

		switch(this.state.suscription){
			case "free":
				this.setState({free:true});
			break;
			case "Basic Account":
				this.setState({basic:true});
			break;
			case "Premium Account":
				this.setState({premium:true});
			break;
			default:
				break;
		}		

		var req = await this.props.fetchRequest({
			api:'accounts',
			method:'GET',
			url:'accounts/counters-by-account/'+accountId,
			requireToken:true
		});

		if(req !== undefined){
			this.setState({
				employees: req.employees,
				locations: req.locations
			});
		}

		
	}

	async getMethods(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var response = await this.props.fetchRequest({
            api:'payments',
            url:'payment-methods/by-account-id/'+accountId,
            requireToken:true
        });

        if(response !== undefined){
            this.setState({methods: response});
        }
	}
	
	async changeDefaultMethod(id){
        var response = await this.props.fetchRequest({
            method: 'POST',
            api: 'payments',
            url: 'payment-methods/change-default-payment/'+id,
            requireToken: true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading')
        });

        if(response !== undefined){
            this.setState({methods: response});
        }
    }

    async deleteMethod(id){
        var response = await this.props.fetchRequest({
            method: 'DELETE',
            api: 'payments',
            url: 'payment-methods/'+id,
            requireToken: true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading')
        });

        if(response !== undefined){
            this.setState({methods: response});
        }
    }

	handleChange(e){
	}

	changePlan(panel){
		if(panel === 'free'){
			this.setState({
				free:true,
				basic:false,
				premium:false
			});
		}else if(panel === 'basic'){
			this.setState({
				free:false,
				basic:true,
				premium:false
			});
		}else if(panel === 'premium'){
			this.setState({
				free:false,
				basic:false,
				premium:true
			});
		}
	}

	updatePlan(){
		var error = '';
		var msg = '';
		if((this.state.premium && this.state.suscription === 'Premium Account') ||
			(this.state.basic && this.state.suscription === 'Basic Account') ||
			(this.state.free && this.state.suscription === 'free') ){
			error = this.props.t('error_alert.error_change_plan');
		}

		if(error === ""){
			if(this.state.suscription === 'Premium Account'){
				if(this.state.basic){
					if(this.state.locations > 10){
						Swal.fire({
							title:this.props.t('modal.alert.titles.alert'),
							text:this.props.t('modal.alert.messages.plan_limit_basic_location'),
							showCancelButton:true,
							showConfirmButton: true,
							confirmButtonText:this.props.t('modal.alert.confirms.go_locations'),
							cancelButtonText:this.props.t('modal.alert.confirms.cancel'),
							icon:'error'
						}).then((result)=>{
							if(result.isConfirmed){
								this.props.history.push('/settings/locations');
							}
						})
					}else{
						this.onUpdate('basic');
					}
				}
	
				if(this.state.free){
	
					if(this.state.location > 1 && this.state.employees > 5){
						msg += this.props.t('modal.alert.messages.plan_limit_free_location_employee');
					}
	
					if(this.state.locations > 1 && this.state.employees <= 5){
						msg += this.props.t('modal.alert.messages.plan_limit_free_location');
					}
	
					if(this.state.locations <= 1 && this.state.employees > 5){
						msg += this.props.t('modal.alert.messages.plan_limit_free_employee');
					}
	
					if(msg !== ""){
						Swal.fire({
							title:this.props.t('modal.alert.titles.alert'),
							text:msg,
							showCancelButton:true,
							showDenyButton:this.state.employees > 5,
							showConfirmButton: this.state.locations > 1,
							confirmButtonText:this.props.t('modal.alert.confirms.go_locations'),
							denyButtonText:this.props.t('modal.alert.confirms.go_employees'),
							cancelButtonText:this.props.t('modal.alert.confirms.cancel'),
							icon:'error'
						}).then((result)=>{
							if(result.isConfirmed){
								this.props.history.push('/settings/locations');
							}
		
							if(result.isDenied){
								this.props.history.push('/settings/employee');
							}
						})
					}else{
						this.onUpdate('free');
					}
					
				}				
			}else if(this.state.suscription === "Basic Account"){
				if(this.state.free){
	
					if(this.state.location > 1 && this.state.employees > 5){
						msg += this.props.t('modal.alert.messages.plan_limit_free_location_employee');
					}
	
					if(this.state.locations > 1 && this.state.employees <= 5){
						msg += this.props.t('modal.alert.messages.plan_limit_free_location');
					}
	
					if(this.state.locations <= 1 && this.state.employees > 5){
						msg += this.props.t('modal.alert.messages.plan_limit_free_employee');
					}
	
					if(msg !== ""){
						Swal.fire({
							title:this.props.t('modal.alert.titles.alert'),
							text:msg,
							showCancelButton:true,
							showDenyButton:this.state.employees > 5,
							showConfirmButton: this.state.locations > 1,
							confirmButtonText:this.props.t('modal.alert.confirms.go_locations'),
							denyButtonText:this.props.t('modal.alert.confirms.go_employees'),
							cancelButtonText:this.props.t('modal.alert.confirms.cancel'),
							icon:'error'
						}).then((result)=>{
							if(result.isConfirmed){
								this.props.history.push('/settings/locations');
							}
		
							if(result.isDenied){
								this.props.history.push('/settings/employee');
							}
						})
					}else{
						this.onUpdate('free');
					}
				}

				if(this.state.premium){
					this.onUpdate('premium');
				}
			}else{
				if(this.state.free){
					this.onUpdate('basic');
				}

				if(this.state.premium){
					this.onUpdate('premium');
				}
			}
		}else{
			Swal.fire(this.props.t('modal.alert.titles.alert'), error, 'error');
		}
		
	}

	onUpdate(type){
		
		if(!this.state.free){
			var obj = {
				title           : this.props.t('modal.alert.titles.continue'),
				text            : this.props.t('modal.alert.messages.update_plan'),
				icon            : 'warning',
				showConfirm     : true,
				showCancel      : true,
				textConfirm     : this.props.t('modal.alert.confirms.continue'),
				colorConfirm    : '#3085d6',
				colorCancel     : '#d33',
				textcancel      : this.props.t('modal.alert.confirms.cancel'),
				values          : type,
				fn              : this.changeSubscription
			}
			swalAction(obj);
		}else{
			this.onPayment(type);
			
		}
		
	}

	async changeSubscription(type){
		var req = await this.props.fetchRequest({
			api:'payments',
			method:'POST',
			obj:{subscriptionType: type},
			url:'subscription/change-subscription-type',
			requireToken:true,
			modal:this.props.modal_loader,
			message_modal:this.props.t('modal.spin.loading')
		});

		if(req !== undefined){
			sessionStorage.setItem('suscription', req.subscriptionType);
			window.location.reload();
		}
	}

	confirmCancelSubscription(){
        var obj = {
            title           : this.props.t('modal.alert.titles.unsuscribe'),
            text            : this.props.t('modal.alert.messages.remove_subscription'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.yes'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.no'),
            values          : '',
            fn              : ()=>{this.cancelSubscription()}
        }
        
        swalAction(obj);  
    }

    async cancelSubscription(){
        let req = {
            api:'payments',
            method:'POST',
            url:'subscription/cancel',
            requireToken:true
        };
        let response =  await this.props.fetchRequest(req);
        if(response !== null&& response !== undefined){
            sessionStorage.setItem("suscription", response.subscriptionType);
            window.location.reload();
        }
	}
	
	onPayment(type){
		var obj = {
			title           : this.props.t('modal.alert.titles.continue'),
			text            : this.props.t('modal.alert.messages.inmediate_payment'),
			icon            : 'warning',
			showConfirm     : true,
			showCancel      : true,
			textConfirm     : this.props.t('modal.alert.confirms.continue'),
			colorConfirm    : '#3085d6',
			colorCancel     : '#d33',
			textcancel      : this.props.t('modal.alert.confirms.cancel'),
			values          : type,
			fn              : this.changeSubscription
		}
		swalAction(obj);
	}

	render(){
		const {t} = this.props;

		return (
		    <Layout {...this.props}>
		    	<h3 className="s-title-page">{t('screen.plans.title')}</h3>
                <FlexboxGrid justify="center" className="form-group">
					<Col xs={24} md={23} lg={this.state.suscription === 'free' ? 22 : 18}>
						{this.state.suscription !== 'free' &&
							<Grid fluid className="form-group">
								<Col xs={24} style={{textAlign:'right'}}>
									<Button color="blue" onClick={()=>this.updatePlan()}>{t('button.update')}</Button>
								</Col>
							</Grid>
						}
						<Grid fluid className="form-group">
							<Col xs={24} sm={12} 
								lg={(this.state.suscription === 'free' && this.state.methods.length === 0) ? 6 : 8} 
								className="s-content form-group" >
								<FreePlan {...this.props} free={this.state.free} changePlan={this.changePlan} />
							</Col>
							<Col xs={24} sm={12} 
								lg={(this.state.suscription === 'free' && this.state.methods.length === 0) ? 6 : 8} 
								className="s-content form-group" >
								<BasicPlan {...this.props} basic={this.state.basic}  changePlan={this.changePlan} />
							</Col>
							<Col xs={24} sm={12} 
								lg={(this.state.suscription === 'free' && this.state.methods.length === 0) ? 6 : 8} 
								className="s-content form-group" >
								<PremiumPlan {...this.props} premium={this.state.premium} changePlan={this.changePlan}/>
							</Col>
							{(this.state.suscription === "free" && this.state.methods.length === 0) &&
								<Col xs={24} sm={12} lg={6} className="s-content form-group">
									<PaymentPlanCard {...this.props} state={this.state}/>
								</Col>
							}
						</Grid>
						{(this.state.suscription !== 'free' || this.state.methods.length > 0) &&
							<Grid fluid>
								<Col xs={24} className="s-content form-group">
									<SubscriptionData {...this.props} 
										methods={this.state.methods}
										getMethods={this.getMethods}
										deleteMethod={this.deleteMethod}
										changeDefaultMethod={this.changeDefaultMethod}
										onRef={ref => this.subscription_data = ref} 
										suscription={this.state.suscription} />									
								</Col>
							</Grid>
						}			

						<Grid fluid>
							{this.state.suscription !== 'free' &&
								<Col xs={24} className="form-group">
									<Unsuscribe {...this.props} confirmCancelSubscription={this.confirmCancelSubscription} />
								</Col>
							}
							<Col xs={24}>
								<DeleteAccount {...this.props} onRef={ref => this.delete_account = ref} />
							</Col>
						</Grid>			
					</Col>

                </FlexboxGrid>
		    </Layout>
		)
	}
}

export default withTranslation('translations')(Plans)