import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';

import {Button, Modal, Col, FlexboxGrid, FormGroup, ControlLabel, Alert } from 'rsuite';
import { withTranslation } from 'react-i18next';
import validator from 'validator';

//class ModalAccountUser extends Component{
const ModalAccountContractors = forwardRef((props , ref) => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const t = props.t;

    useImperativeHandle(ref, ()=>({
        handleShow,
    }));

    const handleShow = (roleId) => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false);
        setName('');
        
    }

    const save = async() => {
        if(name !== "" || name!== ''){	

            var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
            accountId = accountId.substring(1, accountId.length - 1);
            

            var obj = {
                type: 'contractor',
                key:(name.replace(/ /g,'_')).toLowerCase(),
                value: name,
                accountId: accountId,
            };

            //console.log(obj)
            //return
            var req = {
                api:'accounts',
                method:'POST',
                url:'lookups',
                obj:obj,
                modal:props.modal_loader,
                message_modal:props.t('modal.spin.saving'),
                requireToken:true
            }
            
            var response = await props.fetchRequest(req);
            if(response !== null){
                
                props.GetContractors();
            }
            handleClose();
        }else{
            Alert.error(props.t('error_alert.incomplete_fields'));
        }
    }   

    return (
        <Modal backdrop={false} show={show} size="sm">
            <Modal.Header>	
                <Modal.Title>{props.t('screen.settings.account.contractors.modal_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexboxGrid >
                    <Col xs={24} >
                        <FormGroup>
                            <ControlLabel>{props.t('screen.settings.account.users.contractor_name')}</ControlLabel> <span style={{color:'red'}}>*</span>
                            
                            <input type="text" className="form-control form-control-sm"
                                value={name} required
                                name="name"
                                onChange={(e)=>{
                                    setName(e.target.value)
                                }} 
                            />
                        </FormGroup>
                    </Col>
                </FlexboxGrid>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" onClick={save}>{t('button.add')}</Button>
                <Button appearance="default" onClick={handleClose}>{t('button.cancel')}</Button>
            </Modal.Footer>
        </Modal>
    );
    
})

export default ModalAccountContractors;