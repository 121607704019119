import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, Col, Grid, ControlLabel, Button, Alert } from 'rsuite';

//const ModalLocationMap = (props) => {
const ModalSelectAccount = forwardRef((props , ref) => {
    
    const [show, setShow] = useState(false);
    const [listAccounts, setListAccounts] = useState([]);
    const [accountId, setAccountId] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useImperativeHandle(ref, ()=>({
        handleShow,
        //GoogleApiWrapper
    }));

    /*useEffect(() => {
        //console.log(props)
        if(props.open===true){
            
            setShow(true)
        }
        
    }, []);*/

    const handleShow = (email, password) => {
        
        //console.log(props)
        setEmail(email);
        setPassword(password)
        GetAccounts(email)
        setShow(true);
        //this.setState({lat:lat, long:lng, name:name, show:true});
    }

    const GetAccounts = async(email) => {
        let body = {
            email:email
        }
        let req = {
            api:'auth',
            method:'POST',
            url:'auth/accounts-by-user',
            obj:body,
            modal:props.modal_loader,
            message_modal:props.t('modal.spin.start')
        }
        let response = await props.fetchRequest(req);
        //console.log(response)
        if (response) {
            setListAccounts(response)
        }
    }

    const save = async () => {
        if (!accountId) {
            Alert.error(props.t('error_alert.incomplete_fields'));
            return
        }

        let body={
            email:email,
            password:password
        }

        let req = {
            api:'auth',
            method:'POST',
            url:'auth/login-by-account/'+accountId,
            obj:body,
            modal:props.modal_loader,
            message_modal:props.t('modal.spin.start')
        }
        let response = await props.fetchRequest(req);
        //console.log(response)
        if (response) {
            //if(response.action === "CONTINUE"){
                //console.log(response);
                props.initSession(response);
            /*} else {
                props.OpenModalSelectAccount()
            }*/
            
            setShow(false);
        }
    }

    const handleClose = () => {
        //props.close();
        setShow(false);
        //this.setState({show: false, lat:"", long:"", name:""});
    }

    return(
        <Modal size="md" show={show} onHide={handleClose} >
            <Modal.Header>{props.t('screen.modal_accounts.title')}</Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <Grid fluid>
                            
                        <Col xs={24} >
                                    <ControlLabel>{props.t('screen.modal_accounts.account')}</ControlLabel>
                                    
                                    <select className="form-control form-control-sm" 
                                        name='account'
                                        required
                                        value={accountId}
                                        onChange={(e)=> {
                                            //console.log(e.target.value)
                                            setAccountId(e.target.value)
                                        } }
                                    >
                                        <option value="">{props.t('general.select')}</option>
                                        {listAccounts.map((e, j)=>
                                            <option value={e.id} key={e.id}>{e.company_name}</option>                                            
                                        )}
                                    </select>
                                    
                        </Col>
                    </Grid>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="default" onClick={handleClose}>{props.t('button.cancel')}</Button>
                <Button appearance="primary" onClick={save}>{props.t('button.sign_in')}</Button>
                
            </Modal.Footer>
        </Modal>
    )
})

export default ModalSelectAccount;
