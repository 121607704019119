import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { Container, FlexboxGrid, Grid, Col } from 'rsuite';
import moment from 'moment';


import Layout from '../../../components/Layout';
import TableList from '../../../components/TableList';
import ModalEvents from '../../../components/settings/ModalEvents';
import { swalAction } from '../../../lib/functions';

class Events extends Component{
    constructor(props){
        super(props);

        this.newEvent = this.newEvent.bind(this);
        this.getList = this.getList.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.updateList = this.updateList.bind(this);

        this.state = {
            columns:[
                {title:'type', name:'type', visible:true, width:'15%'},
                {title:'employee', name:'employee', visible:true, width:'55%'},
                {title:'start_date', name:'start_date', visible:true, width:'15%'},
                {title:'end_date', name:'end_date', visible:true, width:'15%'},
                {title:'actions', name:'actions', visible:true, width:'150px'}
            ],
            data:[],
            actions:[
                {
                    btn:true,
                    icon:'trash-o',
                    title:this.props.t('general.table.actions.delete'),
                    clickFn:'delete',
                    action: this.deleteEvent
                }
            ],

            employees:[],
            original_employees:[],
            events:[],
        }
    }

    async componentDidMount(){
        var columnsEvents = sessionStorage.getItem('columnsEvents');
        if(columnsEvents === null){
            sessionStorage.setItem('columnsEvents', JSON.stringify(this.state.columns));
        }else{
            this.setState({columns: JSON.parse(columnsEvents)});
        }
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            url:'employees/by-account/'+accountId,
            method:'GET',
            requireToken:true
        };
        var employees = await this.props.fetchRequest(req);

        if(employees === undefined){
            this.props.closeSesion();
            return;
        }

        var data = [];
        employees.map((e)=>(
            data.push(e.firstName+' '+e.lastName+' '+e.maternalSurname)
        ));

        req.url = 'lookups?type=employee_event_type';
        var events = await this.props.fetchRequest(req);


        this.setState({employees: data, original_employees:employees, events: events});

        this.getList();
        
        //this.table.setTable();
    }

    async getList(){
        var events = await this.props.fetchRequest({
            api:'accounts',
            method:'GET',
            url:'employee_event',
            requireToken:true,
            modal:this.props.modal_loader,
            message_modal: 'Cargando...'
        });

        if(events === undefined){
            this.props.closeSesion();
            return;
        }else{
            var list = [];

            var events_list = this.state.events;
            
            events.map((e)=>{
                var index = events_list.findIndex(obj => obj.key === e.eventType);
                
                return list.push([
                    events_list[index].value,
                    e.employee.firstName+' '+e.employee.lastName+' '+e.employee.maternalSurname,
                    moment(e.startDate).format('DD/MM/YYYY HH:mm'),
                    moment(e.endDate).format('DD/MM/YYYY HH:mm'),
                    e.id
                ]);
            });

            this.setState({data: list});

            setTimeout(()=>{
                this.table.setTable();
            }, 700);
        }

    }

    updateList(){
        setTimeout(()=>{
            this.table.resetTable();
            this.getList();
        }, 500)
    }

    updateColumns(e, i){
        var columns = this.state.columns;

        columns[i].visible = e.target.checked;

        sessionStorage.setItem('columnsEvents', JSON.stringify(columns));

        this.setState({columns: columns});
    }

    newEvent(){
        this.modal_events.handleShow();
    }

    deleteEvent(e, id){
        var obj = {
            title           : this.props.t('modal.alert.titles.alert'),
            text            : this.props.t('modal.alert.messages.delete_event'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.delete'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.cancel'),
            values          : id,
            fn              : this.onDelete
        }

        swalAction(obj);        
    }

    onDelete(id){
        var req = {
            api:'accounts',
            method:'DELETE',
            url:'employee_event/'+id,
            requireToken:true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.deleting'),
        }

        this.props.fetchRequest(req);

        this.updateList();
    }

    render(){
        const {t} = this.props;
        return(
            <Layout {...this.props}>
                <h3 className="s-title-page">{t('screen.settings.events.list.title')}</h3>
                <Container>
                    <FlexboxGrid justify="center">
                        <Grid fluid>
                            <Col xs={24}>
                                <TableList 
                                    {...this.props}
                                    columns={this.state.columns}
                                    btnNew={true}
                                    data={this.state.data}
                                    actions={this.state.actions}
                                    updateColumns={this.updateColumns}
                                    new={this.newEvent}
                                    actionIcon={this.actionIcon}
                                    openModal={this.openModalPicture}
                                    changeList={this.changeList}
                                    screen={"events"}
                                    report={false}
                                    scroll={true}
                                    onRef={ref => this.table = ref} 
                                />
                            </Col>
                        </Grid>
                    </FlexboxGrid>
                </Container>
            
                <ModalEvents {...this.props} 
                    events={this.state.events}
                    original_employees={this.state.original_employees}
                    employees={this.state.employees}
                    updateList={this.updateList}
                    onRef={ref => this.modal_events = ref} /> 
            </Layout>
        )
    }
}

export default withTranslation('translations') (Events);