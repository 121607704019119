import React, { useEffect, useState, useRef } from 'react';
import {Panel, Grid, Icon, Col, IconButton} from 'rsuite';
import {Table} from 'react-bootstrap';
//import ModalAccountUser from './ModalAccountUser';
import { swalAction } from '../../../lib/functions';

import ModalAccountContractors from './ModalAccountContractors';

//class AccountUsers extends Component{
const AccountContractors = (props)=>{
    const {t} = props;
    const [listContractors, setListContractors] = useState([]);
    const modal_contractors_ref = useRef(null);
    
    useEffect(() => {
        GetContractors();
    }, []);

    const openModal = () => {
        //this.modal_account_users.handleShow();
        modal_contractors_ref.current.handleShow()
    }    

    const GetContractors = async() => {
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            method:'GET',
            url:'lookups/by-account-and-type/'+accountId+'/contractor',
            requireToken:true
        }
        let response =  await props.fetchRequest(req);

        //console.log(response)
        if(response !== null&& response !== undefined){
            setListContractors(response)
        }
    }

    const onDelete = async(id) => {
        //console.log(roleId);
        var obj = {
            title           : "Esta acción eliminará el contratista",
            text            : "¿Deseas continuar?",                                
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : t('modal.alert.confirms.yes'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : t('modal.alert.confirms.no'),
            values          : id,
            fn              : deleteRole
        };
        swalAction(obj);
    }

    const deleteRole = async(id) => {
        var req = await props.fetchRequest({
            api:'accounts',
            method:'DELETE',
            url:'lookups/'+id,
            requireToken:true,
            modal:props.modal_loader,
            message_modal:t('modal.spin.deleting')
        });
        
        await GetContractors();
    }

    
    return (
        <Panel shaded>
            <i class="rs-panel-heading" style={{padding:"0px"}}> 
                {t('screen.settings.account.contractors.title')}
            </i>
            <Grid fluid className="form-group">
                <Col xs={4}>
                    <IconButton className="s-primary-btn" 
                        icon={<Icon icon="plus" />}
                        circle
                        size="sm"
                        onClick={()=>openModal()}
                    />
                </Col>
                <Table responsive hover>
                    <thead>
                        <tr>
                            <th>{t('screen.settings.account.users.name')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listContractors.map((u, i)=>(
                            <tr key={i}>
                                <td>{ u.value}</td>
                                
                                <td style={{width:'5%'}} align='right'>
                                    {u.type !== "owner"?
                                        <Icon icon="trash" size="lg" 
                                            onClick={()=>onDelete(u.id)}
                                            title={t('button.delete')}	/>
                                    :null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Grid>
            <ModalAccountContractors 
                ref={modal_contractors_ref}
                GetContractors={GetContractors} 
                t={t}
                fetchRequest={props.fetchRequest}
            />
        </Panel>
    )
    
}

export default AccountContractors;