import React, { Component } from 'react';
import FormIncidentRules from '../account/FormIncidentRules';
import { swalAction } from '../../../lib/functions';

class IncidentRules extends Component{
    constructor(props){
        super(props);

        this.selectIncident = this.selectIncident.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.newRule = this.newRule.bind(this);
        this.editRule = this.editRule.bind(this);

        this.state = {
            incident:{
                name:"",
                label:"",
                supportDelays:"No",
                minutesDelays:"",
                numDelays:"",
                supportExtraHr:"No",
                maxExtraHr:"",
                supportEconomicDay:"No",
                maxEconomicDay:""
            },
            incident_name:"",
            incidents:[],
            disable_fields:true,
            select_name: true
        }
    }

    async componentDidMount(){
        this.props.onRef(this);
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var request = await this.props.fetchRequest({
            api:'accounts', 
            method:'GET', 
            url:'accounts/get-incident-rules-by-account/'+accountId, 
            requireToken:true
        });

        var incidents = [];
        request.map((inc)=>{
            return incidents.push({
                name:inc.name,
                label:inc.name.toLowerCase().replace(' ', ''),
                supportDelays:inc.enableDelays ? 'Si' : 'No',
                minutesDelays: inc.allowedDelayMinutes,
                numDelays: inc.delaysToAbsence,
                supportExtraHr: inc.enableOvertime ? 'Si' : 'No',
                maxExtraHr: inc.overtimeMax,
                supportEconomicDay: inc.enableEconomicDays === 0 ? 'No' : 'Si',
                maxEconomicDay: inc.economicDaysMax
            });
        });
        this.setState({incidents: incidents});
        if(this.props.wizard){
            
            if(incidents.length > 0){
                this.setState({select_name: true, edit_schedule: true, disable_fields:false});
                this.props.handleChange({target:{value: incidents[0].name, name:'incident_name'}});
                this.selectIncident({target:{value: incidents[0].name }})
                
                
            }else{
                this.setState({select_name: false, edit_schedule: true, disable_fields:false});
                this.props.handleChange(true, {target:{name:'save_incident', value: true}});
            }
            
        }else{
            this.setState({select_name: true, edit_schedule: true});
        }

        
    }


    selectIncident(e){
        var index = this.state.incidents.findIndex(obj => obj.name === e.target.value);
        var incident = {
            name:"",
            label:"",
            supportDelays:"No",
            minutesDelays:"",
            numDelays:"",
            supportExtraHr:"No",
            maxExtraHr:"",
            supportEconomicDay:"No",
            maxEconomicDay:""
        }

        this.setState({
            incident: index !== -1 ? this.state.incidents[index] : incident,
            incident_name: e.target.value,
            disable_fields: index !== -1 ? false : true
         });
    }

    handleChange(e){
        this.setState({
            incident:{
                ...this.state.incident,
                [e.target.name]: e.target.value
            }
        });

        if(this.props.wizard){
            this.props.changeColorNext();
        }
        
    }

    newRule(e){
        if(sessionStorage.getItem('suscription') === 'free' && this.state.incidents.length >= 1){
            this.verifySuscription();
        }else{
            this.setState({select_name: false, disable_fields:false});
        
            if(this.props.details === undefined){
                this.props.handleChange(true, {target:{name:'save_incident', value: true}});
            }
            
            
            if(this.props.wizard){
                this.props.changeColorNext();    
            }
        }

        
    }

    verifySuscription(){
        var obj = {
            title           : this.props.t('modal.alert.titles.suscription'),
            text            : this.props.t('modal.alert.messages.update_suscription'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.go_plans'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.cancel'),
            values          : '',
            fn              : ()=>{this.props.history.push('/settings/account')}
        }
        
        swalAction(obj);  
    }

    editRule(){
        
        if(!this.state.disable_fields){
            this.props.onUpdate(false);
        }

        this.setState({disable_fields: !this.state.disable_fields});

    }

    render(){
        return( 
            <form>
                <FormIncidentRules 
                    t={this.props.t} 
                    incident_name={this.props.incident_name}
                    incident={this.state.incident} 
                    incidents={this.state.incidents}
                    selectIncident={this.props.handleChange}
                    select_name={this.state.select_name}
                    disable_fields={this.state.disable_fields}
                    handleChange={this.handleChange}
                    show_name={true}
                    details={this.props.details}
                    newRule={this.newRule}
                    editRule={this.editRule}
                />
            </form>
        )
    }
    
}
export default IncidentRules