import React, { useEffect, useState, useRef } from 'react';
import {withTranslation} from 'react-i18next';
import { useTranslation } from 'react-i18next';
import {
	FlexboxGrid, Col, InputGroup, Icon, Button, Input, Alert
} from 'rsuite';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { swalAction } from '../lib/functions';
import ModalChangePassword from './ModalChangePassword';
import ModalSelectAccount from '../components/ModalSelectAccount';

//class Login extends Component{
const Login = (props) => {
	const { t } = useTranslation("translations");
	const modal_change_password = useRef(null);
	const modal_select_account = useRef(null);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const parseJwt = (token) => {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

	const handleChange = (val, e) => {
		/*this.setState({[e.target.name]: e.target.value});


		if(e.target.value !== ""){
			var control = document.getElementById(e.target.name);
			if(control !== null){
				if(control.required && control.className.includes('error')){
		        	control.classList.remove('error');
		    	}
			}
		}*/
	}

	const onSubmit = async() =>{
		if(props.isValidForm('form')){
			let obj = {
				email: email,
				password: password
			}

			let req = {
	  			api:'auth',
	  			method:'POST',
	  			url:'auth/login',
	  			obj:obj,
	  			modal:props.modal_loader,
	  			message_modal:props.t('modal.spin.start')
	  		}
			let response = await props.fetchRequest(req);
			
			if(response !== undefined){
				//console.log(response);
                if(response.action === "CONTINUE"){
					//console.log(response);
                    await initSession(response);
                }else if (response.action === 'SELECT_ACCOUNT') {
					OpenModalSelectAccount(email)
				} else {
                    //modal_change_password.handleShow();
					//console.log(modal_change_password)
					OpenModalChangePassword();
                }
			}
		}else{
			Alert.error(props.t('error_alert.incomplete_fields'));
		}
	}

	const OpenModalChangePassword = () => {
		modal_change_password.current.handleShow();
	}

	const OpenModalSelectAccount = (email) => {
		//console.log(modal_select_account)
		modal_select_account.current.handleShow(email, password)
	}

    const initSession = async (loginResponse) => {//console.log(loginResponse)
        sessionStorage.setItem('token', loginResponse.token);
                    
        if(loginResponse.accountExist){
            var lang = await props.fetchRequest({
                api:'accounts',
                method:'GET',
                url:'accounts/language-by-account/'+loginResponse.accountId,
                requireToken:true
            })

			if (lang) {
				sessionStorage.setItem(btoa("lang"), btoa(lang.language));
			}
            
        }
        
        sessionStorage.setItem('hasAccount', loginResponse.accountExist);

        sessionStorage.setItem(btoa("account_id"), btoa(JSON.stringify(loginResponse.accountId)));
        sessionStorage.setItem(btoa("lead_id"), btoa(loginResponse.leadId));
        sessionStorage.setItem('canceled', loginResponse.canceled);
        sessionStorage.setItem(btoa('sys-leadName'), btoa('nl-'+loginResponse.leadName))
        sessionStorage.setItem("userEmail",email);
		sessionStorage.setItem("accountName",loginResponse.accountName);
        sessionStorage.setItem('suscription', loginResponse.suscriptionType);
		sessionStorage.setItem('userType', loginResponse.userType);
		sessionStorage.setItem('locationId', loginResponse.locationId);
		sessionStorage.setItem('contractorId', loginResponse.contractorId);
        
        if(loginResponse.canceled){
            var obj = {
                title           : props.t('modal.alert.titles.continue'),
                text            : props.t('modal.alert.messages.canceled_account'),
                icon            : 'warning',
                showConfirm     : true,
                showCancel      : true,
                textConfirm     : props.t('modal.alert.confirms.continue'),
                colorConfirm    : '#3085d6',
                colorCancel     : '#d33',
                textcancel      : props.t('modal.alert.confirms.no'),
                values          : '',
                fn              : ()=>{props.history.push('/subscription-plans');}
            };
            swalAction(obj);
        }
        if(!loginResponse.accountConfigured){
            props.history.push('/wizard');
        }else{
            props.history.push('/');							
        }
    }

	return (
		<FlexboxGrid justify="center">
			<Col xs={24} sm={16} md={12} lg={10} >
				<Image src="images/login/login2.png" style={{width:'100%'}}/>
				<div className="login-form">
					<div>
						<h2>{t('screen.login.title')}</h2>               
					</div>
					<form>
						<div style={{padding:'15px'}}>
							<FlexboxGrid justify="center" className="form-group">    
								<Col xs={22} sm={20} md={14} lg={14}>
									<InputGroup inside style={{overflow: 'unset'}}>
										<Input 
											placeholder={t('screen.login.email')}
											size="sm"
											name="email" 
											id="email"
											value={email} 
											onChange={(e)=>{
												//console.log(e)
												setEmail(e)

											}}
											required
										/>
										<InputGroup.Addon>
											<Icon icon="envelope-o" />
										</InputGroup.Addon>
									</InputGroup>
								</Col>
							</FlexboxGrid>
							<FlexboxGrid justify="center" className="form-group">
								<Col xs={22} sm={20} md={14} lg={14}>
									<InputGroup inside style={{overflow: 'unset'}}>
										<Input 
											placeholder={t('screen.login.password')}
											size="sm"
											name="password" 
											id="password"
											type="password"
											value={password} 
											onChange={(e)=>{
												//console.log(e)
												setPassword(e)
											}}
											required
											onPressEnter={onSubmit}
										/>
										<InputGroup.Addon>
											<Icon icon="lock" />
										</InputGroup.Addon>
									</InputGroup>
								</Col>
							</FlexboxGrid>
						</div>
					</form>
					<div style={{paddingBottom:'15px'}}>
						<FlexboxGrid justify="center">
							<Col xs={22} sm={20} md={14} lg={14}>
								<Col xs={24} sm={17} md={17} lg={18} className="form-group">
									<Link onClick={(e)=>{e.preventDefault(); return props.history.push('/recover')}}
										to=""
									>
										{t('screen.login.forgot_password')}
									</Link>
								</Col>
								<Col xs={24} sm={7} md={7} lg={6} style={{textAlign:'right'}}>
									<Button style={{width:'100%'}} color="blue" size="sm" onClick={onSubmit}>{t('button.sign_in')}</Button>
								</Col>
							</Col>
						</FlexboxGrid>
					</div>
				</div>
			</Col>

			<ModalChangePassword 
				{...props} 
				ref = {modal_change_password}
				email={email} 
				initSession={initSession}
				OpenModalSelectAccount={OpenModalSelectAccount}
				t={t}
			/>

			<ModalSelectAccount 
				{...props} 
				ref = {modal_select_account}
				t= {t}
				initSession={initSession}
				OpenModalSelectAccount={OpenModalSelectAccount}
			/>
		</FlexboxGrid>
	)
}

export default withTranslation('translations')(Login);